import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { fetch, update, cancel as cancelRequest } from '../common/api'
import {
  Typography,
  Container,
  Box,
  Grid,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText
} from '@mui/material'
import { grey } from '@mui/material/colors'
import coursesHeader from './courses-header.jpg'
import { useAuth } from '../account/auth'
import HeroBanner from './HeroBanner'
import BreadCrumb from './BreadCrumb'

export default function VirtualInstructorLedTrainingRegistration() {
  const { user, isConfirmed } = useOutletContext()
  const auth = useAuth()
  const [interactionInProgress, setInteractionInProgress] = useState(false)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [details, setDetails] = useState({
    company_name: '',
    city: '',
    accounts: '',
    courseId: ''
  })
  const [dialogOpen, setDialogOpen] = useState(false)

  const [courses, setCoures] = useState([])
  const headerHeight = {
    xs: '333px',
    sm: '413px',
    md: '423px',
    lg: '383px',
    xl: '483px'
  }

  const handleDialogClose = () => {
    setDetails({
      company_name: user['custom:company_name'],
      city: '',
      accounts: '',
      courseId: ''
    })
    setDialogOpen(false)
  }

  const createInteraction = async (type, userId, courseId, metadata) => {
    setInteractionInProgress(true)
    try {
      await update(`/users/${userId}/interactions`, {
        interactionType: type,
        courseId: courseId,
        metadata: metadata
      })
      setInteractionInProgress(false)
    } catch (e) {
      console.log(e)
      setInteractionInProgress(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    await createInteraction(
      'course_enrollment',
      user.sub,
      details.courseId,
      details
    )
    setDialogOpen(true)
  }

  const handleChange = (prop) => (event) => {
    setDetails({ ...details, [prop]: event.target.value })
  }

  useEffect(() => {
    let mounted = true
    let courseRequest

    async function fetchData() {
      try {
        window.scrollTo(0, 0)
        courseRequest = fetch(`/courses/?type=vilt`)
        const courses = await courseRequest

        if (mounted) {
          setSubmitButtonDisabled(false)
          setCoures(
            courses.filter((c) =>
              auth.isAdmin ? c.isPublished === true : true
            )
          )
        }
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()

    if (user && user['custom:company_name'] && mounted)
      setDetails((d) => ({
        ...d,
        ...{ company_name: user['custom:company_name'] }
      }))

    return () => {
      mounted = false
      cancelRequest(courseRequest)
    }
  }, [user, auth.isAdmin])

  return (
    <>
      <HeroBanner
        image={coursesHeader}
        imageLocation="right"
        height={headerHeight}
      >
        <Typography
          variant="h1"
          color="white"
          sx={{
            mb: 3
          }}
        >
          Instructor-led Virtual Training
        </Typography>
        <Typography variant="body" paragraph color="white">
          Take a look at our virtual training and register for a great
          instructor-led course!
        </Typography>
      </HeroBanner>

      <Container component="section" maxWidth="xl" sx={{ pt: 4 }}>
        <BreadCrumb />
      </Container>
      <Container component="section">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            {!isConfirmed ? (
              <Typography variant="h5" paragraph>
                Sorry, you must verify and confirm your email address before you
                can register for virtual training. Please follow the
                instructions at the top of the page.
              </Typography>
            ) : undefined}

            {user && isConfirmed && courses.length > 0 ? (
              <Box component="form" onSubmit={handleSubmit} autoComplete="off">
                <Typography
                  variant="h2"
                  sx={{
                    mt: { xs: 0, md: 1, lg: 3 },
                    mb: 1
                  }}
                >
                  Enroll now
                </Typography>
                <Typography variant="body" paragraph>
                  Fill out the below required information to start your
                  training.
                </Typography>
                <Grid
                  container
                  rowSpacing={{ xs: 4, lg: 0 }}
                  columnSpacing={{ xs: 0, lg: 3 }}
                >
                  <Grid item xs={12} lg={6}>
                    <Box>
                      <FormControl fullWidth margin="normal" variant="filled">
                        <InputLabel shrink htmlFor="given_name">
                          First Name*
                        </InputLabel>
                        <OutlinedInput
                          id="given_name"
                          name="given_name"
                          value={user.given_name}
                          readOnly={true}
                          sx={{
                            backgroundColor: grey[200]
                          }}
                        />
                      </FormControl>
                      <FormControl fullWidth margin="normal" variant="filled">
                        <InputLabel shrink htmlFor="emailAddress">
                          Company Work Email*
                        </InputLabel>
                        <OutlinedInput
                          id="emailAddress"
                          name="emailAddress"
                          type="text"
                          placeholder="Please enter your work email"
                          required
                          value={user.email}
                          readOnly={true}
                          sx={{
                            backgroundColor: grey[200]
                          }}
                        />
                      </FormControl>
                      <FormControl fullWidth margin="normal" variant="filled">
                        <InputLabel shrink htmlFor="emailAddress">
                          City*
                        </InputLabel>
                        <OutlinedInput
                          id="emailAddress"
                          name="emailAddress"
                          type="text"
                          placeholder="Please enter the city you are taking your taining from"
                          required
                          onChange={handleChange('city')}
                          value={details.city}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box>
                      <FormControl fullWidth margin="normal" variant="filled">
                        <InputLabel shrink htmlFor="family_name">
                          Last Name*
                        </InputLabel>
                        <OutlinedInput
                          id="family_name"
                          name="family_name"
                          required
                          value={user.family_name}
                          readOnly={true}
                          sx={{
                            backgroundColor: grey[200]
                          }}
                        />
                      </FormControl>
                      <FormControl fullWidth margin="normal" variant="filled">
                        <InputLabel shrink htmlFor="companyName">
                          Company*
                        </InputLabel>
                        <OutlinedInput
                          id="companyName"
                          name="companyName"
                          required
                          placeholder="Please enter your company*"
                          onChange={handleChange('company_name')}
                          value={details.company_name}
                        />
                      </FormControl>
                      <FormControl fullWidth margin="normal" variant="filled">
                        <InputLabel shrink htmlFor="accounts-worked-on-label">
                          Accounts worked on (optional)
                        </InputLabel>
                        <OutlinedInput
                          id="accounts-worked-on-label"
                          name="accounts-worked-on-label"
                          placeholder="Please enter the accounts worked on (optional)"
                          onChange={handleChange('accounts')}
                          value={details.accounts}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Typography variant="h4" sx={{ mt: 2 }}>
                  Choose from one of the available courses below:
                </Typography>
                <FormControl fullWidth variant="filled" margin="normal">
                  <InputLabel id="virtual-course-label">
                    Select a course*
                  </InputLabel>
                  <Select
                    labelId="virtual-course-label"
                    id="virtual-course"
                    onChange={handleChange('courseId')}
                    label="Accounts worked on"
                    required
                    value={details.courseId}
                    sx={{
                      backgroundColor: 'white',
                      border: 'solid 1px',
                      borderColor: grey[400]
                    }}
                  >
                    {courses
                      ? courses.map((c) => {
                          const sessionStart = new Date(c.startTime)

                          return (
                            <MenuItem value={c.id} key={c.id}>
                              {c.name} | Date:{' '}
                              {sessionStart.toLocaleDateString()} @{' '}
                              {sessionStart.toLocaleTimeString()}
                              {c.enrolledAt ? ' (ALREADY ENROLLED)' : undefined}
                            </MenuItem>
                          )
                        })
                      : undefined}
                  </Select>
                </FormControl>
                <Typography variant="body" paragraph sx={{ mt: 1 }}>
                  Once enrolled, a confirmation email will be sent with the
                  required information to join the session.
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={interactionInProgress || submitButtonDisabled}
                >
                  {interactionInProgress ? 'Enrolling...' : 'Enroll Now'}
                </Button>
              </Box>
            ) : (
              <>
                <Typography variant="h3" sx={{ mt: 8 }}>
                  There are currently no scheduled Instructor-Led courses at the
                  moment but please reach out to{' '}
                  <a href="mailto:kidaware@superawesome.com">
                    kidaware@superawesome.com
                  </a>{' '}
                  to request a training for your team.
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      <Dialog
        onClose={handleDialogClose}
        open={dialogOpen}
        aria-labelledby="confirm-virtual-enrolment-title"
        aria-describedby="confirm-virtual-enrolment-description"
      >
        <DialogTitle id="confirm-virtual-enrolment-title">
          You are now enrolled!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Congratulations, you are now ready to start your training. An email
            will be sent shortly with the details you will need to join the
            virtual course.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
