import PropTypes from 'prop-types'
import { Typography, Box, Link } from '@mui/material'

export default function NewsFeedCard({ title, publishedDate, source, link }) {
  const formatDate = (acfDate) => {
    if (!acfDate) return ''

    const year = parseInt(acfDate.substring(0, 4))
    const month = parseInt(acfDate.substring(5, 7))
    const day = parseInt(acfDate.substring(8, 10))
    const date = new Date(year, month - 1, day)

    const options = { month: 'long', day: 'numeric', year: 'numeric' }
    return date.toLocaleDateString('en-US', options)
  }

  const publishedOn = formatDate(publishedDate)

  return (
    <Typography
      component={Link}
      sx={{ mr: 1, textDecoration: 'none' }}
      target="_blank"
      href={`${link}`}
    >
      <Typography variant="h5" color="#4f4f4f" sx={{ fontWeight: 'light' }}>
        {title ? (
          <>
            {source} / {publishedOn}
          </>
        ) : (
          <Box
            className="is-loading"
            sx={{
              display: 'flex',
              px: 4,
              py: 2,
              mb: 2,
              minHeight: 20,
              width: '50%'
            }}
          ></Box>
        )}
      </Typography>
      <>
        {title ? (
          <Typography
            variant="h4"
            dangerouslySetInnerHTML={{
              __html: title ? title : ''
            }}
          ></Typography>
        ) : (
          <Box
            className="is-loading"
            sx={{
              display: 'flex',
              px: 4,
              py: 2,
              minHeight: 60,
              width: '100%'
            }}
          ></Box>
        )}
      </>
    </Typography>
  )
}

NewsFeedCard.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  source: PropTypes.string,
  publishedDate: PropTypes.string
}
