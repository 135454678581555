import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { update } from '../common/api'
import {
  Box,
  Button,
  Alert,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export default function Page({ current, onSuccess, onClose }) {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [showAlert, setShowAlert] = useState(null)
  const [page, setPage] = useState({
    title: '',
    slug: '',
    isPublished: false
  })

  const handleChange = (prop) => (event) => {
    setPage({ ...page, [prop]: event.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitButtonDisabled(true)

    try {
      setShowAlert(false)
      const r = await update(`/content/${page.id}`, page)
      setPage({ ...page, id: r.id })
      if (onSuccess && typeof onSuccess === 'function') onSuccess(r)
      setSubmitButtonDisabled(false)
      setShowAlert({
        severity: 'success',
        message: 'Page information saved!'
      })
    } catch (e) {
      setSubmitButtonDisabled(false)
      setShowAlert({ severity: 'error', message: e.message })
      console.log(e)
    }
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setPage(current)
      setSubmitButtonDisabled(false)
    }

    return () => {
      mounted = false
    }
  }, [current])

  return (
    <>
      {showAlert ? (
        <Alert variant="outlined" severity={showAlert.severity} sx={{ my: 2 }}>
          {showAlert.message}
        </Alert>
      ) : undefined}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          component="h1"
          variant="h4"
          sx={{ textAlign: 'left', textTransform: 'uppercase', mb: 2 }}
        >
          {page ? page.title : ''}
        </Typography>
        <Box>
          <IconButton
            aria-label="close"
            color="primary"
            onClick={onClose}
            sx={{ p: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box component="form" onSubmit={handleSubmit} autoComplete="off">
        <Box>
          <FormControl fullWidth margin="normal" variant="filled">
            <InputLabel shrink htmlFor="name">
              Title
            </InputLabel>
            <OutlinedInput
              id="title"
              name="title"
              readOnly
              value={page.title}
            />
          </FormControl>
          <FormControl fullWidth margin="normal" variant="filled">
            <InputLabel shrink htmlFor="name">
              Slug
            </InputLabel>
            <OutlinedInput id="slug" name="slug" readOnly value={page.slug} />
          </FormControl>
          <FormControl
            variant="filled"
            margin="normal"
            sx={{ minWidth: '100%' }}
          >
            <InputLabel id="type-label">Is Published</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              value={page.isPublished}
              onChange={handleChange('isPublished')}
              label="Status"
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'end',
              mt: 3
            }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              color="warning"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={submitButtonDisabled}
            >
              {page.id === null || page.id === undefined ? 'Add' : 'Update'}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

Page.propTypes = {
  current: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func
}
