import { useState, useEffect, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Modal, Box } from '@mui/material'

const ButtonModal = ({ component, children, size }) => {
  const [buttonModalOpen, setButtonModelOpen] = useState(false)
  const maxWidth = size === 'lg' ? 1000 : 500
  const child = cloneElement(children, {
    closeModal: () => {
      setButtonModelOpen(false)
    }
  })

  const handleButtonModalOpen = (e) => {
    e.preventDefault()
    setButtonModelOpen(true)
  }

  const handleModalClose = () => setButtonModelOpen(false)

  useEffect(() => {
    let mounted = true

    if (mounted)
      document
        .getElementById(component.props.id)
        .addEventListener('click', handleButtonModalOpen)

    return () => {
      const el = document.getElementById(component.props.id)
      mounted = false
      return el
        ? el.removeEventListener('click', handleButtonModalOpen)
        : undefined
    }
  }, [component])

  return (
    <>
      {component}
      <Modal
        open={buttonModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, -25%)',
            maxWidth: maxWidth,
            width: 'calc(100% - 50px)',
            bgcolor: 'background.paper',
            boxShadow: 8,
            p: { xs: 2, md: 4 },
            overflowY: 'scroll',
            maxHeight: 'calc(100% - 50px)'
          }}
        >
          {child}
        </Box>
      </Modal>
    </>
  )
}

ButtonModal.propTypes = {
  component: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
  size: PropTypes.string
}

ButtonModal.defaultProps = {
  size: 'md'
}

export default ButtonModal
