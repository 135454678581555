import PropTypes from 'prop-types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined'
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined'
import './Course.scss'

export default function TopicStatusCircle({ topic, topicId, previewMode }) {
  return (
    <>
      {topic.id === topicId ? (
        <AdjustOutlinedIcon color="secondary" sx={{ ml: 3 }} />
      ) : (
        <>
          {(!topic.isStarted && !topic.isCompleted) || previewMode ? (
            <CircleOutlinedIcon color="disabled" sx={{ ml: 3 }} />
          ) : (
            <>
              {topic.isCompleted ? (
                <CheckCircleIcon color="trinary" sx={{ ml: 3 }} />
              ) : (
                <PendingOutlinedIcon color="secondary" sx={{ ml: 3 }} />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

TopicStatusCircle.propTypes = {
  topic: PropTypes.object.isRequired,
  topicId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  previewMode: PropTypes.bool
}
