import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { update } from '../common/api'
import { Storage } from 'aws-amplify'
import { useAuth } from '../account/auth'
import ButtonConfirm from '../common/ButtonConfirm'
import { Container, Typography, Box, Alert, Fab } from '@mui/material'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'

export default function Kill() {
  const { setNotification } = useOutletContext()
  const [showAlert, setShowAlert] = useState(null)
  const auth = useAuth()

  const handleKill = async () => {
    if (!auth.isSuperAdmin) {
      setNotification({
        open: true,
        message: 'Super admin is required!',
        severity: 'error'
      })
      return
    }

    try {
      setShowAlert(false)
      setShowAlert({ severity: 'warning', message: 'Killing...' })
      const path = `maintenance.on`

      const content = 'The site is killed now'
      const strblob = new Blob([content], { type: 'text/plain' })

      const formdata = new FormData()
      formdata.append('file', strblob, 'maintenance.txt')
      formdata.append('field-1', 'field-1-data')

      await Storage.put(path, formdata, {
        cacheControl: 'no-cache',
        resumable: false,
        bucket: process.env.REACT_APP_EXTERNAL_ASSETS_S3_BUCKET,
        customPrefix: {
          public: 'maintenance/'
        },
        completeCallback: () => {
          //resumable only
          setShowAlert({
            severity: 'success',
            message: 'Kill sequenece completed!'
          })

          setNotification({
            open: true,
            message: 'Kill sequenece completed!',
            severity: 'success'
          })
        },
        progressCallback: (progress) => {
          const percent = Math.round((progress.loaded / progress.total) * 100)
          setNotification({
            open: true,
            message: `Killing progress: ${percent} %`,
            severity: 'info'
          })
        },
        errorCallback: (err) => {
          setShowAlert({
            severity: 'error',
            message: `Error killing! ${err.msg}`
          })
          setNotification({
            open: true,
            message: 'Error killing.',
            severity: 'error'
          })
        }
      })

      await update(`/kill`)

      setShowAlert({
        severity: 'success',
        message: 'Killed, please refresh'
      })
    } catch (e) {
      setShowAlert({ severity: 'error', message: e.message })
      console.log(e)
    }
  }

  return (
    <>
      {auth.isSuperAdmin ? (
        <Container component="main">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              component="h1"
              variant="h4"
              sx={{
                textAlign: 'left',
                textTransform: 'uppercase',
                mb: 2,
                display: 'flex',
                alignContent: 'space-between'
              }}
            >
              Kill Switch
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            {' '}
            <Typography variant="h1">*****WARNING*****</Typography>
            <Typography variant="h4" sx={{ fontWeight: 300, my: 6 }}>
              This button will kill the application!. All users will be signed
              out and redirect to a static maintenance page. All API access will
              be disabled, and all S3 access policies will be set to deny all.
              The only way to revert this process is to contact the JaPache team
              to manually reenable each section of the application. This is a
              last case scenerio
            </Typography>
            <Typography variant="h1" sx={{ mb: 6 }}>
              *****WARNING*****
            </Typography>
            <ButtonConfirm
              key={`kill`}
              confirmAction={() => handleKill()}
              component={
                <Fab
                  variant="contained"
                  id={`kill-now`}
                  color="secondary"
                  sx={{ backgroundColor: 'primary.main', p: 12 }}
                >
                  <PowerSettingsNewIcon fontSize="large" />
                </Fab>
              }
            >
              <>
                Once you confirm, ALL access will be terminated and can only be
                re-initiated manually by the JaPache team.
              </>
            </ButtonConfirm>
          </Box>

          {showAlert ? (
            <Alert
              variant="outlined"
              severity={showAlert.severity}
              sx={{ my: 2 }}
            >
              {showAlert.message}
            </Alert>
          ) : undefined}
        </Container>
      ) : undefined}
    </>
  )
}
