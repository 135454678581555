import { Auth } from 'aws-amplify'

const awsAuth = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT,
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        name: 'KidAwareApi',
        endpoint: process.env.REACT_APP_KIDAWARE_API_BASE_URL,
        custom_header: async () => {
          const userData = localStorage.getItem('user')
          if (!userData) return { Authorization: 'public' }

          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_ASSETS_S3_BUCKET,
      region: process.env.REACT_APP_COGNITO_REGION
    }
  }
}

export default awsAuth
