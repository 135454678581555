import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { enrollCourse } from '../common/interactions'
import {
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  CardActionArea,
  Box,
  Modal
} from '@mui/material'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { styled } from '@mui/material/styles'
import Truncate from 'react-truncate'
import CourseProgressCircle from './CourseProgressCircle'
import kidawareBadgeNewSvg from './kidaware-badge-new.svg'
import './CourseCard.scss'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} {...expand} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

export default function CourseCard({ course, user, isConfirmed }) {
  const [expanded, setExpanded] = useState(false)
  const [modalOpen, setModelOpen] = useState(false)
  const [isComplete, setComplete] = useState(false)
  const [isInprogress, setInprogress] = useState(false)
  const [hasNotStarted, setNotStarted] = useState(false)
  const [interactionInProgress, setInteractionInProgress] = useState(false)
  const navigate = useNavigate()
  const titleLines = 3
  const mediaHeight = 194

  const handleModalClose = () => setModelOpen(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handelCardModalClick = () => {
    setModelOpen(true)
  }

  const enroll = async (userId, course) => {
    if (!isConfirmed) {
      navigate(`/courses/${course.slug}`)
      return
    }

    setInteractionInProgress(true)
    try {
      await enrollCourse(userId, course.id)
      setInteractionInProgress(false)
      navigate(`/courses/${course.slug}`)
    } catch (e) {
      console.log(e)
      setInteractionInProgress(false)
    }
  }

  const CourseImage = (
    <>
      {course && course.imageUrl ? (
        <CardMedia
          alt="Course"
          sx={{
            position: 'relative',
            backgroundImage: `url(${course.imageUrl
              .replace('_orig', '')
              .replace('.jpeg', '.jpg')
              .replace('.png', '.jpg')
              .replace('.jpg', '_540w.jpg')})`,
            maxHeight: mediaHeight
          }}
        >
          <Box height={mediaHeight}></Box>
          {isInprogress ? (
            <Box
              height="100%"
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                backgroundColor: 'white',
                opacity: 0.5
              }}
            ></Box>
          ) : undefined}

          {course.teaserVideoUrl ? (
            <Box
              height={mediaHeight}
              className="card-overlay"
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <PlayCircleOutlineIcon
                sx={{
                  fontSize: 100,
                  color: 'white',
                  opacity: isInprogress ? 1 : '.75'
                }}
                color="white"
              />
            </Box>
          ) : undefined}
          {isComplete ? (
            <Box
              height={mediaHeight}
              className=""
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'end'
              }}
            >
              <CheckCircleIcon
                sx={{
                  fontSize: 25,
                  color: 'purple.dark',
                  opacity: '1',
                  m: 1
                }}
                color="white"
              />
            </Box>
          ) : undefined}

          {isInprogress ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'end',
                p: 1
              }}
            >
              <CourseProgressCircle percent={course?.percentCompleted ?? 0} />
            </Box>
          ) : (
            <>
              {course?.isNew ? (
                <Box
                  height={mediaHeight}
                  className=""
                  sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'end'
                  }}
                >
                  <img
                    width="45px"
                    alt="kidawawre-new-course-badge"
                    style={{
                      margin: '10px',
                      filter: 'drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))'
                    }}
                    src={kidawareBadgeNewSvg}
                  />
                </Box>
              ) : undefined}
            </>
          )}
        </CardMedia>
      ) : undefined}
    </>
  )

  useEffect(() => {
    let mounted = true
    const percent = course?.percentCompleted ?? 0

    if (mounted) {
      setComplete(percent === 100)
      setInprogress(percent > 0 && percent < 100)
      setNotStarted(!course?.enrolledAt)
    }

    return () => {
      mounted = false
    }
  }, [course])

  return (
    <>
      <Card
        className={
          isComplete
            ? 'course-card course-completed mdc-card'
            : 'course-card mdc-card'
        }
        elevation={3}
        sx={{ height: '100%', display: 'flex', flexFlow: 'wrap' }}
      >
        <CardActionArea
          onClick={handelCardModalClick}
          sx={{
            maxHeight: mediaHeight
          }}
        >
          {CourseImage}
        </CardActionArea>
        <CardContent sx={{ textAlign: 'left' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {course?.name ?? undefined}
          </Typography>
          <Box>
            <Typography variant="body2" color="text.secondary">
              {course &&
              course.description &&
              course.description.length > 150 ? (
                <Truncate lines={!expanded && titleLines}>
                  {course.description}
                </Truncate>
              ) : (
                <>{course.description}</>
              )}
            </Typography>
          </Box>
        </CardContent>
        <CardActions disableSpacing sx={{ px: 2, width: '100%' }}>
          {course && course.isPublished === false ? (
            <Button
              component={Link}
              size="small"
              color="info"
              variant="contained"
              sx={{
                textTransform: 'initial'
              }}
              aria-label="start course"
              to={`/courses/${course.slug}`}
            >
              Draft
            </Button>
          ) : (
            <>
              {hasNotStarted &&
              isConfirmed &&
              (course?.isPublished || course?.isPublished === undefined) ? (
                <Button
                  component={Link}
                  size="small"
                  variant="contained"
                  sx={{
                    textTransform: 'initial'
                  }}
                  aria-label="start course"
                  onClick={(e) => {
                    e.preventDefault()
                    enroll(user.sub, course)
                    return false
                  }}
                  to={`/courses/${course.slug}`}
                >
                  {interactionInProgress ? 'Enrolling...' : 'Start'}
                </Button>
              ) : undefined}

              {hasNotStarted &&
              !isConfirmed &&
              (course.isPublished || course.isPublished === undefined) ? (
                <Button
                  component={Link}
                  size="small"
                  variant="contained"
                  sx={{
                    textTransform: 'initial'
                  }}
                  aria-label="preview course"
                  to={`/courses/${course.slug}`}
                >
                  {'Preview'}
                </Button>
              ) : undefined}

              {isComplete ? (
                <Button
                  component={Link}
                  size="small"
                  color="trinary"
                  variant="outlined"
                  sx={{
                    textTransform: 'initial',
                    backgroundColor: '#fff',
                    boxShadow:
                      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                  }}
                  aria-label="restart course"
                  to={`/courses/${course.slug}`}
                >
                  Restart
                </Button>
              ) : undefined}

              {!hasNotStarted && !isComplete ? (
                <Button
                  component={Link}
                  size="small"
                  color="secondary"
                  variant="outlined"
                  sx={{
                    textTransform: 'initial',
                    boxShadow:
                      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                  }}
                  aria-label="continue course"
                  to={`/courses/${course.slug}`}
                >
                  Continue
                </Button>
              ) : undefined}
            </>
          )}

          {course.description && course.description.length > 150 ? (
            <ExpandMore
              expand={expanded ?? false}
              onClick={handleExpandClick}
              color="primary"
              aria-expanded={expanded ?? false}
              aria-label="show more course description"
            >
              <ExpandCircleDownOutlinedIcon />
            </ExpandMore>
          ) : undefined}
        </CardActions>
      </Card>

      {course.teaserVideoUrl ? (
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '25%',
              left: '50%',
              transform: 'translate(-50%, -25%)',
              maxWidth: '1000px',
              width: 'calc(100% - 50px)',
              bgcolor: 'background.paper',
              boxShadow: 8,
              maxHeight: 'calc(100% - 50px)',
              backgroundColor: 'black'
            }}
          >
            <video
              className="MuiCardMedia-root MuiCardMedia-media css-e6k82g-MuiCardMedia-root"
              src={course.teaserVideoUrl}
              style={{ width: '100%', height: 'auto' }}
              controls
            >
              <source src="{course.teaserVideoUrl}" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Modal>
      ) : undefined}
    </>
  )
}

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
  user: PropTypes.object,
  isConfirmed: PropTypes.bool
}
