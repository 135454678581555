import { Link as ComponentLink } from 'react-router-dom'
import {
  Typography,
  Container,
  Box,
  Grid,
  Button,
  Link,
  IconButton
} from '@mui/material'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import InstagramIcon from '@mui/icons-material/Instagram'
import superAwesomeLogo from './super-awesome-logo.svg'

export default function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <Box component="footer" sx={{ position: 'relative' }}>
      <Box
        sx={{
          height: '50px',
          '&::before': {
            content: "''",
            position: 'absolute',
            backgroundColor: 'trinary.main',
            width: '100%',
            height: '100%',
            left: 0,
            bottom: 50,
            transform: 'skewY(-3deg)',
            zIndex: -1
          }
        }}
      ></Box>
      <Box sx={{ bgcolor: 'trinary.main', color: 'white' }}>
        <Container component="main" maxWidth="xl">
          <Box>
            <Grid
              container
              rowSpacing={0}
              columnSpacing={{ xs: 4, lg: 5 }}
              color="white"
              sx={{ justifyContent: 'center' }}
            >
              <Grid
                item
                xs={12}
                md={3}
                lg={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: { xs: 'center', md: 'start' }
                }}
              >
                <div>
                  <Typography
                    component="h5"
                    sx={{
                      mb: 2,
                      color: 'white'
                    }}
                  >
                    Presented by
                  </Typography>
                  <Link href="https://www.superawesome.com/" target="_blank">
                    <img
                      width="220px"
                      alt="super awesome logo"
                      src={superAwesomeLogo}
                    />
                  </Link>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                lg={4}
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: { xs: 3, md: 0 }
                }}
              >
                <div>
                  <Typography paragraph variant="body">
                    Ask About Our Strategic Partner Program
                  </Typography>
                  <Typography paragraph variant="body">
                    Request an Instructor-led Virtual Training
                  </Typography>
                  <Typography paragraph variant="body">
                    Website Issues and More
                  </Typography>
                  <Button
                    variant="outlined"
                    fullWidth={true}
                    color="whiteButton"
                    component={ComponentLink}
                    to="/contact-us"
                    sx={{ mt: 2 }}
                  >
                    Contact Us
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={4}
                sx={{
                  textAlign: { xs: 'center', md: 'right' },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: { xs: 'center', md: 'end' },
                  pt: { xs: 2, md: 0 }
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: { xs: 'flex', md: 'initial' },
                      alignItems: 'center'
                    }}
                  >
                    <Link
                      component={ComponentLink}
                      to="/terms-of-use"
                      sx={{
                        color: 'white',
                        display: 'block',
                        my: 1,
                        mr: { xs: 2, md: 0 }
                      }}
                    >
                      Terms of Use
                    </Link>
                    <Link
                      component={ComponentLink}
                      to="/privacy-policy"
                      sx={{
                        color: 'white',
                        display: 'block',
                        my: 1
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </Box>
                  <Box sx={{ mt: { xs: 2, md: 3 } }}>
                    <IconButton
                      aria-label="twitter"
                      component={Link}
                      href="https://twitter.com/gosuperawesome"
                      size="small"
                      sx={{
                        backgroundColor: 'white',
                        mr: 1,
                        ':hover': {
                          background: '#ffffff9c'
                        }
                      }}
                    >
                      <TwitterIcon sx={{ color: 'purple.main' }} />
                    </IconButton>
                    <IconButton
                      component={Link}
                      href="https://www.linkedin.com/company/superawesome/"
                      target="_blank"
                      aria-label="twitter"
                      size="small"
                      sx={{
                        backgroundColor: 'white',
                        mr: 1,
                        ':hover': {
                          background: '#ffffff9c'
                        }
                      }}
                    >
                      <LinkedInIcon sx={{ color: 'purple.main' }} />
                    </IconButton>
                    <IconButton
                      component={Link}
                      aria-label="twitter"
                      target="_blank"
                      href="https://www.instagram.com/lifeatsuperawesome/"
                      size="small"
                      sx={{
                        backgroundColor: 'white',
                        ':hover': {
                          background: '#ffffff9c'
                        }
                      }}
                    >
                      <InstagramIcon sx={{ color: 'purple.main' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ mt: { xs: 1, md: 4 }, py: 2 }}>
              <Typography
                sx={{
                  textAlign: 'center',
                  color: 'white',
                  fontSize: 'smaller'
                }}
              >
                © KidAware&trade; {currentYear}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
