import { Typography } from '@mui/material'

function PasswordRequirements() {
  return (
    <Typography paragraph fontSize="small">
      *A password must be at least ten characters long and contain at least one
      number, an upper and lowercase character, and one special character
    </Typography>
  )
}

export default PasswordRequirements
