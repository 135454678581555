import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const CalendlyEmbed = ({ url, minWidth, height }) => {
  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    )
    head.appendChild(script)
  }, [])

  return (
    <Box
      className="calendly-inline-widget"
      data-url={url}
      style={{ minWidth, height }}
    />
  )
}

CalendlyEmbed.propTypes = {
  url: PropTypes.string.isRequired,
  minWidth: PropTypes.number,
  height: PropTypes.number
}

CalendlyEmbed.defaultProps = {
  minWidth: 300,
  height: 800
}

export default CalendlyEmbed
