import { useEffect } from 'react'
import { Link, useOutletContext, useNavigate } from 'react-router-dom'
import { fetch } from '../common/api'
import { useQuery } from '@tanstack/react-query'
import {
  Typography,
  Container,
  Box,
  Grid,
  Button,
  TextField
} from '@mui/material'
import CourseCard from './CourseCard'
import coursesHeader from './courses-header.jpg'
import newsletterImage from './kidaware-newsletter.jpg'
import ButtonModal from '../common/ButtonModal'
import LoginForm from '../account/LoginForm'
import HeroBanner from './HeroBanner'
import HeroBannerAngled from './BannerAngled'
import LoadingCourseCard from './LoadingCourseCard'
import { useAuth } from '../account/auth'

export default function Curriculum() {
  const { authed, isConfirmed, user, isApproved, isWhitelisted } =
    useOutletContext()
  const navigate = useNavigate()
  const auth = useAuth()

  const searchHeight = {
    xs: '347px',
    sm: '407px',
    md: '397px',
    lg: '395px',
    xl: '397px'
  }

  const headerHeight = {
    xs: '297px',
    md: '397px',
    lg: '497px',
    xl: '597px'
  }

  const newsletterHeight = {
    xs: '456px',
    md: '456px',
    lg: '456px',
    xl: '456px'
  }

  const continueCourseBackgroundHeight = {
    xs: 'initial',
    sm: '875px',
    md: '875px',
    lg: '547px',
    xl: '557px'
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    navigate(`/courses/search-results?query=${e.target.searchFor.value}`)
  }

  const filterOnlineCourses = (courses) =>
    courses.filter((c) => c.type === 'online' || c.type === 'xapi')

  const filterFeaturedCourses = (courses) =>
    filterOnlineCourses(courses)
      .filter((c) => c.isFeatured)
      .filter((c) => (auth.isAdmin ? c.isPublished === true : true))
      .slice(0, 2)

  const filterRemainderCourses = (courses) =>
    filterOnlineCourses(courses)
      .filter(
        (c) =>
          c.slug !== 'kidaware-newsletters' &&
          !filterFeaturedCourses(courses)
            .map((c) => c.id)
            .includes(c.id)
      )
      .filter((c) => (auth.isAdmin ? c.isPublished === true : true))

  const useCourseOnline = () => {
    return useQuery(['coursesOnline'], async () => {
      return await fetch(`/courses?types=online,xapi`)
    })
  }

  const { status, data: courses, error } = useCourseOnline()

  const useSearch = () => {
    return useQuery(['searchCourses'], async () => {
      return await fetch(`/courses/search`)
    })
  }

  useSearch()

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {}
  }, [authed])

  return (
    <>
      {!authed || !isConfirmed || (!isApproved && !isWhitelisted) ? (
        <HeroBanner
          image={coursesHeader}
          imageLocation="right"
          height={headerHeight}
        >
          <Typography
            variant="h1"
            color="white"
            sx={{
              mb: 3
            }}
          >
            What is KidAware
          </Typography>
          <Typography variant="body" paragraph color="white">
            KidAware is a training program that provides you and your teams the
            knowledge to navigate the compliance, regulation and industry
            standards of the digital kids landscape. You will discover all you
            need to know about how to engage with kids in compliance with COPPA,
            GDPR-K and more. We offer in-person and virtual instructor-led
            training sessions as well as a robust eLearning library of reference
            material.
          </Typography>
          {authed && isConfirmed && (isApproved || isWhitelisted) ? (
            <ButtonModal
              size="md"
              component={
                <Button
                  id="enroll-now-button"
                  color="secondaryDark"
                  variant="contained"
                  sx={{ mt: 3, width: { xs: '100%', sm: 'initial' } }}
                >
                  Enroll Now
                </Button>
              }
            >
              <LoginForm />
            </ButtonModal>
          ) : (
            <>
              {!isConfirmed && authed ? (
                <Button
                  id="enroll-now-button"
                  color="secondaryDark"
                  variant="contained"
                  sx={{ mt: 3, width: { xs: '100%', sm: 'initial' } }}
                >
                  Confirm your email to continue
                </Button>
              ) : !isApproved && !isWhitelisted && authed ? (
                <Button
                  id="enroll-now-button"
                  color="secondaryDark"
                  variant="contained"
                  sx={{ mt: 3, width: { xs: '100%', sm: 'initial' } }}
                >
                  Approval pending
                </Button>
              ) : (
                <ButtonModal
                  size="lg"
                  component={
                    <Button
                      id="enroll-now-button"
                      color="secondaryDark"
                      variant="contained"
                      sx={{ mt: 3, width: { xs: '100%', sm: 'initial' } }}
                    >
                      Login to Enroll Now
                    </Button>
                  }
                >
                  <LoginForm />
                </ButtonModal>
              )}
            </>
          )}
        </HeroBanner>
      ) : undefined}

      {!authed || (!isApproved && !isWhitelisted) ? (
        <Box
          className={`brand-background background-secondary-light`}
          sx={{
            postition: 'relative',
            marginTop: { xs: -9, sm: -24, md: -15, lg: -12, xl: -9 },

            '&::before': {
              height: continueCourseBackgroundHeight
            }
          }}
        >
          <Container
            component="section"
            maxWidth="xl"
            sx={{
              py: { xs: 1, sm: 9 },
              mt: { xs: 12, sm: 10 }
            }}
          >
            <Grid
              container
              rowSpacing={0}
              columnSpacing={{ xs: 4, lg: 5 }}
              color="white"
              sx={{
                textAlign: 'center'
              }}
            >
              <Grid
                item
                md={12}
                lg={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: { xs: 2, lg: 0 }
                }}
              >
                <div>
                  <Typography
                    variant="h1"
                    color="black.main"
                    sx={{
                      mb: 3
                    }}
                  >
                    Our Featured <br /> Courses
                  </Typography>
                  <Typography
                    paragraph
                    variant="body"
                    color="black.main"
                    sx={{ mb: 0 }}
                  >
                    Check out our newest courses here
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                sm={0}
                md={0}
                lg={1}
                sx={{
                  display: { xs: 'none', lg: 'flex' }
                }}
              ></Grid>
              <Grid
                item
                sm={12}
                lg={7}
                sx={{
                  justifyContent: 'center'
                }}
              >
                <Grid
                  container
                  rowSpacing={{ xs: 3, md: 0 }}
                  columnSpacing={{ xs: 2, lg: 4, xl: 8 }}
                  color="white"
                  sx={{
                    justifyContent: 'center'
                  }}
                >
                  {status === 'loading' ? (
                    [...Array(2)].map((_, i) => (
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        lg={6}
                        key={`empty-featured-course-card-${i}`}
                      >
                        <LoadingCourseCard />
                      </Grid>
                    ))
                  ) : status === 'error' ? (
                    <span>Error: {error.message}</span>
                  ) : (
                    <>
                      {filterFeaturedCourses(courses).map((c) => (
                        <Grid item xs={12} sm={5} md={4} lg={6} key={c.id}>
                          <CourseCard
                            course={c}
                            user={user}
                            isConfirmed={
                              isConfirmed && (isApproved || isWhitelisted)
                            }
                          />
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      ) : isConfirmed && (isApproved || isWhitelisted) ? (
        <>
          <HeroBanner
            imageLocation="right"
            height={searchHeight}
            backgroundColor="#6806EE"
            paddingBottom={{
              xs: '95px',
              sm: '120px',
              md: '160px',
              lg: '125px',
              xl: '140px'
            }}
          >
            <Typography
              variant="h1"
              color="white"
              sx={{
                mb: 0
              }}
            >
              Looking for something specific?
            </Typography>
            <Typography variant="body" paragraph color="white" sx={{ mb: 3 }}>
              Try searching to narrow down your course selection.
            </Typography>
            <Box
              sx={{ display: 'flex' }}
              component="form"
              onSubmit={handleSearchSubmit}
            >
              <TextField
                id="search-for"
                name="searchFor"
                label="Search our courses"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  sx: {
                    display: 'none'
                  }
                }}
                InputProps={{
                  sx: {
                    backgroundColor: 'white',
                    borderTopLeftRadius: '100px',
                    borderBottomLeftRadius: '100px',
                    borderTopRightRadius: '0',
                    borderBottomrightRadius: '0'
                  }
                }}
              />
              <Button
                type="submit"
                id="search-button"
                color="secondaryDark"
                variant="contained"
                sx={{
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0
                  }
                }}
                aria-label="search for a course"
              >
                Search
              </Button>
            </Box>
          </HeroBanner>

          <Box
            sx={{
              position: 'relative',
              mt: { xs: -9, sm: -24, md: -15, lg: 0, xl: -1 }
            }}
          >
            <Grid
              container
              columnSpacing={{ xs: 2, lg: 10, xl: 30 }}
              sx={{
                display: { xs: 'none', sm: 'flex' }
              }}
              rowSpacing={0}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={4}
                xl={5}
                sx={{
                  height: continueCourseBackgroundHeight
                }}
              ></Grid>
            </Grid>
            <Box
              sx={{
                position: { xs: 'initial', sm: 'absolute' },
                backgroundColor: { xs: 'trinary.light', sm: 'initial' },
                py: { xs: 6, sm: 0 },
                top: 0,
                height: continueCourseBackgroundHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                '&::before': {
                  content: "''",
                  position: 'absolute',
                  backgroundColor: 'trinary.light',
                  width: { xs: '100%', lg: '33.33%' },
                  height: continueCourseBackgroundHeight,
                  left: 0,
                  botom: 0,
                  transform: 'skewY(-3deg)',
                  zIndex: -1
                }
              }}
            >
              <Container component="section" maxWidth="xl">
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={{ xs: 0, lg: 12, xl: 5 }}
                  color="white"
                  sx={{
                    textAlign: { xs: 'center', lg: 'left' }
                  }}
                >
                  <Grid
                    item
                    md={12}
                    lg={3}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'black.main',
                      mb: { xs: 2, lg: 0 }
                    }}
                  >
                    <div>
                      <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                          mb: 3,
                          fontWeight: 700,
                          lineHeight: 1.2
                        }}
                      >
                        Continue <br /> Learning
                      </Typography>
                      <Typography component="h2" paragraph variant="h2">
                        Continue where you left off, or try a new course.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    sm={0}
                    md={0}
                    lg={1}
                    sx={{
                      display: { xs: 'none', lg: 'flex' }
                    }}
                  ></Grid>
                  <Grid
                    item
                    sm={12}
                    lg={8}
                    xl={7}
                    sx={{
                      justifyContent: 'center'
                    }}
                  >
                    <Grid
                      container
                      rowSpacing={{ xs: 3, md: 0 }}
                      columnSpacing={{ xs: 2, lg: 4, xl: 8 }}
                      color="white"
                      sx={{
                        justifyContent: 'center'
                      }}
                    >
                      {status === 'loading' ? (
                        [...Array(2)].map((_, i) => (
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            md={4}
                            lg={6}
                            key={`empty-featured-course-card-${i}`}
                          >
                            <LoadingCourseCard />
                          </Grid>
                        ))
                      ) : status === 'error' ? (
                        <span>Error: {error.message}</span>
                      ) : (
                        <>
                          {filterFeaturedCourses(courses).map((c) => (
                            <Grid item xs={12} sm={5} md={4} lg={6} key={c.id}>
                              <CourseCard
                                course={c}
                                user={user}
                                isConfirmed={
                                  isConfirmed && (isApproved || isWhitelisted)
                                }
                              />
                            </Grid>
                          ))}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        </>
      ) : undefined}

      <Container
        component="section"
        sx={{
          mt: { xs: 3, sm: 6, md: 8, lg: 12, xl: 16 },
          pb: { xs: 0, md: 4, lg: 5 }
        }}
      >
        {!authed ? (
          <Typography component="h1" variant="h2" sx={{ pt: 3, mb: 3 }}>
            More Great Courses
          </Typography>
        ) : undefined}
        <Box sx={{ width: 1 }}>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 3, sm: 2, md: 3, lg: 6 }}
            style={{
              gridAutoRows: '1fr'
            }}
          >
            {status === 'loading' ? (
              [...Array(9)].map((_, i) => (
                <Box
                  gridColumn={{ xs: 'span 12', sm: 'span 6', md: 'span 4' }}
                  key={`empty-course-card-${i}`}
                >
                  <LoadingCourseCard />
                </Box>
              ))
            ) : status === 'error' ? (
              <span>Error: {error.message}</span>
            ) : (
              <>
                {filterRemainderCourses(courses).map((c) => (
                  <Box
                    gridColumn={{ xs: 'span 12', sm: 'span 6', md: 'span 4' }}
                    key={c.id}
                  >
                    <CourseCard
                      course={c}
                      user={user}
                      isConfirmed={isConfirmed && (isApproved || isWhitelisted)}
                    />
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
      </Container>

      <Box sx={{ paddingTop: 10 }}>
        <HeroBannerAngled
          image={newsletterImage}
          imageLocation="left"
          height={newsletterHeight}
        >
          <Typography
            variant="h1"
            color="white"
            sx={{
              mb: 3
            }}
          >
            KidAware Newsletter Archive
          </Typography>
          <Typography variant="body" paragraph color="white">
            KidAware newsletters provide a way to stay up-to-date on the most
            recent developments in the compliance regulatory landscape
          </Typography>
          {authed ? (
            <Button
              component={Link}
              to="/courses/kidaware-newsletters"
              id="enroll-now-button"
              color="secondaryDark"
              variant="contained"
              sx={{ mt: 3 }}
            >
              Access now
            </Button>
          ) : (
            <ButtonModal
              size="lg"
              component={
                <Button
                  id="login-to-newsletter-button"
                  color="secondaryDark"
                  variant="contained"
                  sx={{ mt: 3 }}
                >
                  Login to access
                </Button>
              }
            >
              <LoginForm />
            </ButtonModal>
          )}
        </HeroBannerAngled>
      </Box>

      {isWhitelisted ? (
        <Box>
          <Container
            component="section"
            maxWidth="lg"
            sx={{
              mt: { xs: 10, md: 12, lg: 17 },
              textAlign: 'center'
            }}
          >
            <Grid container rowSpacing={0} sx={{ justifyContent: 'center' }}>
              <Grid
                item
                md={8}
                sx={{
                  textAlign: 'center',
                  mb: { xs: 2, lg: 0 }
                }}
              >
                <Typography
                  component="h2"
                  variant="h1"
                  sx={{
                    fontWeight: 'bold',
                    color: 'black.main',
                    lineHeight: 1.3
                  }}
                >
                  Did you know we also offer Virtual Instructor Led Training?
                </Typography>
                <Typography variant="h3" component="h2" sx={{ mt: 1 }}>
                  Find the right course and register now.
                </Typography>
                <Button
                  component={Link}
                  to="/courses/virtual-instructor-led-training-registration"
                  color="primary"
                  variant="contained"
                  sx={{ mt: 3 }}
                >
                  Register Now
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      ) : undefined}
    </>
  )
}
