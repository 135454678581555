import { useEffect, useState } from 'react'
import { useOutletContext, Link as ReactLink } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { Storage } from 'aws-amplify'
import PropTypes from 'prop-types'
import { fetch, update, cancel as cancelRequest } from '../common/api'
import ButtonConfirm from '../common/ButtonConfirm'
import LearningPathSelector from './LearningPathSelector'
import { v4 as uuid } from 'uuid'
import {
  Box,
  Grid,
  Button,
  Alert,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  SwipeableDrawer,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DataGridWithFiltering from '../common/DataGridWithFiltering'
import Topic from './Topic'

function Course({ current, onSuccess, onClose }) {
  const { setNotification } = useOutletContext()
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [showAlert, setShowAlert] = useState(null)
  const [showItem, setShowItem] = useState(false)
  const [course, setCourse] = useState({
    id: '',
    name: '',
    isFeatured: false,
    isNew: false,
    isPublished: false,
    accredibleGroupId: ''
  })
  const emptyTopic = {
    id: null,
    name: '',
    isPublished: false,
    estimatedTime: 0,
    attachments: []
  }
  const [topic, setTopic] = useState(emptyTopic)
  const [list, setList] = useState([])
  const [listCopy, setListCopy] = useState([])
  const [dataLoading, setDataLoading] = useState(false)
  const [saveRequired, setSaveRequired] = useState(false)
  const [openSaveRequired, setOpenSaveRequired] = useState(false)

  const handleChange = (prop) => (event) => {
    setCourse({ ...course, [prop]: event.target.value })
  }

  const handleLearningPathSelect = (list) => {
    setCourse({ ...course, learningPathIds: list.map((p) => p.id) })
  }

  const handleSaveRequiredClose = () => setOpenSaveRequired(false)

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setShowItem(open)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitButtonDisabled(true)

    const url = course.id ? `/courses/${course.id}` : `/courses`

    try {
      setShowAlert(false)
      const c = await update(url, { ...course, ...{ topics: list } })
      setCourse({ ...course, id: c.id })
      if (onSuccess && typeof onSuccess === 'function') onSuccess(c)
      setSubmitButtonDisabled(false)
      setShowAlert({
        severity: 'success',
        message: 'Course information saved!'
      })
      setSaveRequired(false)
    } catch (e) {
      setSubmitButtonDisabled(false)
      setShowAlert({ severity: 'error', message: e.message })
      console.log(e)
    }
  }

  const handleFeatureUpload = (e) => {
    handleUpload(e, 'imageUrl', 'featured')
  }

  const handleTeaserVideoUpload = (e) => {
    handleUpload(e, 'teaserVideoUrl', 'teaser')
  }

  const handlexApiContentUpload = (e) => {
    handleUpload(e, 'contentUrl', 'xapi/zip')
  }

  const handleUpload = async (e, property, uploadPath) => {
    const file = e.target.files[0]
    const ext = file.name.split('.').pop()
    const path = `courses/${course.id}/${uploadPath}/${
      course.slug
    }-${uuid()}_orig.${ext}`
    const url = `${process.env.REACT_APP_ASSETS_S3_URL}/${path}`

    try {
      setNotification({
        open: true,
        message: `Starting upload`,
        severity: 'info'
      })

      await Storage.put(path, file, {
        contentType: 'image/jpg',
        cacheControl: 'no-cache',
        resumable: false,
        completeCallback: () => {
          //resumable only
          setShowAlert({ severity: 'success', message: 'Upload completed!' })

          setNotification({
            open: true,
            message: 'Upload completed!',
            severity: 'success'
          })
          setCourse({ ...course, imageUrl: url })
        },
        progressCallback: (progress) => {
          const percent = Math.round((progress.loaded / progress.total) * 100)
          setNotification({
            open: true,
            message: `Uploading: ${percent} %`,
            severity: 'info'
          })
        },
        errorCallback: (err) => {
          setShowAlert({
            severity: 'error',
            message: `Error uploading! ${err.msg}`
          })
          setNotification({
            open: true,
            message: 'Error uploading.',
            severity: 'error'
          })
        }
      })

      setShowAlert({ severity: 'success', message: 'Upload completed!' })
      setSaveRequired(true)

      setNotification({
        open: true,
        message: 'Upload completed!',
        severity: 'success'
      })
      setCourse({ ...course, [property]: url })
      e.target.value = null
    } catch (error) {
      console.log('Error uploading file: ', error)
      setShowAlert({
        severity: 'error',
        message: `Error uploading! ${error}`
      })
    }
  }

  const handleRemoveTopic = (topicId) => {
    const index = list.findIndex((t) => t.id === topicId)
    const newTopics = [...list]
    newTopics.splice(index, 1)
    setList(newTopics)
    setSaveRequired(true)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 400,
      flex: 1,
      editable: false,
      renderCell: function renderCell(params) {
        return (
          <Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center'
              }}
            >
              {params.row.isPublished ? (
                <CloudDoneIcon sx={{ color: 'success.main' }}></CloudDoneIcon>
              ) : (
                <CloudOffIcon></CloudOffIcon>
              )}
              <Typography sx={{ ml: 1 }}>{params.value}</Typography>
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: (params) => [
        <ButtonConfirm
          key={`edit-${params.id}`}
          confirmAction={() => handleRemoveTopic(params.id)}
          component={
            <GridActionsCellItem
              id={`resource-delete-confirm-${params.id}`}
              icon={<DeleteIcon />}
              sx={{ color: 'primary.main' }}
              label="Delete"
            />
          }
        >
          <>
            Please confirm if you would like to delete the resource. You must
            click &quot;Update&quot; to save the deletion.
          </>
        </ButtonConfirm>
      ]
    }
  ]

  useEffect(() => {
    let mounted = true
    let request

    async function fetchTopicData() {
      setDataLoading(true)
      setShowAlert(false)
      const query =
        current.type === 'online' ? '?related=topics' : '?related=topics'

      try {
        request = fetch(`/courses/${current.id}${query}`)
        const course = await request

        if (mounted && course.topics) {
          setList(course.topics)
          setListCopy(course.topics)
          setDataLoading(false)
          setSubmitButtonDisabled(false)
        }
      } catch (e) {
        if (mounted) {
          setShowAlert({ severity: 'error', message: e.message })
          setSubmitButtonDisabled(false)
          setDataLoading(false)
        }
        console.log(e)
      }
    }

    if (mounted) {
      setCourse(current)
      setSubmitButtonDisabled(false)
    }

    if (current.id) fetchTopicData()

    return () => {
      mounted = false
      cancelRequest(request)
    }
  }, [current])

  return (
    <>
      {showAlert ? (
        <Alert variant="outlined" severity={showAlert.severity} sx={{ my: 2 }}>
          {showAlert.message}
        </Alert>
      ) : undefined}

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          component="h1"
          variant="h4"
          sx={{ textAlign: 'left', textTransform: 'uppercase', mb: 2 }}
        >
          {course ? course.name : ''}
        </Typography>
        <Box>
          <IconButton
            aria-label="close"
            color="primary"
            onClick={() => {
              if (!saveRequired) onClose()
              else setOpenSaveRequired(true)
            }}
            sx={{ p: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box component="form" onSubmit={handleSubmit} autoComplete="off">
        <Grid
          container
          rowSpacing={{ xs: 4, lg: 0 }}
          columnSpacing={{ xs: 0, lg: 3 }}
        >
          <Grid item xs={12} lg={7}>
            <Box sx={{ display: 'flex' }}>
              <FormControl fullWidth margin="normal" variant="filled">
                <InputLabel shrink htmlFor="name">
                  Course Name
                </InputLabel>
                <OutlinedInput
                  id="name"
                  name="name"
                  placeholder="Please enter the course name*"
                  required
                  onChange={handleChange('name')}
                  value={course.name}
                />
              </FormControl>
              <FormControl
                variant="filled"
                margin="normal"
                sx={{ ml: 3, minWidth: 200 }}
              >
                <InputLabel id="isPublished-label">Status</InputLabel>
                <Select
                  labelId="isPublished-label"
                  id="isPublished"
                  value={course.isPublished}
                  onChange={handleChange('isPublished')}
                  label="Is Published"
                >
                  <MenuItem value={true}>Published</MenuItem>
                  <MenuItem value={false}>UnPublished</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="filled"
                margin="normal"
                sx={{ ml: 3, minWidth: 120 }}
              >
                <Button
                  component={ReactLink}
                  sx={{ height: '100%' }}
                  variant="outlined"
                  color="success"
                  target="_blank"
                  endIcon={<VisibilityIcon />}
                  to={`/courses/${course.slug}?preview=1`}
                >
                  Preview
                </Button>
              </FormControl>
            </Box>

            <FormControl fullWidth margin="normal" variant="filled">
              <InputLabel shrink htmlFor="description">
                Description
              </InputLabel>
              <OutlinedInput
                id="description"
                name="description"
                type="email"
                rows="4"
                multiline={true}
                placeholder="Please the course description*"
                required
                onChange={handleChange('description')}
                value={course.description}
              />
            </FormControl>
            <Grid
              container
              rowSpacing={{ xs: 4, lg: 0 }}
              columnSpacing={{ xs: 0, lg: 3 }}
            >
              <Grid item xs={12} lg={12}>
                <Box sx={{ position: 'relative' }}>
                  {course && course.teaserVideoUrl ? (
                    <video
                      className="MuiCardMedia-root MuiCardMedia-media css-e6k82g-MuiCardMedia-root"
                      src={course.teaserVideoUrl}
                      style={{ maxWidth: 800, width: '100%', height: 'auto' }}
                      controls
                    >
                      <source src={course.teaserVideoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src="https://via.placeholder.com/1000x500&text=Teaser Video"
                      alt=""
                      style={{ width: '100%' }}
                    />
                  )}

                  <Box sx={{ position: 'absolute', top: 0, right: 0, p: 1 }}>
                    <label htmlFor="upload-teaser-video">
                      <input
                        style={{ display: 'none' }}
                        id="upload-teaser-video"
                        name="upload-teaser-video"
                        type="file"
                        accept=".mp4"
                        onChange={handleTeaserVideoUpload}
                      />
                      <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                      >
                        <FileUploadIcon />
                      </Fab>
                    </label>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Box sx={{ position: 'relative', marginTop: '16px' }}>
              <img
                src={
                  course && course.imageUrl
                    ? `${course.imageUrl
                        .replace('_orig', '')
                        .replace('.jpeg', '.jpg')
                        .replace('.png', '.jpg')
                        .replace('.jpg', '_540w.jpg')}`
                    : 'https://via.placeholder.com/1000x667'
                }
                alt=""
                style={{ width: '100%' }}
              />

              <Box sx={{ position: 'absolute', top: 0, right: 0, p: 1 }}>
                <label htmlFor="upload-featured">
                  <input
                    style={{ display: 'none' }}
                    id="upload-featured"
                    name="upload-featured"
                    type="file"
                    accept=".jpg, .png, .jpeg"
                    onChange={handleFeatureUpload}
                  />
                  <Fab
                    color="secondary"
                    size="small"
                    component="span"
                    aria-label="add"
                  >
                    <FileUploadIcon />
                  </Fab>
                </label>
              </Box>
            </Box>
            {course.type === 'xapiTODO' ? (
              <Box sx={{ position: 'relative', marginTop: '16px' }}>
                <FormControl fullWidth margin="normal" variant="filled">
                  <InputLabel shrink htmlFor="description">
                    xAPI Published File
                  </InputLabel>
                  <OutlinedInput
                    id="contentUrl"
                    name="contentUrl"
                    type="text"
                    placeholder="Upload xAPI published package"
                    disabled
                    value={course.contentUrl}
                  />
                </FormControl>

                <Box sx={{ position: 'absolute', top: 14, right: 0, p: 1 }}>
                  <label htmlFor="upload-featured">
                    <input
                      style={{ display: 'none' }}
                      id="upload-featured"
                      name="upload-featured"
                      accept=".zip"
                      type="file"
                      onChange={handlexApiContentUpload}
                    />
                    <Fab
                      color="secondary"
                      size="small"
                      component="span"
                      aria-label="add"
                    >
                      <FileUploadIcon />
                    </Fab>
                  </label>
                </Box>
              </Box>
            ) : undefined}

            <Box sx={{ display: 'flex' }}>
              <FormControl fullWidth variant="filled" margin="normal">
                <InputLabel id="isFeatured-label">Is Featured?</InputLabel>
                <Select
                  labelId="isFeatured-label"
                  id="isFeatured"
                  value={course.isFeatured}
                  onChange={handleChange('isFeatured')}
                  label="Is Published"
                >
                  <MenuItem value={true}>Featured</MenuItem>
                  <MenuItem value={false}>Not Featured</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                margin="normal"
                sx={{ ml: 3 }}
              >
                <InputLabel id="isNew-label">Is New?</InputLabel>
                <Select
                  labelId="isNew-label"
                  id="isNew"
                  value={course.isNew}
                  onChange={handleChange('isNew')}
                  label="Is New"
                >
                  <MenuItem value={true}>New</MenuItem>
                  <MenuItem value={false}>Not New</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FormControl fullWidth margin="normal" variant="filled">
              <InputLabel shrink htmlFor="name">
                Accredible Group ID (Used for quiz completion)
              </InputLabel>
              <OutlinedInput
                id="accredibleGroupId"
                name="accredibleGroupId"
                placeholder="Please enter the group id to the certificate"
                onChange={handleChange('accredibleGroupId')}
                value={course.accredibleGroupId}
              />
            </FormControl>
            <Box>
              <LearningPathSelector
                onSelect={handleLearningPathSelect}
                initialList={course.learningPathIds}
              />
            </Box>
          </Grid>
        </Grid>
        {course.id ? (
          <>
            <Dialog
              open={openSaveRequired}
              onClose={handleSaveRequiredClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                You have made some changes
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Changes have been made that require updating first. Would you
                  like to continue and discard your changes?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSaveRequiredClose}>Cancel</Button>
                <Button
                  onClick={() => {
                    handleSaveRequiredClose()
                    onClose()
                  }}
                  autoFocus
                >
                  Discard changes
                </Button>
              </DialogActions>
            </Dialog>

            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}
            >
              <Typography
                component="h2"
                variant="h5"
                sx={{ textAlign: 'left', textTransform: 'uppercase', my: 2 }}
              >
                Course Topics
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  endIcon={<AddIcon />}
                  onClick={() => {
                    setTopic(emptyTopic)
                    setShowItem(true)
                  }}
                >
                  Add Topic
                </Button>
              </Box>
            </Box>

            <DataGridWithFiltering
              height="600px"
              rows={list}
              rowsCopy={listCopy}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              setRows={setList}
              onRowClick={({ row }) => {
                setTopic({
                  ...row
                })
                setShowItem(true)
              }}
              onCellClick={(_, event) => {
                event.defaultMuiPrevented = true
              }}
              getRowId={(row) => row.id}
              rowReordering={true}
              onRowOrderChange={(params) => {
                setSaveRequired(true)
                const rows = [...list]
                const row = rows.splice(params.oldIndex, 1)[0]
                rows.splice(params.targetIndex, 0, row)
                setList(rows)
              }}
              loading={dataLoading}
            />
          </>
        ) : undefined}
        <Box
          sx={{ display: 'flex', width: '100%', justifyContent: 'end', mt: 3 }}
        >
          <Button
            onClick={() => {
              if (!saveRequired) onClose()
              else setOpenSaveRequired(true)
            }}
            variant="outlined"
            color="warning"
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={submitButtonDisabled}
          >
            {course.id === null ? 'Add' : 'Update'}
          </Button>
        </Box>
      </Box>
      <SwipeableDrawer
        anchor="right"
        open={showItem}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{ p: 4 }}>
          {topic ? (
            <Topic
              current={topic}
              courseId={course.id}
              onClose={toggleDrawer(false)}
              setNotification={setNotification}
              onSuccess={(item) => {
                const index = list.findIndex((i) => i.id === item.id)
                const newList = [...list]
                newList.splice(index === -1 ? list.length : index, 1, item)
                setList(newList)
                setNotification({
                  open: true,
                  message: 'Topic has been saved',
                  severity: 'success'
                })
              }}
            />
          ) : undefined}
        </Box>
      </SwipeableDrawer>
    </>
  )
}

Course.propTypes = {
  current: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func
}

export default Course
