import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { fetch } from './common/api'
import Layout from './layout/Layout'
import awsExport from './aws-exports'
import theme from './mui-theme'
import ErrorBoundary from './common/ErrorBoundary'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import WebFont from 'webfontloader'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { LicenseInfo } from '@mui/x-license-pro'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

LicenseInfo.setLicenseKey(
  '0521be77cf9877d5fc51594e45f6b9f4Tz00NzIzOCxFPTE2ODkyNzYyOTAzNDQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)
Amplify.configure(awsExport)

function App() {
  queryClient.getQueryData(['coursesOnline']) ??
    queryClient.prefetchQuery(
      ['coursesOnline'],
      async () => await fetch(`/courses?types=online,xapi`)
    )

  useEffect(() => {
    WebFont.load({
      typekit: { id: process.env.REACT_APP_ADOBE_TYPEKIT_ID }
    })
  })

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Layout />
            </QueryClientProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App
