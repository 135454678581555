import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Typography,
  Grid,
  Box,
  Paper,
  FormControl,
  InputLabel,
  FilledInput,
  Button,
  Link
} from '@mui/material'
import BreadCrumb from '../course/BreadCrumb'
import ResourceTypeList from './ResourceTypeList'
import AngledBackground from './AngledBackground'
import HeroBanner from '../course/HeroBanner'
import { types } from './useResourceQueries.js'
import alertBellSvg from './alert-bell.svg'

export default function ResourceType() {
  const { type } = useParams()
  const [typeInfo, setTypeInfo] = useState(false)
  const [subscribeEmail, setSubscribeEmail] = useState('')
  const headerHeight = {
    xs: '100px',
    sm: '250px',
    md: '250px',
    lg: '250px',
    xl: '250px'
  }

  const showNewsLetterSubscribe = false

  useEffect(() => {
    window.scrollTo(0, 0)
    setTypeInfo(types.find((c) => c.type === type) ?? false)
  }, [type])

  return (
    <>
      <HeroBanner height={headerHeight} containerWidth="xl">
        <Typography variant="h1" color="white" sx={{ py: 4 }}>
          {typeInfo.name ?? ''}
        </Typography>
        <></>
      </HeroBanner>
      <Container
        component="section"
        maxWidth="xl"
        sx={{ pb: showNewsLetterSubscribe ? 15 : 30 }}
      >
        <BreadCrumb />
        <Box sx={{ pt: 3 }}>
          <ResourceTypeList type={type} page={0} limit={24} />
        </Box>
      </Container>

      {showNewsLetterSubscribe ? (
        <AngledBackground>
          <Box sx={{ pb: 4 }}>
            <Grid
              container
              sx={{ justifyContent: { xs: 'center', md: 'space-between' } }}
            >
              <Grid item sm={12} md={5}>
                <Box sx={{ position: 'relative' }}>
                  <Grid container>
                    <Grid item xs={12} lg={9}>
                      <Typography variant="h3" sx={{ mt: 2, mb: 1 }}>
                        Our kids industry newsletters are excellent.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component="p" sx={{ fontWeight: 'bold', mb: 2 }}>
                    SuperAwesome works to make the internet safer for kids. Our
                    kidtech is used by hundreds of brands and content-owners to
                    enable safe digital engagement with the global kids
                    audience.
                  </Typography>
                  <Typography component="p" sx={{ mb: 0 }}>
                    Built specifically to ensure total digital privacy for kids,
                    our technology footprint reaches almost half a billion kids
                    across North America, Europe, LATAM and APAC. We help brands
                    achieve their objectives while remaining compliant.
                    Headquartered in London, we have offices in New York,
                    Chicago, Los Angeles and Singapore.
                  </Typography>
                  <Box sx={{ position: 'absolute', top: '-140px' }}>
                    <img
                      width="109px"
                      alt="headphone-svg"
                      style={{
                        mt: 3
                      }}
                      src={alertBellSvg}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12} md={5}>
                <Box sx={{ position: { sx: 'flex', md: 'relative' } }}>
                  <Paper
                    elevation={8}
                    sx={{
                      p: 6,
                      borderRadius: 3,
                      position: { sx: 'flex', md: 'absolute' },
                      top: -180,
                      width: '100%',
                      mt: { xs: 5, md: 0 }
                    }}
                  >
                    <Typography variant="h3" sx={{ mb: { md: 4 } }}>
                      Subscribe today!
                    </Typography>

                    <FormControl
                      fullWidth
                      margin="none"
                      variant="filled"
                      sx={{ p: 0, my: { xs: 2, md: 5 } }}
                    >
                      <InputLabel shrink htmlFor="name">
                        Email
                      </InputLabel>
                      <FilledInput
                        id="subscribe_email"
                        name="subscribe_email"
                        placeholder="Enter your email*"
                        required
                        onChange={(e) => setSubscribeEmail(e.target.value)}
                      />
                    </FormControl>
                    <Button
                      component={Link}
                      type="submit"
                      variant="contained"
                      target="_blank"
                      href={`https://share.hsforms.com/1u_OIA8mSQuutQStXGYA8rg2zdm4?email=${subscribeEmail}`}
                    >
                      Submit
                    </Button>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </AngledBackground>
      ) : undefined}
    </>
  )
}
