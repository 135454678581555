import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { fetch, cancel as cancelRequest } from '../common/api'
import CourseCardLong from './CourseCardLong'
import { Typography, Grid } from '@mui/material'
import './Course.scss'

export default function WhatsNextCourses({ courseId, authed }) {
  const [completedCourse, setCompletedCourse] = useState({})
  const [newCourse, setNewCourse] = useState({})

  useEffect(() => {
    let mounted = true
    let courseRequest

    async function fetchData() {
      courseRequest = fetch(`/courses?type=online`)
      const courses = await courseRequest
      const cleanedCourses = courses.filter(
        (c) => c.id !== courseId && c.type === 'online'
      )
      const completedList = cleanedCourses.filter(
        (c) => c.percentCompleted === 100
      )
      const newList = cleanedCourses.filter((c) => !c.enrolledAt)

      if (mounted) {
        setCompletedCourse(
          completedList[Math.floor(Math.random() * completedList.length)]
        )

        setNewCourse(newList[Math.floor(Math.random() * newList.length)])
      }
    }

    fetchData()

    return () => {
      mounted = false
      cancelRequest(courseRequest)
    }
  }, [courseId, authed])

  return (
    <Grid
      container
      rowSpacing={{ xs: 3, sm: 0 }}
      columnSpacing={{ xs: 1, md: 2, lg: 6 }}
      justifyContent={authed && completedCourse ? 'center' : 'right'}
      sx={{ my: { xs: 0, md: 2, lg: 5 } }}
    >
      {authed && completedCourse ? (
        <Grid item xs={12} md={6}>
          <Typography variant="h2" sx={{ mb: { xs: 2, md: 3, lg: 4 } }}>
            Completed Courses
          </Typography>
          <CourseCardLong course={completedCourse} />
        </Grid>
      ) : undefined}

      <Grid item xs={12} md={6}>
        {authed ? (
          <Typography variant="h2" sx={{ mb: { xs: 2, md: 3, lg: 4 } }}>
            Try Another Course
          </Typography>
        ) : (
          <Typography variant="h2" sx={{ mb: { xs: 2, md: 3, lg: 4 } }}>
            A course you maybe interested in
          </Typography>
        )}
        <CourseCardLong course={newCourse} />
      </Grid>
    </Grid>
  )
}

WhatsNextCourses.propTypes = {
  courseId: PropTypes.string.isRequired,
  authed: PropTypes.bool
}
