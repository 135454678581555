import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { update, fetch, cancel as cancelRequest } from '../common/api'
import DataGridWithFiltering from '../common/DataGridWithFiltering'
import {
  Box,
  Button,
  Alert,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  TextField
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

function VirtualCourse({ current, onSuccess, onClose }) {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [showAlert, setShowAlert] = useState(null)
  const [list, setList] = useState([])
  const [listCopy, setListCopy] = useState([])
  const [course, setCourse] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)

  const handleChange = (prop) => (event) => {
    if (!event) return
    const value = event._isAMomentObject ? event.format() : event.target.value
    setCourse({ ...course, [prop]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitButtonDisabled(true)
    const url = course.id ? `/courses/${course.id}` : `/courses`

    try {
      setShowAlert(false)
      const c = await update(url, course)
      setCourse({ ...course, id: c.id })
      if (onSuccess && typeof onSuccess === 'function') onSuccess(c)
      setSubmitButtonDisabled(false)
      setShowAlert({
        severity: 'success',
        message: 'Course information saved!'
      })
    } catch (e) {
      setSubmitButtonDisabled(false)
      setShowAlert({ severity: 'error', message: e.message })
      console.log(e)
    }
  }

  const columns = [
    {
      field: 'firstName',
      headerName: 'Name',
      width: 250,
      editable: false
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 250,
      editable: false
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 350,
      editable: false
    },
    {
      field: 'enrolledAt',
      headerName: 'Enrolled On',
      width: 250,
      editable: false
    }
  ]

  useEffect(() => {
    let mounted = true
    let courseRequest

    async function fetchData() {
      setDataLoading(true)
      courseRequest = fetch(`/courses/${current.id}?related=enrollment`)
      const course = await courseRequest

      if (mounted) {
        setCourse(course)
        setSubmitButtonDisabled(false)
        setList(course.enrolledUsers)
        setListCopy(course.enrolledUsers)
        setDataLoading(false)
      }
    }

    if (current.id) fetchData()
    else {
      if (mounted) {
        setCourse(current)
        setSubmitButtonDisabled(false)
        setDataLoading(false)
      }
    }

    return () => {
      mounted = false
      cancelRequest(courseRequest)
    }
  }, [current])

  return (
    <>
      {showAlert ? (
        <Alert variant="outlined" severity={showAlert.severity} sx={{ my: 2 }}>
          {showAlert.message}
        </Alert>
      ) : undefined}

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          component="h1"
          variant="h4"
          sx={{ textAlign: 'left', textTransform: 'uppercase', mb: 2 }}
        >
          {course ? course.name : ''}
        </Typography>
        <Box>
          <IconButton
            aria-label="close"
            color="primary"
            onClick={onClose}
            sx={{ p: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      {course ? (
        <>
          <Box component="form" onSubmit={handleSubmit} autoComplete="off">
            <Box sx={{ display: 'flex' }}>
              <FormControl fullWidth margin="normal" variant="filled">
                <InputLabel shrink htmlFor="name">
                  Course Name*
                </InputLabel>
                <OutlinedInput
                  id="name"
                  name="name"
                  placeholder="Please enter the course name"
                  required
                  onChange={handleChange('name')}
                  value={course.name}
                />
              </FormControl>

              <FormControl
                fullWidth
                margin="normal"
                variant="filled"
                sx={{ ml: 3 }}
              >
                <InputLabel shrink htmlFor="zoomCode">
                  Zoom Link/Code*
                </InputLabel>
                <OutlinedInput
                  id="zoomCode"
                  name="zoomCode"
                  placeholder="Please enter the Zoom code/link"
                  required
                  onChange={handleChange('zoomCode')}
                  value={course.zoomCode}
                />
              </FormControl>

              <FormControl
                variant="filled"
                margin="normal"
                sx={{ ml: 3, width: 200 }}
              >
                <InputLabel id="isPublished-label">Status*</InputLabel>
                <Select
                  labelId="isPublished-label"
                  id="isPublished"
                  value={course.isPublished}
                  onChange={handleChange('isPublished')}
                  label="Is Published"
                >
                  <MenuItem value={true}>Open</MenuItem>
                  <MenuItem value={false}>Closed</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <FormControl
                fullWidth
                margin="normal"
                variant="filled"
                sx={{ width: 500 }}
              >
                <DateTimePicker
                  id="startTime"
                  name="startTime"
                  label="Start Time"
                  value={course.startTime}
                  onChange={handleChange('startTime')}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                variant="filled"
                sx={{ ml: 3, width: 500 }}
              >
                <DateTimePicker
                  id="endTime"
                  name="endTime"
                  label="End Time"
                  value={course.endTime}
                  onChange={handleChange('endTime')}
                  renderInput={(params) => <TextField {...params} required />}
                  required
                />
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <FormControl fullWidth margin="normal" variant="filled">
                <InputLabel shrink htmlFor="description">
                  Description*
                </InputLabel>
                <OutlinedInput
                  id="description"
                  name="description"
                  type="email"
                  rows="4"
                  multiline={true}
                  placeholder="Please the course description"
                  required
                  onChange={handleChange('description')}
                  value={course.description}
                />
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'end',
                mt: 3
              }}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                color="warning"
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={submitButtonDisabled}
              >
                {course.id === null ? 'Add' : 'Update'}
              </Button>
            </Box>
          </Box>

          <Typography variant="h3" sx={{ mt: 3, mb: 1 }}>
            Users Enrolled
          </Typography>
          <DataGridWithFiltering
            height="600px"
            rows={list}
            rowsCopy={listCopy}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            setRows={setList}
            onCellClick={(_, event) => {
              event.defaultMuiPrevented = true
            }}
            getRowId={(row) => row.id}
            loading={dataLoading}
          />
        </>
      ) : undefined}
    </>
  )
}

VirtualCourse.propTypes = {
  current: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func
}

export default VirtualCourse
