import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { update } from '../common/api'
import {
  Box,
  Button,
  Alert,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export default function Resource({ current, onSuccess, onClose }) {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [showAlert, setShowAlert] = useState(null)
  const [resource, setResource] = useState({
    key: '',
    type: '',
    value: ''
  })

  const handleChange = (prop) => (event) => {
    setResource({ ...resource, [prop]: event.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitButtonDisabled(true)

    try {
      setShowAlert(false)
      const r = await update('/resources', resource)
      setResource({ ...resource, id: r.id })
      if (onSuccess && typeof onSuccess === 'function') onSuccess(r)
      setSubmitButtonDisabled(false)
      setShowAlert({
        severity: 'success',
        message: 'Resource information saved!'
      })
    } catch (e) {
      setSubmitButtonDisabled(false)
      setShowAlert({ severity: 'error', message: e.message })
      console.log(e)
    }
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setResource(current)
      setSubmitButtonDisabled(false)
    }

    return () => {
      mounted = false
    }
  }, [current])

  return (
    <>
      {showAlert ? (
        <Alert variant="outlined" severity={showAlert.severity} sx={{ my: 2 }}>
          {showAlert.message}
        </Alert>
      ) : undefined}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          component="h1"
          variant="h4"
          sx={{ textAlign: 'left', textTransform: 'uppercase', mb: 2 }}
        >
          {resource ? `${resource.type} - ${resource.key}` : ''}
        </Typography>
        <Box>
          <IconButton
            aria-label="close"
            color="primary"
            onClick={onClose}
            sx={{ p: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box component="form" onSubmit={handleSubmit} autoComplete="off">
        <Box>
          <FormControl
            variant="filled"
            margin="normal"
            sx={{ minWidth: '100%' }}
          >
            <InputLabel id="type-label">WordPress Type</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              value={resource.type}
              onChange={handleChange('type')}
              label="WordPress Type"
            >
              <MenuItem value="blog">Blog</MenuItem>
              <MenuItem value="news">News</MenuItem>
              <MenuItem value="podcast">Podcast</MenuItem>
              <MenuItem value="guide">Guides</MenuItem>
              <MenuItem value="external-resource">External Resources</MenuItem>
              <MenuItem value="kidaware-in-the-world">
                KidAware Around The World
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="filled">
            <InputLabel shrink htmlFor="name">
              Option
            </InputLabel>
            <Select
              labelId="key-label"
              id="key"
              value={resource.key}
              onChange={handleChange('key')}
              label="WordPress Type"
            >
              <MenuItem value="order">Order</MenuItem>
              <MenuItem value="categories">Categories</MenuItem>
              <MenuItem value="tags">Tags</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="filled">
            <InputLabel shrink htmlFor="name">
              Value
            </InputLabel>
            <OutlinedInput
              id="value"
              name="value"
              placeholder="Please enter the resource value*"
              required
              onChange={handleChange('value')}
              value={resource.value}
            />
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'end',
              mt: 3
            }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              color="warning"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={submitButtonDisabled}
            >
              {resource.id === null || resource.id === undefined
                ? 'Add'
                : 'Update'}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

Resource.propTypes = {
  current: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func
}
