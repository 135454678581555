import PropTypes from 'prop-types'
import AccountForm from '../account/AccountForm'
import AccountEnrollment from '../account/AccountEnrollment'

export default function Account({ account, onSuccess, onClose }) {
  return (
    <>
      <AccountForm
        user={account}
        onSuccess={onSuccess}
        onClose={onClose}
        allowResetPassword={false}
      />
      <AccountEnrollment subId={account?.userId}></AccountEnrollment>
    </>
  )
}

Account.propTypes = {
  account: PropTypes.object,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func
}

Account.defaultProps = {
  account: null
}
