import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { cancel as cancelRequest } from '../common/api'
import { Storage } from 'aws-amplify'
import DataGridWithFiltering from '../common/DataGridWithFiltering'
import {
  Container,
  Typography,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Alert,
  SwipeableDrawer,
  IconButton,
  Button
} from '@mui/material'
import { fetch } from '../common/api'
import ButtonModal from '../common/ButtonModal'
import SignUpForm from '../account/SignUpForm'
import Account from './Account'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import SchoolIcon from '@mui/icons-material/School'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import UploadIcon from '@mui/icons-material/Upload'
import GppGoodIcon from '@mui/icons-material/GppGood'

export default function Accounts() {
  const [list, setList] = useState([])
  const [listCopy, setListCopy] = useState([])
  const [roleFilter, setRoleFilter] = useState('all')
  const [approvedStatusFilter, setSpprovedStatusFilter] = useState('all')
  const [showAlert, setShowAlert] = useState(null)
  const [showItem, setShowItem] = useState(false)
  const [account, setAccount] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)
  const { setNotification } = useOutletContext()
  const { id } = useParams()

  const emptyUser = {
    userId: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    companyName: '',
    userRole: 'student',
    userStatus: 'activated',
    newsletterConsent: '',
    communicateConsent: '',
    isApproved: false
  }

  const handleRoleFilterChange = (event) => {
    setRoleFilter(event.target.value)
  }

  const handleApprovedStatusFilterChange = (event) => {
    setSpprovedStatusFilter(event.target.value)

    if (event.target.value === 'all') {
      setList(listCopy)
      return
    }

    const updatedAccounts =
      event.target.value === 'whitelisted'
        ? listCopy.filter((a) => a.isWhitelisted)
        : listCopy.filter(
            (a) =>
              (event.target.value === 'is_approved'
                ? a.isApproved
                : !a.isApproved) && !a.isWhitelisted
          )

    setList(updatedAccounts)
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setShowItem(open)
  }

  const columns = [
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: function renderCell(params) {
        return (
          <Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center'
              }}
            >
              {params.row.userRole === 'super_administrator' ? (
                <AdminPanelSettingsIcon
                  sx={{ color: 'primary.main' }}
                ></AdminPanelSettingsIcon>
              ) : undefined}
              {params.row.userRole === 'administrator' ? (
                <AdminPanelSettingsIcon sx={{ color: 'success.main' }}>
                  {' '}
                </AdminPanelSettingsIcon>
              ) : undefined}
              {params.row.userRole === 'vilt_instructor' ? (
                <VideoCameraFrontIcon></VideoCameraFrontIcon>
              ) : undefined}
              {params.row.userRole === 'student' ? (
                <SchoolIcon></SchoolIcon>
              ) : undefined}
              <Typography sx={{ ml: 1 }}>
                {params.value} {params.row.lastName}
              </Typography>
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'emailAddress',
      headerName: 'Email',
      flex: 1,
      minWidth: 180,
      editable: false
    },
    {
      field: 'companyName',
      headerName: 'Company',
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'userRole',
      headerName: 'User Role',
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'isApproved',
      headerName: 'Approval Status',
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: function renderCell(params) {
        return (
          <Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center'
              }}
            >
              {params?.row?.isWhitelisted ? (
                <>
                  <GppGoodIcon sx={{ color: 'success.main' }}></GppGoodIcon>
                  <Typography sx={{ ml: 1 }}>Whitelisted</Typography>
                </>
              ) : params?.row?.isApproved ? (
                <>
                  <GppGoodIcon sx={{ color: 'success.main' }}></GppGoodIcon>
                  <Typography sx={{ ml: 1 }}>Approved</Typography>
                </>
              ) : (
                <>
                  <GppGoodIcon sx={{ color: 'error.main' }}></GppGoodIcon>
                  <Typography sx={{ ml: 1 }}>Not Approved</Typography>
                </>
              )}
            </Box>
          </Box>
        )
      }
    }
  ]

  const handleNewAccount = () => {
    setShowAlert({
      severity: 'success',
      message: 'User has been created'
    })
  }

  const handleUpload = async (e) => {
    const file = e.target.files[0]
    const ext = file.name.split('.').pop()
    const now = new Date()

    const fileName = `${now.getDate()}${now.getTime()}${
      now.getMonth() + 1
    }${now.getFullYear()}-${now.getTime()}`
    const path = `imported-users/${fileName}.${ext}`

    try {
      setNotification({
        open: true,
        message: `Starting upload`,
        severity: 'info'
      })

      await Storage.put(path, file, {
        contentType: 'text/csv',
        cacheControl: 'no-cache',
        resumable: false,
        completeCallback: () => {
          //resumable only
          setShowAlert({ severity: 'success', message: 'Upload completed!' })

          setNotification({
            open: true,
            message: 'Upload completed!',
            severity: 'success'
          })
        },
        progressCallback: (progress) => {
          const percent = Math.round((progress.loaded / progress.total) * 100)
          setNotification({
            open: true,
            message: `Uploading: ${percent} %`,
            severity: 'info'
          })
        },
        errorCallback: (err) => {
          setShowAlert({
            severity: 'error',
            message: `Error uploading! ${err.msg}`
          })
          setNotification({
            open: true,
            message: 'Error uploading.',
            severity: 'error'
          })
        }
      })

      setShowAlert({
        severity: 'success',
        message:
          'Your csv has been uploaded and we are now generating the new users in the background.  Please check back in a few min for the new users.'
      })

      setNotification({
        open: true,
        message: 'Upload completed!',
        severity: 'success'
      })
      e.target.value = null
    } catch (error) {
      console.error('Error uploading file: ', error)
      setShowAlert({
        severity: 'error',
        message: `Error uploading! ${error}`
      })
    }
  }

  useEffect(() => {
    let mounted = true
    let usersRequest
    let userRequest
    let domainListRequest

    async function fetchAndShowAccount() {
      try {
        userRequest = fetch(`/users/${id}`)
        const account = await userRequest

        setAccount({
          ...{
            userId: '',
            emailAddress: '',
            firstName: '',
            lastName: '',
            companyName: '',
            userRole: 'student',
            userStatus: 'activated',
            newsletterConsent: '',
            communicateConsent: '',
            isApproved: false
          },
          ...account
        })
        setShowItem(true)
      } catch (e) {
        if (mounted) {
          setShowAlert({
            severity: 'error',
            message: e.message
          })
        }
        console.error(e)
      }
    }

    if (id) fetchAndShowAccount()

    async function fetchData() {
      setDataLoading(true)
      const userRoleQuery = roleFilter !== 'all' ? `userRole=${roleFilter}` : ''
      try {
        usersRequest = fetch(`/users?${userRoleQuery}`)
        const accounts = await usersRequest
        domainListRequest = fetch(`/domains`)
        const list = await domainListRequest

        const accountsUpdates = accounts.map((a) => {
          const usersDomain = a.emailAddress.split('@').pop()
          const isWhitelisted = list.find(
            (d) => d.domainName === usersDomain && d.isApproved === true
          )
          return { ...a, ...{ isWhitelisted: isWhitelisted != null } }
        })

        if (mounted) {
          setList(accountsUpdates)
          setListCopy(accountsUpdates)
          setDataLoading(false)
        }
      } catch (e) {
        if (mounted) {
          setShowAlert({
            severity: 'error',
            message: e.message
          })
          setDataLoading(false)
        }
        console.error(e)
      }
    }

    fetchData()

    return () => {
      mounted = false
      cancelRequest(usersRequest)
      cancelRequest(domainListRequest)
      cancelRequest(userRequest)
    }
  }, [roleFilter, id])

  return (
    <>
      <Container component="main">
        {showAlert ? (
          <Alert
            variant="outlined"
            severity={showAlert.severity}
            sx={{ my: 2 }}
          >
            {showAlert.message}
          </Alert>
        ) : undefined}

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            component="h1"
            variant="h4"
            sx={{ textAlign: 'left', textTransform: 'uppercase', mb: 2 }}
          >
            Accounts
          </Typography>

          <Box sx={{ display: 'flex' }}>
            <Box>
              <label htmlFor="upload-batch-users">
                <input
                  style={{ display: 'none' }}
                  id="upload-batch-users"
                  name="upload-batch-users"
                  type="file"
                  accept=".csv"
                  onChange={handleUpload}
                />
                <Button
                  component="span"
                  id="batch-users-upload-button"
                  variant="outlined"
                  endIcon={<UploadIcon />}
                  size="small"
                  color="secondary"
                  sx={{ ml: { xs: 0, lg: 1 } }}
                >
                  Batch Upload
                </Button>
              </label>
            </Box>
            <Box>
              <ButtonModal
                component={
                  <Button
                    id="sign-up-button"
                    variant="outlined"
                    endIcon={<AddIcon />}
                    size="small"
                    sx={{ ml: { xs: 0, lg: 1 } }}
                  >
                    Add Account
                  </Button>
                }
              >
                <SignUpForm
                  autoLogin={false}
                  generatePassword={true}
                  handlePostSignUp={handleNewAccount}
                  title="Register new Account"
                />
              </ButtonModal>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="role-filter-approved-status-label">
              Approved Status
            </InputLabel>
            <Select
              labelId="role-filter-approved-status-label"
              id="role-filter"
              value={approvedStatusFilter}
              onChange={handleApprovedStatusFilterChange}
              label="Approved Status"
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="is_approved">Approved</MenuItem>
              <MenuItem value="not_approved">Not Approved</MenuItem>
              <MenuItem value="whitelisted">Whitelisted</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="role-filter-label">User Role</InputLabel>
            <Select
              labelId="role-filter-label"
              id="role-filter"
              value={roleFilter}
              onChange={handleRoleFilterChange}
              label="User Role"
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="student">Student</MenuItem>
              <MenuItem value="administrator">Administrator</MenuItem>
              <MenuItem value="super_administrator">
                Super Administrator
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <DataGridWithFiltering
          height="800px"
          rows={list}
          rowsCopy={listCopy}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          setRows={setList}
          onRowClick={({ row }) => {
            setAccount({ ...emptyUser, ...row })
            setShowItem(true)
          }}
          onCellClick={(_, event) => {
            event.defaultMuiPrevented = true
          }}
          getRowId={(row) => row?.userId}
          loading={dataLoading}
        />
      </Container>
      <SwipeableDrawer
        anchor="right"
        open={showItem}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{ p: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              component="h1"
              variant="h5"
              sx={{ textAlign: 'left', textTransform: 'uppercase', mb: 2 }}
            >
              {account ? account.firstName : ''}{' '}
              {account ? account.lastName : ''}
            </Typography>
            <Box>
              <IconButton
                aria-label="close"
                color="primary"
                onClick={toggleDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Account
            account={account}
            onClose={toggleDrawer(false)}
            onSuccess={(item) => {
              const index = list.findIndex((i) => i.userId === item.userId)
              const newList = [...list]
              newList.splice(index, 1, item)
              setList(newList)
              setNotification({
                open: true,
                message: 'Account has been saved',
                severity: 'success'
              })
            }}
          />
        </Box>
      </SwipeableDrawer>
    </>
  )
}
