import { API as AmplifyAPI } from 'aws-amplify'

const awsAPI = 'KidAwareApi'
const buildHeader = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'max-age=3600'
    }
  }
}

const getToken = () => {
  const userData = localStorage.getItem('user')
  return userData ? JSON.parse(userData).token : 'public'
}

export const buildBasicAuth = () => {
  const token = getToken()
  return `Bearer ${token}`
}

export const fetch = (path) => {
  return AmplifyAPI.get(awsAPI, path, buildHeader())
}

export const update = (path, body) => {
  const init = {
    body: body,
    ...buildHeader()
  }
  return AmplifyAPI.post(awsAPI, path, init)
}

export const destroy = (path) => {
  return AmplifyAPI.del(awsAPI, path, buildHeader())
}

export const cancel = (promise) => {
  return AmplifyAPI.cancel(promise, 'request cancelled')
}
