import Accounts from './Accounts'
import Courses from './Courses'
import Kill from './Kill'
import Resources from './Resources'
import Whitelisting from './Whitelisting'
import VirtualCourses from './VirtualCourses'
import AdminHeader from './AdminHeader'
import Pages from './Pages'
import { RequireAuth } from '../account/auth'
import LearningPaths from './LearningPaths'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const adminRroutes = [
  {
    path: '/admin',
    name: 'Dashboard',
    element: (
      <RequireAuth forceLogin={true} adminOnly={true}>
        <AdminHeader />
      </RequireAuth>
    ),
    children: [
      {
        path: 'accounts',
        name: 'Accounts',
        element: <Accounts />,
        children: [
          {
            path: ':id',
            name: 'Account',
            element: <Accounts />
          }
        ]
      },
      {
        path: 'pages',
        name: 'Pages',
        element: <Pages />
      },
      {
        path: 'courses',
        name: 'Courses',
        element: <Courses />
      },
      {
        path: 'learning-paths',
        name: 'Learning Paths',
        element: <LearningPaths />
      },
      {
        path: 'virtual-instructor-led-courses',
        name: 'Virtual Instructor-led Courses',
        element: (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <VirtualCourses />
          </LocalizationProvider>
        )
      },
      {
        path: 'resources',
        name: 'Resources',
        element: <Resources />
      },
      {
        path: 'whitelisting',
        name: 'Whitelisting',
        element: <Whitelisting />
      },
      {
        path: 'kill',
        name: 'Kill',
        element: <Kill />
      }
    ]
  }
]

export default adminRroutes
