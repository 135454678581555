import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import LinearProgress from '@mui/material/LinearProgress'

function SearchToolbar(props) {
  return (
    <TextField
      fullWidth
      size="small"
      value={props.value}
      onChange={props.onChange}
      placeholder="Search..."
      InputProps={{
        autoFocus: true,
        style: { border: 'none', borderRadius: '4px 4px 0 0' },
        startAdornment: (
          <SearchIcon
            fontSize="small"
            sx={{ mr: 1, color: 'secondary.main' }}
          />
        ),
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: props.value ? 'visible' : 'hidden' }}
            onClick={props.clearSearch}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )
      }}
    />
  )
}

SearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export default function DataGridWithFiltering({
  columns,
  rows,
  rowsCopy,
  setRows,
  onRowClick,
  getRowId,
  pageSize,
  height,
  rowsPerPageOptions,
  apiRef,
  onCellClick,
  onCellEditStop,
  onCellEditCommit,
  rowReordering,
  onRowOrderChange,
  loading
}) {
  const [searchText, setSearchText] = useState('')
  const escapeRegExp = (value) =>
    value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

  const requestSearch = (searchValue) => {
    const searchRowes =
      searchValue.trimStart().length < searchText.length ? rowsCopy : rows

    setSearchText(searchValue.trimStart())

    if (searchValue.trimStart() === '') {
      setRows && setRows(rowsCopy)
      return
    }

    const searchRegex = new RegExp(escapeRegExp(searchValue.trimStart()), 'i')
    const filteredRows = searchRowes?.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString())
      })
    })

    setRows && setRows(filteredRows)
  }

  const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
    color:
      theme.palette.mode === 'light'
        ? 'rgba(0,0,0,.85)'
        : 'rgba(255,255,255,0.85)',
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d'
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-row.MuiDataGrid-row.Mui-selected':
      {
        backgroundColor: theme.palette.secondary.light
      },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell.MuiDataGrid-cell--editable':
      {
        color: theme.palette.secondary.main,
        cursor: 'pointer'
      },
    '& .MuiDataGrid-cell': {
      color:
        theme.palette.mode === 'light'
          ? 'rgba(0,0,0,.85)'
          : 'rgba(255,255,255,0.65)'
    }
  }))

  useEffect(() => {
    let mounted = true

    if (mounted) requestSearch(searchText)

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsCopy])

  return (
    <div style={{ height: height }}>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <StyledDataGridPro
            components={{
              Toolbar: SearchToolbar,
              LoadingOverlay: LinearProgress
            }}
            loading={loading}
            rows={rows ?? []}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowClick={onRowClick}
            onCellClick={onCellClick}
            onCellEditStop={onCellEditStop}
            onCellEditCommit={onCellEditCommit}
            getRowId={getRowId}
            apiRef={apiRef}
            rowReordering={rowReordering}
            onRowOrderChange={onRowOrderChange}
            className="data-grid-with-filter"
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch('')
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

DataGridWithFiltering.propTypes = {
  height: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
  pageSize: PropTypes.number,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  rowsCopy: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  getRowId: PropTypes.func,
  apiRef: PropTypes.object,
  onCellEditStop: PropTypes.func,
  onCellClick: PropTypes.func,
  onCellEditCommit: PropTypes.func,
  rowReordering: PropTypes.bool,
  onRowOrderChange: PropTypes.func,
  loading: PropTypes.bool
}

DataGridWithFiltering.defaultProps = {
  height: '400px',
  pageSize: 5,
  rowsPerPageOptions: 5
}
