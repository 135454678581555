import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Document, Page, pdfjs } from 'react-pdf'
import { Storage } from 'aws-amplify'
import { Box, Button, Typography } from '@mui/material'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

export default function TopicPdf({ attachment }) {
  const [file, setFile] = useState('')
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  useEffect(() => {
    let mounted = true

    const getAttachment = async () => {
      if (!attachment.uri) return

      if (attachment.amplified) {
        setFile(attachment.uri)
        return
      }

      const uri = attachment.uri
        .replace(`${process.env.REACT_APP_ASSETS_S3_URL}/`, '')
        .replace(`public/`, '')

      const r = await Storage.get(uri)
      setFile(r)
    }

    if (mounted) getAttachment()

    return () => {
      mounted = false
    }
  }, [attachment.uri, attachment.amplified])

  return (
    <>
      <Typography sx={{ textAlign: 'right', mb: 1 }}>
        <a href={file} target="_blank" rel="noreferrer">
          Click here to download
        </a>
      </Typography>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
        <Button
          color="secondary"
          variant="outlined"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </Button>
        <Typography>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </Typography>
        <Button
          color="secondary"
          variant="outlined"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </Button>
      </Box>
    </>
  )
}

TopicPdf.propTypes = {
  attachment: PropTypes.object.isRequired
}
