import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useAuth } from '../account/auth'
import PasswordRequirements from './PasswordRequirements'
import {
  TextField,
  Box,
  Typography,
  Alert,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material'
function SignUpForm({
  closeModal,
  autoLogin,
  generatePassword,
  title,
  handlePostSignUp,
  initialEmail
}) {
  const [showAlert, setShowAlert] = useState(false)
  const [email, setEmail] = useState(false)
  const [tempPassword, setTempPassword] = useState(false)
  const [signUpInProgress, setSignUpInProgress] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const auth = useAuth()

  const newPassword = (
    charNum = 30,
    chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$&*Z(){}[]'
  ) =>
    Array.from(crypto.getRandomValues(new Uint32Array(charNum)))
      .map((x) => chars[x % chars.length])
      .join('')
      .concat('1Ik')

  const handleEmail = (e) => {
    setEmail(e.target.value.trim())
  }

  const handleSignUpSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSignUpInProgress(true)

    const data = new FormData(e.currentTarget)

    try {
      const username = data.get('email').trim()
      const password = !generatePassword
        ? data.get('password').trim()
        : tempPassword
      const company = data.get('company').trim()
      const first_name = data.get('first_name').trim()
      const last_name = data.get('last_name').trim()
      const newsletter = data.get('newsletter_consent')
      const communicate_consent = data.get('communicate_consent')
      const loginToken = await executeRecaptcha('loginToken')

      const signupMeta = {
        ...{ username },
        ...{ password },
        ...{ validationData: { captcha: loginToken } },
        ...{
          attributes: {
            email: username,
            given_name: first_name,
            family_name: last_name,
            'custom:user_role': 'student',
            'custom:company_name': company,
            'custom:newsletter_consent': newsletter,
            'custom:communicate_consent': communicate_consent
          }
        }
      }

      await Auth.signUp(signupMeta)

      const newToken = await executeRecaptcha('signin')

      const loginUser = autoLogin
        ? await Auth.signIn(username, password, { captcha: newToken })
        : false

      if (autoLogin) await auth.verifyUsersEmail(loginUser)

      if (handlePostSignUp && typeof handlePostSignUp === 'function')
        handlePostSignUp(loginUser)

      if (closeModal && typeof closeModal === 'function') closeModal()
      setSignUpInProgress(false)
    } catch (e) {
      setSignUpInProgress(false)
      setShowAlert(e.message)
      console.error(e.code)
    }
  }

  useEffect(() => {
    let mounted = true

    if (mounted) {
      setTempPassword(newPassword())
      setEmail(initialEmail)
    }

    return () => {
      mounted = false
    }
  }, [generatePassword, initialEmail])

  return (
    <>
      <Box component="form" onSubmit={handleSignUpSubmit}>
        <Typography
          component="h1"
          variant="h5"
          sx={{ textAlign: 'center', textTransform: 'uppercase' }}
        >
          {title}
        </Typography>
        {showAlert ? (
          <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
            {showAlert}
          </Alert>
        ) : undefined}
        <TextField
          margin="normal"
          required
          fullWidth
          id="first_name"
          label="First Name"
          name="first_name"
          autoComplete="first_name"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="last_name"
          label="Last Name"
          name="last_name"
          autoComplete="last_name"
        />
        <TextField
          margin="normal"
          fullWidth
          id="company"
          label="Company"
          name="company"
          autoComplete="company"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          type="email"
          label="Work Email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={handleEmail}
        />
        {!generatePassword ? (
          <>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirm-password"
              label="Confirm your Password"
              type="password"
              id="confirm-password"
              autoComplete="confirm-password"
            />
            <PasswordRequirements />
          </>
        ) : (
          <>
            <Typography variant="h6" sx={{ mt: 3 }}>
              Temp password :
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              readOnly
              name="tempPass"
              sx={{ backgroundColor: '#f3f3f3', mb: 3 }}
              label=""
              type="text"
              value={tempPassword}
            />
          </>
        )}
        <FormControlLabel
          componentsProps={{ typography: { variant: 'subtitle2' } }}
          control={<Checkbox name="communicate_consent" value="true" />}
          label="I consent to receive email communication about other SuperAwesome products and services. (optional)"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={signUpInProgress}
          sx={{ mt: 3, mb: 2, p: 2 }}
        >
          {signUpInProgress ? <span>Processing...</span> : <span>Sign Up</span>}
        </Button>
      </Box>
    </>
  )
}

SignUpForm.propTypes = {
  closeModal: PropTypes.func,
  handlePostSignUp: PropTypes.func,
  autoLogin: PropTypes.bool,
  generatePassword: PropTypes.bool,
  title: PropTypes.string,
  initialEmail: PropTypes.string
}

SignUpForm.defaultProps = {
  autoLogin: true,
  generatePassword: false,
  title: 'Sign Up to to gain access to KidAware Training'
}
export default SignUpForm
