import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import LoginForm from './LoginForm'
import { Modal, Box } from '@mui/material'

function LoginPrompt({ returnPath }) {
  const [modalOpen, setModelOpen] = useState(false)

  const handleModalClose = () => setModelOpen(false)

  useEffect(() => {
    let mounted = true
    if (mounted) setModelOpen(true)
    return () => {
      mounted = false
    }
  }, [])

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '25%',
          left: '50%',
          transform: 'translate(-50%, -25%)',
          maxWidth: 1000,
          width: 'calc(100% - 50px)',
          bgcolor: 'background.paper',
          boxShadow: 8,
          p: 5
        }}
      >
        <LoginForm
          returnPath={returnPath}
          closeModal={() => setModelOpen(false)}
        />
      </Box>
    </Modal>
  )
}

LoginPrompt.propTypes = {
  returnPath: PropTypes.string
}

export default LoginPrompt
