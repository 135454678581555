import { useEffect, useState } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Storage } from 'aws-amplify'
import { update } from '../common/api'
import { nanoid } from 'nanoid'
import {
  Box,
  Grid,
  Button,
  Alert,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Fab,
  Divider
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TopicEditor from './TopicEditor'
import TopicVideo from './TopicVideo'
import TopicXapi from './TopicXapi'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import HtmlIcon from '@mui/icons-material/Html'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DeleteIcon from '@mui/icons-material/Delete'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { v4 as uuid } from 'uuid'
import TopicPdf from './TopicPdf'
import ButtonConfirm from '../common/ButtonConfirm'

function Topic({ current, courseId, onSuccess, onClose, setNotification }) {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [showAlert, setShowAlert] = useState(null)
  const [topic, setTopic] = useState(null)

  const handleChange = (prop) => (event) => {
    setTopic({ ...topic, [prop]: event.target.value })
  }

  const formatTopicPayload = (topic) => ({
    ...(topic.id ? { id: topic.id } : undefined),
    isPublished: topic.isPublished,
    ...(topic.attachments
      ? { attachments: topic.attachments.filter((a) => a.kind !== undefined) }
      : { attachments: [] }),
    name: topic.name,
    ...(topic.pdfUploaded ? { pdfUploaded: topic.pdfUploaded } : undefined),
    estimatedTime: topic.estimatedTime
      ? topic.estimatedTime
      : topic.estimatedTimeMinutes
  })

  const updateTopic = async (topic) => {
    setSubmitButtonDisabled(true)
    try {
      setShowAlert(false)

      const url = topic.id
        ? `/courses/${courseId}/topics/${topic.id}`
        : `/courses/${courseId}/topics`

      const updatedTopic = await update(url, formatTopicPayload(topic))

      const tempTopic = {
        ...formatTopicPayload(updatedTopic),
        ...{ attachments: formatTopicPayload(topic).attachments },
        ...{ isPublished: formatTopicPayload(topic).isPublished }
      }

      if (onSuccess && typeof onSuccess === 'function')
        onSuccess(updatedTopic === 'Success' ? topic : tempTopic)

      setTopic(tempTopic)
      setSubmitButtonDisabled(false)
      setShowAlert({
        severity: 'success',
        message: 'Topic information saved!'
      })
      setNotification({
        open: true,
        severity: 'success',
        message: 'Topic information saved!'
      })
    } catch (e) {
      setSubmitButtonDisabled(false)
      setShowAlert({ severity: 'error', message: e.message })
      setNotification({
        open: true,
        severity: 'error',
        message: e.message
      })
      console.error(e)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    updateTopic(topic)
  }

  const getUpdatedAttachments = (attachment, remove) => {
    const index = topic.attachments.findIndex((a) => a.id === attachment.id)
    const newAttachments = [...topic.attachments]
    if (remove) newAttachments.splice(index, 1)
    else newAttachments.splice(index, 1, attachment)
    return newAttachments
  }

  const handleRemoveAttachment = (attachment) => {
    const updatedTopic = {
      ...topic,
      attachments: getUpdatedAttachments(attachment, true)
    }

    setTopic(updatedTopic)
  }

  const handleSaveAttachment = (attachment) => {
    const tempAttachment = {
      ...attachment,
      ...{ AttachmentKind: attachment.kind, kind: attachment.kind }
    }

    const updatedTopic = {
      ...topic,
      attachments: getUpdatedAttachments(tempAttachment)
    }

    setTopic(updatedTopic)
    updateTopic(updatedTopic)
  }

  const handleAttachmentUpload = (e, attachment) => {
    handleUpload(
      e,
      attachment,
      `courses/${courseId}/topics/${topic.id}/attachments`
    )
  }

  const buildXapiUploadPath = (attachment) => {
    return `courses/${courseId}/xapi/zip/topics/${topic.id}/attachments/${attachment.id}`
  }

  const buildXapiPublishPath = (attachment) => {
    const uploadPath = buildXapiUploadPath(attachment)
    const contentFolder = uploadPath
      .substring(
        0,
        uploadPath.indexOf(attachment.id) + attachment.id.length + 1
      )
      .replace('zip', 'content')
    return (
      process.env.REACT_APP_ASSETS_S3_URL + `/${contentFolder}/index_lms.html`
    )
  }

  const handlexApiContentUpload = (e, attachment) => {
    const updatedAttachment = {
      ...attachment
    }
    handleUpload(e, updatedAttachment, buildXapiUploadPath(attachment))
  }

  const handleEditorUpload = (file) => {
    const ext = file.name.split('.').pop()
    const path = `courses/${courseId}/${
      topic.id
    }/attachments/${topic.name.replace(' ', '-')}/${file.name}-${uuid()}.${ext}`
    const url = process.env.REACT_APP_ASSETS_S3_URL + `/${path}`

    setNotification({
      open: true,
      message: `Starting upload`,
      severity: 'info'
    })

    return new Promise((resolve) => {
      uploadToAws(path, file).then(() => {
        resolve({ data: { link: url } })
      })
    })
  }

  const uploadToAws = (path, file) => {
    return Storage.put(path, file, {
      cacheControl: 'no-cache',
      resumable: false,
      progressCallback: (progress) => {
        const percent = Math.round((progress.loaded / progress.total) * 100)
        setNotification({
          open: true,
          message: `Uploading: ${percent} %`,
          severity: 'info'
        })
      },
      errorCallback: () => {
        setNotification({
          open: true,
          message: 'Error uploading.',
          severity: 'error'
        })
      }
    })
  }

  const handleUpload = async (e, attachment, path) => {
    const file = e.target.files[0]
    const ext = file.name.split('.').pop()
    const fileName = `${topic.name.replaceAll(' ', '-')}-${uuid()}.${ext}`
    const fullPath = `${path}/${fileName}`

    try {
      setNotification({
        open: true,
        message: `Starting upload`,
        severity: 'info'
      })

      await uploadToAws(fullPath, file)

      setNotification({
        open: true,
        message: 'Upload completed!',
        severity: 'success'
      })

      const updatedAttachment = {
        ...attachment,
        ...{ uri: fullPath },
        ...(attachment.kind === 'pdf_embed'
          ? { AttachmentKind: attachment.kind }
          : { kind: attachment.kind })
      }

      const updatedTopic = {
        ...topic,
        pdfUploaded: attachment.kind === 'pdf_embed',
        attachments: getUpdatedAttachments(updatedAttachment)
      }

      setTopic(updatedTopic)
      await updateTopic(updatedTopic)
      e.target.value = null
    } catch (error) {
      console.error('Error uploading file: ', error)
      setShowAlert({
        severity: 'error',
        message: `Error uploading! ${error}`
      })
    }
  }

  const handleNewAttachment = (index, kind) => {
    const id = nanoid(16)
    const newAttachment = {
      id: kind === 'xapi' || kind === 'xapi_quiz' ? id : null,
      ...(kind === 'pdf_embed' ? { AttachmentKind: kind } : { kind: kind }),
      kind: kind,
      isPublished: true,
      ...(kind === 'text' ? { html: 'text' } : { uri: '' })
    }
    const newAttachments = [...topic.attachments]
    newAttachments.splice(index, 0, newAttachment)

    const updatedTopic = {
      ...topic,
      attachments: newAttachments
    }

    setTopic(updatedTopic)
  }

  useEffect(() => {
    let mounted = true

    if (current && mounted) {
      setTopic(current)
      setSubmitButtonDisabled(false)
    }

    return () => {
      mounted = false
    }
  }, [current])

  return (
    <>
      {showAlert ? (
        <Alert variant="outlined" severity={showAlert.severity} sx={{ my: 2 }}>
          {showAlert.message}
        </Alert>
      ) : undefined}

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          component="h1"
          variant="h4"
          sx={{ textAlign: 'left', textTransform: 'uppercase', mb: 2 }}
        >
          {topic ? topic.name : ''}
        </Typography>
        <Box>
          <IconButton
            aria-label="close"
            color="primary"
            onClick={onClose}
            sx={{ p: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        autoComplete="off"
        sx={{ maxWidth: 1200 }}
      >
        {topic ? (
          <Grid
            container
            rowSpacing={{ xs: 4, lg: 0 }}
            columnSpacing={{ xs: 0, lg: 3 }}
          >
            <Grid item xs={12} lg={5}>
              <Box>
                <FormControl fullWidth margin="normal" variant="filled">
                  <InputLabel shrink htmlFor="name">
                    Topic Name
                  </InputLabel>
                  <OutlinedInput
                    id="name"
                    name="name"
                    placeholder="Please enter the course name*"
                    required
                    onChange={handleChange('name')}
                    value={topic ? topic.name : ''}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} lg={2}>
              <FormControl fullWidth variant="filled" margin="normal">
                <InputLabel shrink htmlFor="name">
                  Estimate Time
                </InputLabel>
                <OutlinedInput
                  id="estimatedTime"
                  name="estimatedTime"
                  placeholder="Please enter the estimateds time to complete topic*"
                  required
                  onChange={handleChange('estimatedTime')}
                  value={topic ? topic.estimatedTime : 0}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3}>
              <FormControl fullWidth variant="filled" margin="normal">
                <InputLabel id="isPublished-label">Status</InputLabel>
                {topic && topic.isPublished !== undefined ? (
                  <Select
                    labelId="isPublished-label"
                    id="isPublished"
                    value={topic.isPublished}
                    onChange={handleChange('isPublished')}
                    label="Is Published"
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value={true}>Published</MenuItem>
                    <MenuItem value={false}>UnPublished</MenuItem>
                  </Select>
                ) : undefined}
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={2}>
              <FormControl fullWidth variant="filled" margin="normal">
                <Button
                  component={ReactLink}
                  sx={{
                    height: '100%',
                    paddingBottom: '14px',
                    paddingTop: '15px'
                  }}
                  variant="outlined"
                  color="success"
                  target="_blank"
                  endIcon={<VisibilityIcon />}
                  to={`/courses/${courseId}/${topic.id}?preview=1`}
                >
                  Preview
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        ) : undefined}
        <Box>
          {topic && topic.attachments
            ? topic.attachments.map((a, index) => (
                <Box key={index}>
                  <Divider sx={{ my: 3 }}>
                    <Fab
                      color="primary"
                      size="small"
                      component="span"
                      aria-label="add"
                      sx={{ mr: 2 }}
                      onClick={() => handleNewAttachment(index, 'text')}
                    >
                      <HtmlIcon />
                    </Fab>

                    <Fab
                      color="primary"
                      size="small"
                      component="span"
                      aria-label="add"
                      sx={{ mr: 2 }}
                      onClick={() => handleNewAttachment(index, 'video')}
                    >
                      <VideoCallIcon />
                    </Fab>

                    <Fab
                      color="primary"
                      size="small"
                      component="span"
                      sx={{ mr: 2 }}
                      aria-label="add"
                      onClick={() => handleNewAttachment(index, 'pdf_embed')}
                    >
                      <PictureAsPdfIcon />
                    </Fab>

                    <Fab
                      color="primary"
                      size="small"
                      component="span"
                      aria-label="add"
                      onClick={() => handleNewAttachment(index, 'xapi')}
                    >
                      <AccountBalanceIcon />
                    </Fab>
                  </Divider>
                  {a.kind === 'xapi' || a.kind === 'xapi_quiz' ? (
                    <>
                      <FormControl fullWidth variant="filled" margin="normal">
                        <InputLabel shrink htmlFor="name">
                          LMS Path
                        </InputLabel>
                        <OutlinedInput value={buildXapiPublishPath(a)} />
                      </FormControl>
                      <Box sx={{ position: 'relative', marginTop: '16px' }}>
                        <FormControl fullWidth margin="normal" variant="filled">
                          <InputLabel shrink htmlFor="description">
                            xAPI Published File
                          </InputLabel>
                          <OutlinedInput
                            id="contentUrl"
                            name="contentUrl"
                            type="text"
                            placeholder="Upload xAPI published package"
                            disabled
                            value={a.uri}
                          />
                        </FormControl>

                        <TopicXapi
                          courseId={courseId}
                          topicId={topic.id}
                          attachment={a}
                          preview={true}
                        />

                        <Box
                          sx={{ position: 'absolute', top: 14, right: 0, p: 1 }}
                        >
                          <label htmlFor="upload-xapi-attachment">
                            <input
                              style={{ display: 'none' }}
                              id="upload-xapi-attachment"
                              name="upload-xapi-attachment"
                              type="file"
                              accept=".zip"
                              onChange={(e) => handlexApiContentUpload(e, a)}
                            />
                            <Fab
                              color="secondary"
                              size="small"
                              component="span"
                              aria-label="add"
                            >
                              <FileUploadIcon />
                            </Fab>

                            <ButtonConfirm
                              confirmAction={() => handleRemoveAttachment(a)}
                              component={
                                <Fab
                                  id={`attachment-xapi-delete-confirm-${index}`}
                                  color="primary"
                                  size="small"
                                  component="span"
                                  aria-label="remove"
                                  sx={{ ml: 1 }}
                                >
                                  <DeleteIcon />
                                </Fab>
                              }
                            >
                              <>
                                Please confirm if you would like to delete the
                                XAPI attachment. You must click
                                &quot;Update&quot; to save the deletion.
                              </>
                            </ButtonConfirm>
                          </label>
                        </Box>
                      </Box>
                    </>
                  ) : undefined}
                  {a.kind === 'text' ? (
                    <TopicEditor
                      index={index}
                      attachment={a}
                      handleSave={handleSaveAttachment}
                      handleRemove={handleRemoveAttachment}
                      handleImageUplaod={handleEditorUpload}
                    />
                  ) : undefined}
                  {a.kind === 'video' ? (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Box sx={{ position: 'relative', width: 'fit-content' }}>
                        <TopicVideo attachment={a} />
                        <Box
                          sx={{ position: 'absolute', top: 0, right: 0, p: 1 }}
                        >
                          <label htmlFor={`upload-attachment-video-${index}`}>
                            <input
                              style={{ display: 'none' }}
                              id={`upload-attachment-video-${index}`}
                              name={`upload-attachment-video-${index}`}
                              type="file"
                              accept=".mp4"
                              onChange={(e) => handleAttachmentUpload(e, a)}
                            />
                            <Fab
                              color="secondary"
                              size="small"
                              component="span"
                              aria-label="add"
                            >
                              <FileUploadIcon />
                            </Fab>
                          </label>

                          <ButtonConfirm
                            confirmAction={() => handleRemoveAttachment(a)}
                            component={
                              <Fab
                                id={`attachment-video-delete-confirm-${index}`}
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="remove"
                                sx={{ ml: 1 }}
                              >
                                <DeleteIcon />
                              </Fab>
                            }
                          >
                            <>
                              Please confirm if you would like to delete the
                              video attachment. You must click
                              &quot;Update&quot; to save the deletion.
                            </>
                          </ButtonConfirm>
                        </Box>
                      </Box>
                    </Box>
                  ) : undefined}
                  {a.kind === 'pdf_raw' ? (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          width: 'fit-content',
                          minWidth: 300,
                          minHeight: 100,
                          pt: 7
                        }}
                      >
                        <OutlinedInput
                          sx={{
                            width: '100%',
                            display: 'flex'
                          }}
                          readonly
                          value={a ? a.uri : ''}
                          multiline
                        />

                        <Box
                          sx={{ position: 'absolute', top: 0, right: 0, p: 1 }}
                        >
                          <ButtonConfirm
                            confirmAction={() => handleRemoveAttachment(a)}
                            component={
                              <Fab
                                id={`attachment-pdf-delete-confirm-${index}`}
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="remove"
                                sx={{ ml: 1 }}
                              >
                                <DeleteIcon />
                              </Fab>
                            }
                          >
                            <>
                              Please confirm if you would like to delete the PDF
                              attachment. You must click &quot;Update&quot; to
                              save the deletion.
                            </>
                          </ButtonConfirm>
                        </Box>
                      </Box>
                    </Box>
                  ) : undefined}
                  {a.kind === 'pdf_embed' ||
                  a.AttachmentKind === 'pdf_embed' ? (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          width: 'fit-content',
                          minWidth: 300,
                          minHeight: 100,
                          pt: 7
                        }}
                      >
                        <TopicPdf attachment={a} />

                        <Box
                          sx={{ position: 'absolute', top: 0, right: 0, p: 1 }}
                        >
                          <label htmlFor={`upload-attachment-pdf-${index}`}>
                            <input
                              style={{ display: 'none' }}
                              id={`upload-attachment-pdf-${index}`}
                              name={`upload-attachment-pdf-${index}`}
                              type="file"
                              accept=".pdf"
                              onChange={(e) => handleAttachmentUpload(e, a)}
                            />
                            <Fab
                              color="secondary"
                              size="small"
                              component="span"
                              aria-label="add"
                            >
                              <FileUploadIcon />
                            </Fab>
                          </label>

                          <ButtonConfirm
                            confirmAction={() => handleRemoveAttachment(a)}
                            component={
                              <Fab
                                id={`attachment-pdf-delete-confirm-${index}`}
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="remove"
                                sx={{ ml: 1 }}
                              >
                                <DeleteIcon />
                              </Fab>
                            }
                          >
                            <>
                              Please confirm if you would like to delete the PDF
                              attachment. You must click &quot;Update&quot; to
                              save the deletion.
                            </>
                          </ButtonConfirm>
                        </Box>
                      </Box>
                    </Box>
                  ) : undefined}
                </Box>
              ))
            : undefined}

          {topic && topic.id ? (
            <Divider sx={{ my: 3 }}>
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                sx={{ mr: 2 }}
                onClick={() =>
                  handleNewAttachment(topic.attachments.length, 'text')
                }
              >
                <HtmlIcon />
              </Fab>

              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                sx={{ mr: 2 }}
                onClick={() =>
                  handleNewAttachment(topic.attachments.length, 'video')
                }
              >
                <VideoCallIcon />
              </Fab>

              <Fab
                color="primary"
                size="small"
                component="span"
                sx={{ mr: 2 }}
                aria-label="add"
                onClick={() =>
                  handleNewAttachment(topic.attachments.length, 'pdf_embed')
                }
              >
                <PictureAsPdfIcon />
              </Fab>

              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                onClick={() =>
                  handleNewAttachment(topic.attachments.length, 'xapi')
                }
              >
                <AccountBalanceIcon />
              </Fab>
            </Divider>
          ) : undefined}
        </Box>
        {topic ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'end',
              mt: 3
            }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              color="warning"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={submitButtonDisabled}
            >
              {topic.id ? 'Update' : 'Create'}
            </Button>
          </Box>
        ) : undefined}
      </Box>
    </>
  )
}

Topic.propTypes = {
  current: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  courseId: PropTypes.string,
  onClose: PropTypes.func,
  setNotification: PropTypes.func
}

export default Topic
