import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { grey } from '@mui/material/colors'
import PropTypes from 'prop-types'
import { Typography, Button, Box, Paper } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export default function CourseCardLong({ course }) {
  const [isComplete, setComplete] = useState(false)
  const mediaHeight = 150

  useEffect(() => {
    let mounted = true

    const percent =
      course && course.percentCompleted ? course.percentCompleted : 0

    if (mounted) setComplete(percent === 100)

    return () => {
      mounted = false
    }
  }, [course])

  return (
    <>
      {course ? (
        <Paper
          elevation={3}
          sx={{
            backgroundColor: isComplete ? '#e4deeb' : grey[100],
            display: 'flex',
            alignItems: 'center',
            boderRadius: 2
          }}
        >
          {course?.imageUrl !== undefined ? (
            <Box
              sx={{
                backgroundImage: `url(${course.imageUrl
                  .replace('_orig', '')
                  .replace('.jpeg', '.jpg')
                  .replace('.png', '.jpg')
                  .replace('.jpg', '_200x200.jpg')})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                height: mediaHeight,
                minWidth: mediaHeight
              }}
            ></Box>
          ) : undefined}
          <Box
            sx={{
              px: 3,
              py: 2,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '80px'
              }}
            >
              <Typography variant="h5">{course.name}</Typography>
              {isComplete ? <CheckCircleIcon color="darkPurple" /> : undefined}
            </Box>
            {isComplete ? (
              <Button
                component={Link}
                color="darkPurple"
                variant="outlined"
                size="small"
                to={`/courses/${course.slug}`}
                sx={{
                  textTransform: 'initial',
                  backgroundColor: '#fff',
                  width: 110,
                  boxShadow:
                    '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                }}
                aria-label="restart topic"
              >
                Restart
              </Button>
            ) : (
              <Button
                component={Link}
                color="black"
                variant="contained"
                size="small"
                to={`/courses/${course.slug}`}
                sx={{
                  textTransform: 'initial',
                  width: 110,
                  boxShadow:
                    '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                }}
                aria-label="restart topic"
              >
                Up Next
              </Button>
            )}
          </Box>
        </Paper>
      ) : undefined}
    </>
  )
}

CourseCardLong.propTypes = {
  course: PropTypes.object
}
