import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import DataGridWithFiltering from '../common/DataGridWithFiltering'
import {
  Container,
  Typography,
  Box,
  Alert,
  SwipeableDrawer,
  Button
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import { fetch, cancel as cancelRequest } from '../common/api'
import Course from './Course'

export default function Courses() {
  const [list, setList] = useState([])
  const [listCopy, setListCopy] = useState([])
  const [showAlert, setShowAlert] = useState(null)
  const [showItem, setShowItem] = useState(false)
  const [course, setCourse] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)
  const { setNotification } = useOutletContext()

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setShowItem(open)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Course',
      minWidth: 280,
      flex: 1,
      editable: false,
      renderCell: function renderCell(params) {
        return (
          <Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center'
              }}
            >
              {params.row.isPublished ? (
                <CloudDoneIcon sx={{ color: 'success.main' }}></CloudDoneIcon>
              ) : (
                <CloudOffIcon></CloudOffIcon>
              )}
              {params.row.isNew ? (
                <FiberNewIcon
                  sx={{ color: 'tertiaryCerulean.main', ml: 1 }}
                ></FiberNewIcon>
              ) : undefined}
              {params.row.isFeatured ? (
                <LabelImportantIcon
                  sx={{ color: 'purple.main', ml: 1 }}
                ></LabelImportantIcon>
              ) : undefined}
              <Typography sx={{ ml: 1 }}>{params.value}</Typography>
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      minWidth: 450,
      editable: false
    },
    {
      field: 'type',
      headerName: 'Course Type',
      width: 150,
      editable: false
    }
  ]

  useEffect(() => {
    let mounted = true
    let request

    async function fetchData() {
      setDataLoading(true)
      const related = `related=topics`
      try {
        request = fetch(`/courses?${related}`)
        const courses = await request

        if (mounted) {
          const onlineCourses = courses.filter(
            (c) => c.type === 'online' || c.type === 'xapi'
          )

          setList(onlineCourses)
          setListCopy(onlineCourses)
          setDataLoading(false)
        }
      } catch (e) {
        if (mounted) {
          setShowAlert(e.message)
          setDataLoading(false)
        }
        console.log(e)
      }
    }

    fetchData()

    return () => {
      mounted = false
      cancelRequest(request)
    }
  }, [])

  return (
    <>
      <Container component="main">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              textAlign: 'left',
              textTransform: 'uppercase',
              mb: 2,
              display: 'flex',
              alignContent: 'space-between'
            }}
          >
            Courses
          </Typography>
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              endIcon={<AddIcon />}
              onClick={() => {
                setCourse({
                  isFeatured: false,
                  isNew: false,
                  isPublished: false,
                  id: null,
                  type: 'xapi',
                  imageUrl: '',
                  teaserVideoUrl: '',
                  name: '',
                  description: '',
                  accredibleGroupId: ''
                })
                setShowItem(true)
              }}
            >
              Add Course
            </Button>
          </Box>
        </Box>

        {showAlert ? (
          <Alert variant="outlined" severity="error" sx={{ my: 2 }}>
            {showAlert}
          </Alert>
        ) : undefined}
        <DataGridWithFiltering
          height="600px"
          rows={list}
          rowsCopy={listCopy}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          setRows={setList}
          onRowClick={({ row }) => {
            setCourse({
              ...{
                isFeatured: false,
                isNew: false
              },
              ...row
            })
            setShowItem(true)
          }}
          onCellClick={(_, event) => {
            event.defaultMuiPrevented = true
          }}
          getRowId={(row) => row.id}
          loading={dataLoading}
        />
      </Container>
      <SwipeableDrawer
        anchor="right"
        open={showItem}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{ p: 4 }}>
          {course ? (
            <Course
              current={course}
              onClose={toggleDrawer(false)}
              onSuccess={(item) => {
                const index = list.findIndex((i) => i.id === item.id)
                const newList = [...list]

                if (index === -1) newList.push(item)
                else newList.splice(index, 1, item)

                setList(newList)
                setNotification({
                  open: true,
                  message: 'Course has been saved',
                  severity: 'success'
                })
              }}
            />
          ) : undefined}
        </Box>
      </SwipeableDrawer>
    </>
  )
}
