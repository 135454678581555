import { useState, useEffect } from 'react'
import routes from './routes.js'
import { Outlet, useLocation, Link as ReactLink } from 'react-router-dom'
import { Snackbar, Alert, Breadcrumbs, Link, Container } from '@mui/material'

function AdminHeader() {
  const location = useLocation()
  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right'
  }
  const [currentRoute, setCurrentRoute] = useState([{ path: '' }])
  const [notification, setNotification] = useState({
    open: false,
    message: null,
    severity: 'success'
  })

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setNotification({
      ...notification,
      ...{
        open: false
      }
    })
  }

  useEffect(() => {
    let mounted = true
    const paths = window.location.pathname.substring(7).split('/')
    const found = routes[0].children.find(({ path }) =>
      paths.find((p) => p === path)
    )

    if (mounted) setCurrentRoute([found ? found : { path: '' }])

    return () => {
      mounted = false
    }
  }, [location])

  return (
    <>
      <Snackbar
        open={notification.open}
        anchorOrigin={anchorOrigin}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
      <Container>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Link
            component={ReactLink}
            underline="hover"
            color="inherit"
            to="/admin"
            key="admin"
          >
            Dashboard
          </Link>

          {[...currentRoute]
            .filter((r) => r.path !== '')
            .map((r, key) => {
              return (
                <Link
                  component={ReactLink}
                  underline="hover"
                  color="inherit"
                  to={r.path}
                  key={key}
                >
                  {r.name}
                </Link>
              )
            })}
        </Breadcrumbs>
      </Container>
      <Outlet context={{ notification, setNotification }} />
    </>
  )
}

export default AdminHeader
