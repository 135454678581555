import { useEffect } from 'react'
import { Container, Typography, Box, Grid, Button } from '@mui/material'
import HubspotForm from 'react-hubspot-form'
import HeroBanner from '../course/HeroBanner'
import CalendlyEmbed from '../common/CalendlyEmbed'
import ButtonModal from '../common/ButtonModal'

export default function Contact() {
  const enableCalendlyContact = false
  const headerHeight = {
    xs: '100px',
    sm: '200px',
    md: '200px',
    lg: '200px',
    xl: '200px'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <>
      <HeroBanner height={headerHeight} containerWidth="lg">
        <Typography variant="h1" color="white">
          Contact Us
        </Typography>
        <></>
      </HeroBanner>

      <Container
        component="main"
        sx={{ mt: 5, mb: { xs: 17, md: 18, lg: 25 } }}
      >
        <Grid
          container
          rowSpacing={0}
          columnSpacing={{ xs: 8 }}
          sx={{ height: '100%' }}
        >
          <Grid item md={10} lg={10}>
            {enableCalendlyContact ? (
              <>
                <Typography variant="h3" sx={{ mb: 1 }}>
                  Request a private Instructor-led Virtual Training for your
                  company?
                </Typography>
                <Typography paragraph>
                  Reserve your time now and meet with a representative at
                  Kidaware and get your virtual training rolling!
                </Typography>
                <Box>
                  <ButtonModal
                    size="md"
                    component={
                      <Button
                        id="request-meeting"
                        color="secondaryDark"
                        variant="outlined"
                        sx={{ width: { xs: '100%', sm: 'initial' } }}
                      >
                        Request Now
                      </Button>
                    }
                  >
                    <>
                      <Typography variant="h2" sx={{ textAlign: 'center' }}>
                        Would you like to schedule a meeting
                      </Typography>
                      <Typography paragraph sx={{ textAlign: 'center', mt: 2 }}>
                        Select an available time and day to meet with one of our
                        specialists
                      </Typography>
                      <CalendlyEmbed url="https://calendly.com/jonwarmington/15min?hide_event_type_details=1&hide_gdpr_banner=1"></CalendlyEmbed>
                    </>
                  </ButtonModal>
                </Box>
              </>
            ) : undefined}

            <Typography variant="h4" sx={{ mb: 3, mt: 2 }}>
              Have questions for us or want to request an instructor-led
              training for your team? Reach out to us
            </Typography>
            <HubspotForm
              portalId="5009836"
              formId="d9dc8b0e-343d-4a7a-a803-fac3374b6ea3"
              onSubmit={() => console.log('Submit!')}
              onReady={() => console.log('Form ready!')}
              loading={<div>Loading...</div>}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
