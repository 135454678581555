import PropTypes from 'prop-types'
import { BlockPicker } from 'react-color'
import ColorLensIcon from '@mui/icons-material/ColorLens'
import themeConfig from '../mui-theme'

export default function TopicEditorColorPicker({
  expanded,
  onExpandEvent,
  currentState,
  onChange
}) {
  const stopPropagation = (event) => {
    event.stopPropagation()
  }

  const renderModal = () => {
    const colors = [
      themeConfig.palette.primary.main,
      themeConfig.palette.secondary.main,
      themeConfig.palette.purple.main,
      themeConfig.palette.darkPurple.main,
      themeConfig.palette.concrete.main,
      themeConfig.palette.alto.main,
      themeConfig.palette.coolGray.main,
      themeConfig.palette.secondaryMalibu.main,
      themeConfig.palette.secondaryChathamsBlue.main,
      themeConfig.palette.secondaryLavenderPurple.main,
      themeConfig.palette.secondaryHaiti.main,
      themeConfig.palette.secondaryHopbush.main,
      themeConfig.palette.secondaryBlackberry.main,
      themeConfig.palette.tertiaryCerulean.main,
      themeConfig.palette.tertiaryTurquoiseBlue.main,
      themeConfig.palette.tertiaryRegalBlue.main,
      themeConfig.palette.tertiaryPurpleHeart.main,
      themeConfig.palette.tertiaryLavender.main,
      themeConfig.palette.tertiaryPersianIndigo.main,
      '#fff',
      '#000'
    ]

    const { color } = currentState
    return (
      <div
        onClick={stopPropagation}
        style={{
          position: 'absolute',
          right: '-70px',
          top: '135%',
          zIndex: 10
        }}
      >
        <BlockPicker
          color={color}
          colors={colors}
          onChangeComplete={(color) => onChange('color', color.hex)}
        />
      </div>
    )
  }

  return (
    <div
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-color-picker"
      style={{ position: 'relative' }}
    >
      <div className="rdw-option-wrapper" title="Color" onClick={onExpandEvent}>
        <ColorLensIcon />
      </div>
      {expanded ? renderModal() : undefined}
    </div>
  )
}

TopicEditorColorPicker.propTypes = {
  expanded: PropTypes.bool,
  onExpandEvent: PropTypes.func,
  onChange: PropTypes.func,
  currentState: PropTypes.object
}
