import { useEffect, useState, useLayoutEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import ButtonModal from '../common/ButtonModal'
import LoginForm from '../account/LoginForm'
import { useAuth } from '../account/auth'
import logoBackgroundSvg from './logo-background.svg'
import logo from './kidAware-logo-long-white.png'
import logoBlack from './kidAware-logo-long-black.png'
import {
  Button,
  Tooltip,
  Box,
  IconButton,
  Container,
  Tab,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  Typography,
  Alert,
  TextField,
  Grid,
  Drawer,
  ListItem,
  ListItemButton
} from '@mui/material'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'

import './Nav.scss'

const pages = ['Resources', 'About Us']
const formatPagePath = (path) => path.toLowerCase().replace(' ', '-')
function Nav({ authed, user, isAdmin, isAdminRoute }) {
  const [anchorElUser, setAnchorElUser] = useState(false)
  const [anchorElCourses, setAnchorElCourses] = useState(false)
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [currentPage, setCurrentPage] = useState('')
  const [showConfirmNotice, setShowConfirmNotice] = useState(false)
  const [isWhiteListed, setIsWhiteListed] = useState(false)
  const [showApprovalRequired, setShowApprovalRequired] = useState(false)
  const [sentEmailVerification, setSentEmailVerification] = useState(false)
  const [navProperties, setNavProperties] = useState({
    height: 87,
    logoBackgroundHeight: 119,
    logoTop: 35,
    navTopMargin: '20px',
    navAuthTopMargin: '25px',
    navButtonTopMargin: '24px',
    mobileNavButtonTopMargin: '18px',
    mobileLogoWidth: 160,
    mobileLogoHeight: 40,
    mobileLoginTopMargin: '17px',
    mobileLoginTopHeight: 72
  })
  const [code, setCode] = useState(null)
  const [allowCodeSubmit, setAllowCodeSubmit] = useState(false)

  const open = Boolean(anchorElUser)
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const handleCode = (e) => {
    setCode(e.target.value)
    setAllowCodeSubmit(e.target.value.length > 3 ? true : false)
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleOpenCoursesMenu = (event) => {
    setCurrentPage('courses')
    event.preventDefault()
    setAnchorElCourses(event.currentTarget)
  }

  const handleCloseCoursesMenu = () => {
    setAnchorElCourses(null)
    setCurrentPage('')
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const gotoHome = (event) => {
    event.preventDefault()
    navigate('/')
  }

  const resendConfirmationCode = async (e) => {
    e.preventDefault()
    try {
      setAllowCodeSubmit(false)
      const currentUser = await auth.getCurrentAuthenticatedUser()
      await auth.verifyUsersEmail(currentUser)
      setSentEmailVerification('A new confirmation email was sent successfully')
    } catch (err) {
      setSentEmailVerification(`Error Sending email: ${err.message}`)
      console.error(err)
    }
  }

  const handleConfirmationCodeSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      await auth.confirmUserEmailCode(code)
      auth.reCheckAuth()
      setSentEmailVerification(false)
      navigate('/')
    } catch (err) {
      setSentEmailVerification('Error Sending email: ', err)
      console.error(err)
    }
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setSentEmailVerification(
        'Please check your email for the verification code.'
      )
      setShowConfirmNotice(user && !user.email_verified)

      if (
        user &&
        user.email_verified &&
        user?.is_approved != null &&
        user?.is_whitelisted != null
      )
        setShowApprovalRequired(!user?.is_approved && !user?.is_whitelisted)
      else setShowApprovalRequired(false)

      setIsWhiteListed(user?.is_whitelisted)
    }
    return () => {
      mounted = false
    }
  }, [user])

  useEffect(() => {
    let mounted = true
    const path =
      location.pathname.substring(1) ===
      'courses/virtual-instructor-led-training-registration'
        ? 'courses'
        : location.pathname.substring(1)

    if (mounted) setCurrentPage(path)

    return () => {
      mounted = false
    }
  }, [location.pathname])

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100)
        setNavProperties({
          ...{
            height: 60,
            logoBackgroundHeight: 80,
            logoTop: 20,
            navTopMargin: '6px',
            navAuthTopMargin: '11px',
            navButtonTopMargin: '13px',
            mobileNavButtonTopMargin: '4px',
            mobileLogoWidth: 122,
            mobileLogoHeight: 31,
            mobileLoginTopMargin: '5px',
            mobileLoginTopHeight: 30
          }
        })
      if (window.scrollY <= 100)
        setNavProperties({
          ...{
            height: 87,
            logoBackgroundHeight: 119,
            logoTop: 35,
            navTopMargin: '20px',
            navAuthTopMargin: '25px',
            navButtonTopMargin: '25px',
            mobileNavButtonTopMargin: '18px',
            mobileLogoWidth: 135,
            mobileLogoHeight: 34,
            mobileLoginTopMargin: '17px',
            mobileLoginTopHeight: 55
          }
        })
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Box
      sx={{
        height: isAdminRoute ? '64px' : navProperties.height
      }}
    >
      <AppBar
        sx={{
          backgroundColor: 'white',
          height: isAdminRoute ? '64px' : navProperties.height,
          opacity: '0.95',
          boxShadow: '0px 1px 5px rgba(0,0,0,0.1), 0px -1px 5px rgba(0,0,0,0.2)'
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                textAlign: 'center',
                position: 'relative',
                visibility: isAdminRoute ? 'hidden' : 'visible',
                opacity: isAdminRoute ? 0 : 1,
                height: isAdminRoute ? '0' : '100%'
              }}
              className={'nav-logo'}
            >
              <img
                src={logoBackgroundSvg}
                style={{
                  position: 'absoulte',
                  left: '120px',
                  width: '212px',
                  opacity: '0.95',
                  borderRadius: '0 0 10px 10px',
                  boxShadow: '0px 4px 5px rgba(0,0,0,0.21)',
                  height: isAdminRoute
                    ? '0'
                    : navProperties.logoBackgroundHeight
                }}
                alt="logo background"
              />
              <a href="/" onClick={gotoHome}>
                <img
                  src={logo}
                  style={{
                    position: 'absolute',
                    left: '24px',
                    top: navProperties.logoTop,
                    width: '163px',
                    opacity: 0.95,
                    height: isAdminRoute ? '0' : '41px'
                  }}
                  alt="logo"
                />
              </a>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                mt: navProperties.mobileNavButtonTopMargin
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: 'black' }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <ListItem
                  onClick={handleCloseNavMenu}
                  sx={{ minWidth: '300px' }}
                >
                  <ListItemButton>
                    <a href="/" onClick={gotoHome}>
                      <img
                        src={logoBlack}
                        className={'nav-logo-mobile'}
                        style={{
                          width: navProperties.mobileLogoWidth,
                          opacity: 0.95,
                          height: isAdminRoute
                            ? '0'
                            : navProperties.mobileLogoHeight
                        }}
                        alt="kidaware-logo-black"
                      />
                    </a>
                  </ListItemButton>
                </ListItem>
                {isAdmin ? (
                  <ListItem onClick={handleCloseNavMenu}>
                    <ListItemButton
                      component={Link}
                      to={`/admin/courses`}
                      onClick={handleCloseNavMenu}
                      className={` ${
                        currentPage === 'courses' ? 'selected' : ''
                      }`}
                    >
                      <ListItemText
                        primary="Admin"
                        sx={{ textTransform: 'uppercase' }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : undefined}
                <ListItem onClick={handleCloseNavMenu}>
                  <ListItemButton
                    component={Link}
                    to={`/courses`}
                    onClick={handleCloseNavMenu}
                    className={` ${
                      currentPage === 'courses' ? 'selected' : ''
                    }`}
                  >
                    <ListItemText
                      primary="E-learning"
                      sx={{ textTransform: 'uppercase' }}
                    />
                  </ListItemButton>
                </ListItem>
                {authed && isWhiteListed ? (
                  <ListItem onClick={handleCloseNavMenu}>
                    <ListItemButton
                      component={Link}
                      to={`/courses/virtual-instructor-led-training-registration`}
                      onClick={handleCloseNavMenu}
                      className={` ${currentPage === '' ? 'selected' : ''}`}
                    >
                      <ListItemText
                        primary="Virtual instructor-led training"
                        sx={{ textTransform: 'uppercase' }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : null}
                {pages.map((page) => {
                  const formatedPage = formatPagePath(page)
                  return (
                    <ListItem key={formatedPage} onClick={handleCloseNavMenu}>
                      <ListItemButton
                        component={Link}
                        to={`/${formatedPage}`}
                        onClick={handleCloseNavMenu}
                        className={` ${
                          currentPage === formatedPage ? 'selected' : ''
                        }`}
                      >
                        <ListItemText
                          primary={page}
                          sx={{ textTransform: 'uppercase' }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
                <ListItem onClick={handleCloseNavMenu}>
                  <ListItemButton
                    component={Link}
                    to={`/contact-us`}
                    onClick={handleCloseNavMenu}
                    className={` ${currentPage === '' ? 'selected' : ''}`}
                  >
                    <ListItemText
                      sx={{ textTransform: 'uppercase' }}
                      primary="Contact us"
                    />
                  </ListItemButton>
                </ListItem>
                {!authed ? (
                  <>
                    <ListItem>
                      <ButtonModal
                        component={
                          <Tab
                            id="login-button-from-mobile"
                            component="a"
                            icon={<AccountCircleIcon />}
                            label="Log in / Sign Up"
                            iconPosition="start"
                            sx={{
                              color: 'primary.main',
                              minHeight: '48px',
                              pt: '11px'
                            }}
                          />
                        }
                        size="lg"
                      >
                        <LoginForm />
                      </ButtonModal>
                    </ListItem>
                  </>
                ) : (
                  <>
                    <ListItem onClick={handleCloseNavMenu}>
                      <ListItemButton
                        component={Link}
                        to={`/account`}
                        onClick={handleCloseNavMenu}
                        className={` ${
                          currentPage === 'courses' ? 'selected' : ''
                        }`}
                      >
                        <ListItemText
                          primary="My Account"
                          sx={{ textTransform: 'uppercase' }}
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      onClick={() => {
                        handleCloseUserMenu()
                        auth.logout()
                        navigate('/')
                      }}
                    >
                      <Tab
                        id="login-button-from-mobile"
                        component="a"
                        icon={<LogoutIcon />}
                        label="Log out"
                        iconPosition="start"
                        sx={{
                          color: 'primary.main',
                          minHeight: '48px',
                          pt: '6px'
                        }}
                      />
                    </ListItem>
                  </>
                )}
              </Drawer>
            </Box>
            <Box
              sx={{
                justifyContent: 'center',
                flexGrow: 14,
                display: { xs: 'flex', md: 'none' },
                mt: navProperties.mobileNavButtonTopMargin
              }}
            >
              <a href="/" onClick={gotoHome}>
                <img
                  src={logoBlack}
                  className={'nav-logo-mobile'}
                  style={{
                    width: navProperties.mobileLogoWidth,
                    opacity: 0.95,
                    height: isAdminRoute ? '0' : navProperties.mobileLogoHeight,
                    verticalAlign: 'middle'
                  }}
                  alt="kidaware-logo-black"
                />
              </a>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: 'right',
                display: { xs: 'none', md: 'flex' },
                alignSelf: 'flex-start',
                mt: isAdminRoute ? '12px' : navProperties.navTopMargin,
                mr: 2,
                class: ' sdfs'
              }}
              className="menu-nav"
            >
              {authed && isWhiteListed ? (
                <>
                  <Link
                    onClick={handleOpenCoursesMenu}
                    className={`nav-link ${
                      currentPage === 'courses' ? 'selected' : ''
                    }`}
                    to=""
                    aria-controls={open ? 'courses' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    Courses
                  </Link>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElCourses}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={Boolean(anchorElCourses)}
                    onClose={handleCloseCoursesMenu}
                  >
                    <MenuItem
                      onClick={() => {
                        handleCloseCoursesMenu()
                      }}
                      component={Link}
                      to="/courses"
                    >
                      <ListItemText>E-Learning</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleCloseCoursesMenu()
                      }}
                      component={Link}
                      to="/courses/virtual-instructor-led-training-registration"
                    >
                      <ListItemText>
                        Virtual instructor-led training
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Link
                  to={`/courses`}
                  key="courses"
                  onClick={() => {
                    handleCloseNavMenu()
                  }}
                  className={`nav-link ${
                    currentPage === 'courses' ? 'selected' : ''
                  }`}
                >
                  Courses
                </Link>
              )}

              {pages.map((page) => {
                const formatedPage = formatPagePath(page)
                return (
                  <Link
                    to={`/${formatedPage}`}
                    key={formatedPage}
                    onClick={() => {
                      handleCloseNavMenu()
                    }}
                    className={`nav-link ${
                      currentPage === formatedPage ? 'selected' : ''
                    }`}
                  >
                    {page}
                  </Link>
                )
              })}
              {!authed ? (
                <Link
                  to="/contact-us"
                  key="contact-us"
                  color="black"
                  sx={{
                    mr: 3
                  }}
                  onClick={() => {
                    handleCloseNavMenu()
                  }}
                  className={`nav-link ${
                    currentPage === 'contact-us' ? 'selected' : ''
                  }`}
                >
                  Contact Us
                </Link>
              ) : undefined}
              {!authed ? (
                <div>
                  <ButtonModal
                    component={
                      <Tab
                        id="login-button"
                        component="a"
                        icon={<AccountCircleIcon />}
                        label="Log in / Sign Up"
                        iconPosition="start"
                        sx={{
                          color: 'primary.main',
                          minHeight: '48px',
                          pt: '11px'
                        }}
                      />
                    }
                    size="lg"
                  >
                    <LoginForm />
                  </ButtonModal>
                </div>
              ) : undefined}
            </Box>
            {authed && user ? (
              <Box
                className="transition-ease-in-out"
                sx={{
                  flexGrow: 0,
                  alignSelf: 'flex-start',
                  mt: isAdminRoute ? '15px' : navProperties.navAuthTopMargin,
                  display: { xs: 'none', sm: 'flex' }
                }}
              >
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <AccountCircleIcon sx={{ mr: 1 }} />
                    <Typography sx={{ mr: '6px', fontSize: '.9rem' }}>
                      {user.given_name}
                    </Typography>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {isAdmin ? (
                    <MenuItem
                      onClick={handleCloseUserMenu}
                      component={Link}
                      to="/admin/courses"
                    >
                      <ListItemIcon>
                        <ManageAccountsIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Admin</ListItemText>
                    </MenuItem>
                  ) : undefined}

                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu()
                    }}
                    component={Link}
                    to="/account"
                  >
                    <ListItemIcon>
                      <ManageAccountsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {user.given_name}&apos;s Account
                    </ListItemText>
                  </MenuItem>
                  <Divider orientation="horizontal"></Divider>
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu()
                      auth.logout()
                      navigate('/')
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Log Out</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            ) : undefined}
            {authed ? (
              <Box
                className="transition-ease-in-out"
                sx={{
                  flexGrow: 0,
                  alignSelf: 'flex-start',
                  mt: isAdminRoute
                    ? '17px'
                    : {
                        xs: navProperties.mobileNavButtonTopMargin,
                        sm: navProperties.navButtonTopMargin
                      }
                }}
              >
                <Button
                  id="contact-us-button"
                  variant="outlined"
                  color="black"
                  size="small"
                  sx={{
                    marginTop: '0',
                    ml: 2,
                    display: { xs: 'none', sm: 'flex' }
                  }}
                  component={Link}
                  to="/contact-us"
                >
                  Contact Us
                </Button>
                <Box
                  sx={{ minWidth: 48, display: { xs: 'flex', sm: 'none' } }}
                ></Box>
              </Box>
            ) : (
              <Box
                sx={{
                  mt: navProperties.mobileLoginTopMargin,
                  display: { xs: 'flex', md: 'none' }
                }}
              >
                <ButtonModal
                  component={
                    <Tab
                      id="login-button-top-right"
                      component="a"
                      icon={<AccountCircleIcon />}
                      label="Log in"
                      iconPosition="start"
                      sx={{
                        color: 'black.main',
                        p: 0,
                        minHeight: navProperties.mobileLoginTopHeight
                      }}
                    />
                  }
                  size="lg"
                >
                  <LoginForm />
                </ButtonModal>
              </Box>
            )}
          </Toolbar>
        </Container>
        {showApprovalRequired && !showConfirmNotice ? (
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 999999,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Alert
              severity="warning"
              sx={{ maxWidth: '500px' }}
              id="confirm-email-alert"
            >
              Thank you for verifying your email. Before you can start your
              training, a KidAware administrator will need to approve your
              account. You will be notified if your account is approved.
            </Alert>
          </Box>
        ) : null}

        {showConfirmNotice ? (
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 999999,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Alert
              severity="warning"
              sx={{ maxWidth: '500px' }}
              id="confirm-email-alert"
            >
              Please confirm your email to fully activate your account. You can
              do this by clicking the link in the email confirmation we sent
              you.
              {sentEmailVerification ? (
                <Box
                  sx={{ color: 'primary.main' }}
                  component="form"
                  onSubmit={handleConfirmationCodeSubmit}
                >
                  <Box sx={{ mt: 2 }}>{sentEmailVerification}</Box>
                  <Box>
                    <a href="/" onClick={resendConfirmationCode}>
                      Resend email now.
                    </a>
                  </Box>
                  <Grid
                    container
                    rowSpacing={{ xs: 0, lg: 0 }}
                    columnSpacing={{ xs: 2 }}
                  >
                    <Grid item xs={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        size="small"
                        id="code"
                        label="Enter code"
                        name="code"
                        autoComplete="code"
                        onChange={handleCode}
                      />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 2, p: 1 }}
                        disabled={!allowCodeSubmit}
                      >
                        Submit code
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box>
                  <a href="/" onClick={resendConfirmationCode}>
                    Resend email now.
                  </a>
                </Box>
              )}
            </Alert>
          </Box>
        ) : undefined}
      </AppBar>
    </Box>
  )
}

Nav.propTypes = {
  authed: PropTypes.bool,
  user: PropTypes.object,
  isAdmin: PropTypes.bool,
  isAdminRoute: PropTypes.bool
}

export default Nav
