import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material'
import GroupsIcon from '@mui/icons-material/Groups'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import SchoolIcon from '@mui/icons-material/School'
import ForkRightIcon from '@mui/icons-material/ForkRight'
import WebIcon from '@mui/icons-material/Web'
import logo from './kidAware-logo-long-red.png'
import GppGoodIcon from '@mui/icons-material/GppGood'

function AdminNav({ open, isSuperAdmin }) {
  const drawerWidth = '220px'

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
    >
      <Toolbar>
        <img
          src={logo}
          style={{
            width: '163px',
            height: '41px'
          }}
          alt="logo"
        />
      </Toolbar>
      <Divider />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <ListItem button key="Accounts" component={Link} to="/admin/accounts">
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
          </ListItem>
          <ListItem button key="Pages" component={Link} to="/admin/pages">
            <ListItemIcon>
              <WebIcon />
            </ListItemIcon>
            <ListItemText primary="Pages" />
          </ListItem>
          <ListItem button key="Courses" component={Link} to="/admin/courses">
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Courses" />
          </ListItem>
          <ListItem
            button
            key="VLIT"
            component={Link}
            to="/admin/virtual-instructor-led-courses"
          >
            <ListItemIcon>
              <VideoCameraFrontIcon />
            </ListItemIcon>
            <ListItemText primary="Virtual Instructor Led" />
          </ListItem>
          <ListItem
            button
            key="LearningPaths"
            component={Link}
            to="/admin/learning-paths"
          >
            <ListItemIcon>
              <ForkRightIcon />
            </ListItemIcon>
            <ListItemText primary="Learning Paths" />
          </ListItem>
          <ListItem
            button
            key="Resources"
            component={Link}
            to="/admin/resources"
          >
            <ListItemIcon>
              <HomeRepairServiceIcon />
            </ListItemIcon>
            <ListItemText primary="Resources" />
          </ListItem>
          <ListItem
            button
            key="WhiteListing"
            component={Link}
            to="/admin/whitelisting"
          >
            <ListItemIcon>
              <GppGoodIcon />
            </ListItemIcon>
            <ListItemText primary="Whitelisting" />
          </ListItem>
        </List>
        {isSuperAdmin ? (
          <>
            <Divider />
            <List>
              <ListItem
                button
                key="KillSwitch"
                component={Link}
                to="/admin/kill"
              >
                <ListItemIcon>
                  <ToggleOffIcon />
                </ListItemIcon>
                <ListItemText primary="Kill Switch" />
              </ListItem>
            </List>
          </>
        ) : undefined}
      </Box>
    </Drawer>
  )
}

AdminNav.propTypes = {
  open: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

export default AdminNav
