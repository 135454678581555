import { useState } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useAuth } from './auth'
import {
  Dialog,
  TextField,
  Box,
  Typography,
  Alert,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import PasswordRequirements from './PasswordRequirements'

function ConfirmPasswordForm({ onConfirm, user }) {
  const [showAlert, setShowAlert] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [confirmInProgress, setConfirmInProgress] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const auth = useAuth()

  const handleDialogClose = () => {
    setDialogOpen(false)
    if (onConfirm && typeof onConfirm === 'function') onConfirm()
  }

  const handleConfirmUserRequest = async (e) => {
    setShowAlert(false)
    setConfirmInProgress(true)
    e.preventDefault()
    e.stopPropagation()

    const data = new FormData(e.currentTarget)
    const email = user.challengeParam.userAttributes.email
    const newPassword = data.get('new-password').trim()
    const confirmPassword = data.get('confirm-password').trim()
    const company = data.get('company').trim()
    const newsletter = data.get('newsletter_consent') ?? 'false'
    const communicate_consent = data.get('communicate_consent') ?? 'false'

    if (newPassword !== confirmPassword) {
      setShowAlert(
        'Sorry, your confirm password does not match your new password. Please verify and try again.'
      )

      setConfirmInProgress(false)
      return false
    }

    try {
      await Auth.completeNewPassword(user, newPassword, {
        'custom:company_name': company,
        'custom:newsletter_consent': newsletter,
        'custom:communicate_consent': communicate_consent
      })

      setDialogOpen(true)
      const newToken = await executeRecaptcha('confirmUserLogin')
      await auth.login(email, newPassword, newToken)
      setConfirmInProgress(false)
    } catch (e) {
      setConfirmInProgress(false)
      setShowAlert(e.message)

      if (e.code === 'NotAuthorizedException')
        onConfirm('Session expired, please try again')

      console.error(e.code)
    }
  }

  return (
    <>
      <Dialog
        onClose={handleDialogClose}
        open={dialogOpen}
        aria-labelledby="reset-password-confirmation-title"
        aria-describedby="reset-password-confirmation-description"
      >
        <DialogContent>
          <DialogTitle id="reset-password-confirmation-title">
            You have successfully confirmed your account! You may continue your
            training experience.
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            onClick={handleDialogClose}
            sx={{ mx: 3, mb: 3 }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Typography
          component="h1"
          variant="h5"
          sx={{ textAlign: 'center', textTransform: 'uppercase' }}
        >
          Confirm your account
        </Typography>
        {showAlert ? (
          <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
            {showAlert}
          </Alert>
        ) : undefined}
      </Box>
      <Box component="form" onSubmit={handleConfirmUserRequest}>
        <TextField
          margin="normal"
          fullWidth
          readOnly
          name="tempPass"
          sx={{ backgroundColor: '#f3f3f3' }}
          label=""
          type="text"
          value={user?.challengeParam?.userAttributes?.email}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="new-password"
          label="Enter your new password"
          type="password"
          id="new-password"
          autoComplete="new-password"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirm-password"
          label="Confirm password"
          type="password"
          id="confirm-password"
          autoComplete="confirm-password"
        />
        <PasswordRequirements />
        <TextField
          margin="normal"
          fullWidth
          id="company"
          label="Company"
          name="company"
          autoComplete="company"
        />
        <FormControlLabel
          componentsProps={{ typography: { variant: 'subtitle2' } }}
          control={<Checkbox name="communicate_consent" value="true" />}
          label="I consent to receive email communication about other SuperAwesome products and services. (optional)"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={confirmInProgress}
          sx={{ mt: 3, mb: 2, p: 2 }}
        >
          {confirmInProgress ? (
            <span>Processing...</span>
          ) : (
            <span>Confirm my account</span>
          )}
        </Button>
      </Box>
    </>
  )
}

ConfirmPasswordForm.propTypes = {
  user: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export default ConfirmPasswordForm
