import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Alert, Typography, IconButton } from '@mui/material'
import DataGridWithFiltering from '../common/DataGridWithFiltering'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import ButtonConfirm from '../common/ButtonConfirm'
import { resetTopicFailedAttemps } from '../common/interactions'
import { useAuth } from '../account/auth'
import CloseIcon from '@mui/icons-material/Close'
import ReplayIcon from '@mui/icons-material/Replay'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'

export default function Enrollment({ current, subId, onClose }) {
  const [showAlert, setShowAlert] = useState(null)
  const [list, setList] = useState([])
  const [listCopy, setListCopy] = useState([])
  const auth = useAuth()

  const filterList = (list) => {
    setList(list)
  }

  const handleResetFailedAttempts = async (t) => {
    setShowAlert(false)
    if (!auth.isAdmin) return

    try {
      console.log(subId)
      await resetTopicFailedAttemps(subId, current.id, t.id)

      const newList = list.map((t) => {
        return { ...t, ...{ topicFailedAttemps: 0 } }
      })
      setList(newList)

      setShowAlert({ severity: 'success', message: 'Failures reset' })
    } catch (e) {
      setShowAlert({ severity: 'error', message: e.message })
    }
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 450,
      flex: 1,
      editable: false,
      renderCell: function renderCell(params) {
        return (
          <Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center'
              }}
            >
              {params.row.isCompleted ? (
                <CheckCircleIcon
                  sx={{ color: 'trinary.main', ml: 1 }}
                ></CheckCircleIcon>
              ) : (
                <CircleOutlinedIcon
                  color="disabled"
                  sx={{ ml: 1 }}
                ></CircleOutlinedIcon>
              )}
              <Typography sx={{ ml: 1 }}>{params.value}</Typography>
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'failedTopicAttempts',
      headerName: 'Quiz status',
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: function renderCell(params) {
        return (
          <Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center'
              }}
            >
              {params.row.topicScore > 0.6 ? (
                <Typography sx={{ ml: 1 }}>
                  Passed ( {(params.row.topicScore ?? 0) * 100}% )
                </Typography>
              ) : params.row.failedTopicAttempts > 0 ? (
                <Typography sx={{ ml: 1 }}>
                  Failed ( {(params.row.topicScore ?? 0) * 100}% ) (
                  {params.row.failedTopicAttempts ?? 0} / 3)
                </Typography>
              ) : params.row.failedTopicAttempts === 0 ? (
                <Typography sx={{ ml: 1 }}>
                  Failed Attemps ({params.row.failedTopicAttempts ?? 0} / 3)
                </Typography>
              ) : undefined}
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 100,
      flex: 0,
      getActions: (params) => [
        <>
          {params.row.failedTopicAttempts >= 1 ? (
            <ButtonConfirm
              key={`edit-${params.row.id}`}
              confirmAction={() => handleResetFailedAttempts(params.row)}
              component={
                <GridActionsCellItem
                  id={`enrollment-reset-failed-attemts-confirm-${params.row.id}`}
                  icon={<ReplayIcon />}
                  sx={{ color: 'secondary.main' }}
                  label="Reset failed attemps"
                />
              }
            >
              <>Are you sure you want to reset the failed attemps?</>
            </ButtonConfirm>
          ) : undefined}
        </>
      ]
    }
  ]

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setList(current.topics)
      setListCopy(current.topics)
    }

    return () => {
      mounted = false
    }
  }, [current.topics])

  return (
    <>
      {showAlert ? (
        <Alert variant="outlined" severity={showAlert.severity} sx={{ my: 2 }}>
          {showAlert.message}
        </Alert>
      ) : undefined}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          component="h1"
          variant="h4"
          sx={{ textAlign: 'left', textTransform: 'uppercase', mb: 2 }}
        >
          {current ? `${current?.name}` : ''}
        </Typography>
        <Box>
          <IconButton
            aria-label="close"
            color="primary"
            onClick={onClose}
            sx={{ p: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Typography component="p" sx={{ mb: 2 }}>
          {current ? `${current?.description}` : ''}
        </Typography>
        <DataGridWithFiltering
          height="700px"
          rows={list}
          columns={columns}
          pageSize={10}
          rowsCopy={listCopy}
          setRows={filterList}
          rowsPerPageOptions={[5]}
          onCellClick={(_, event) => {
            event.defaultMuiPrevented = true
          }}
          getRowId={(row) => row.id}
        />
      </Box>
    </>
  )
}

Enrollment.propTypes = {
  current: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  subId: PropTypes.string
}
