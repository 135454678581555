import PropTypes from 'prop-types'
import { useQueryClient } from '@tanstack/react-query'
import { fetch } from '../common/api'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Button } from '@mui/material'

export default function NextTopicButton({
  course,
  topicId,
  children,
  variant,
  color,
  sx,
  id,
  size,
  fullWidth,
  onClick,
  disabled
}) {
  const queryClient = useQueryClient()
  const [nextTopicId, setNextTopicId] = useState(null)

  const topicQuery = (courseId, topicId) => ({
    queryKey: ['topic', courseId, topicId],
    queryFn: async () => {
      return await fetch(
        `/courses/${courseId}/topics/${topicId}?related=attachments`
      )
    },
    enabled: !!nextTopicId
  })

  if (nextTopicId)
    queryClient.getQueryData(['topic', course.slug, nextTopicId]) ??
      queryClient.prefetchQuery(topicQuery(course.slug, nextTopicId))

  useEffect(() => {
    let mounted = true
    if (!course || !course.id) return

    const currentTopicIndex = course.topics.reduce(
      (a, t, i) => (t.id === topicId ? i : a),
      0
    )

    const nextTopicId =
      topicId && course.topics.length > currentTopicIndex + 1
        ? course.topics[currentTopicIndex + 1]?.slug
        : 0

    const firstStartedTopic = course.topics.reduce(
      (a, t) => a || (t.isStarted && !t.isCompleted ? t.slug : 0),
      0
    )
    const lastCompletedTopicIndex = course.topics.reduce(
      (a, t, i) => (t.isCompleted ? i : a),
      0
    )
    const nextUnCompletedTopic =
      lastCompletedTopicIndex > 0
        ? course.topics.length > lastCompletedTopicIndex + 1
          ? course.topics[lastCompletedTopicIndex + 1]?.slug
          : 0
        : course.topics[lastCompletedTopicIndex]?.slug

    const nextTopic = topicId
      ? nextTopicId
      : firstStartedTopic || nextUnCompletedTopic

    if (mounted) setNextTopicId(nextTopic)

    return () => {
      mounted = false
    }
  }, [course, topicId])

  return (
    <>
      {nextTopicId ? (
        <Button
          component={Link}
          id={id}
          color={color}
          variant={variant}
          sx={sx}
          size={size}
          fullWidth={fullWidth}
          onClick={onClick}
          disabled={disabled}
          to={`/courses/${course.slug}/${nextTopicId}`}
        >
          {children}
        </Button>
      ) : undefined}
    </>
  )
}

NextTopicButton.propTypes = {
  course: PropTypes.object.isRequired,
  topicId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.any,
  variant: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.object,
  id: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

NextTopicButton.defaultProps = {
  variant: 'contained',
  topicId: null,
  color: 'primary.main',
  size: 'medium',
  fullWidth: false,
  sx: {},
  disabled: false,
  onClick: () => {}
}
