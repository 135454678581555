import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Typography, Box, Link as MaterialLink } from '@mui/material'
import headphonesSvg from './headphones.svg'

export default function PodcastCard({ title, excerpt, link, channels }) {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow: 'wrap',
        mt: 5,
        p: 2
      }}
    >
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Box className="brand-background background-trinary">
          <Typography variant="h3" color="white" sx={{ pt: 9, pb: 5, px: 4 }}>
            {title ? (
              <>{title}</>
            ) : (
              <Box
                className="is-loading"
                sx={{
                  display: 'flex',
                  px: 4,
                  py: 2,
                  minHeight: 80,
                  width: '100%'
                }}
              ></Box>
            )}
          </Typography>
        </Box>
        <Box sx={{ position: 'absolute', top: -50, left: 30 }}>
          <img
            width="109px"
            alt="headphone-svg"
            style={{
              mt: 3
            }}
            src={headphonesSvg}
          />
        </Box>
      </Box>
      <Box sx={{ p: 7 }}>
        {excerpt ? (
          <Typography
            component="p"
            variant="topicBody"
            dangerouslySetInnerHTML={{
              __html: excerpt ? excerpt : ''
            }}
          ></Typography>
        ) : (
          <Box
            className="is-loading"
            sx={{
              display: 'flex',
              px: 4,
              py: 2,
              minHeight: 180,
              width: '100%'
            }}
          ></Box>
        )}
        <Box sx={{ mt: 3 }}>
          <Typography component="p" variant="topicBody" sx={{ mr: 1 }}>
            Listen here:
          </Typography>

          {channels && channels?.spotify_url ? (
            <Typography
              component={MaterialLink}
              variant="topicBody"
              color="primary"
              sx={{ mr: 1 }}
              target="_blank"
              href={`${channels?.spotify_url}`}
            >
              Spotify,
            </Typography>
          ) : undefined}
          {channels && channels?.amazon_music_url ? (
            <Typography
              component={MaterialLink}
              variant="topicBody"
              color="primary"
              sx={{ mr: 1 }}
              target="_blank"
              href={`${channels?.amazon_music_url}`}
            >
              Amazon Music,
            </Typography>
          ) : undefined}
          {channels && channels?.sound_cloud_url ? (
            <Typography
              component={MaterialLink}
              variant="topicBody"
              color="primary"
              sx={{ mr: 1 }}
              target="_blank"
              href={`${channels?.sound_cloud_url}`}
            >
              Sound Cloud
            </Typography>
          ) : undefined}
        </Box>
        {link ? (
          <Box
            component={Link}
            aria-label="Read More"
            to={`${link}`}
            sx={{
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'space-between',
              mt: { xl: 2 },
              textTransform: 'uppercase',
              textDecoration: 'none',
              ':hover': {
                color: 'secondary',
                textDecoration: 'underline'
              }
            }}
          >
            <Typography component="h5" color="black" sx={{ fontWeight: 600 }}>
              Read more
            </Typography>
          </Box>
        ) : (
          <Box
            className="is-loading"
            sx={{
              display: 'flex',
              px: 4,
              py: 2,
              minHeight: 44,
              alignItems: 'left',
              justifyContent: 'left',
              width: '30%'
            }}
          ></Box>
        )}
      </Box>
    </Box>
  )
}

PodcastCard.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  publishedDate: PropTypes.string,
  channels: PropTypes.object
}
