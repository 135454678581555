import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export default function CourseProgressCircle({ percent, size, strokeWidth }) {
  const [strokeDashOffset, setStrokeDashOffset] = useState(false)
  const [fontSize, setFontSize] = useState(false)
  const diameter = 88
  const radius = diameter / 4 - strokeWidth / 2
  const circumference = Math.PI * radius * 2

  useEffect(() => {
    const offset = circumference * ((100 - percent) / 100)
    const fontSize = size > 100 ? '1.1rem' : '0.75rem'
    setFontSize(fontSize)
    setStrokeDashOffset(offset)
  }, [circumference, percent, size])

  return (
    <>
      <Box sx={{ position: 'relative', display: 'inline-flex', m: 0 }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: '#8c82ad'
          }}
          size={size}
          thickness={3}
          value={100}
        />
        <span
          className="MuiCircularProgress-root MuiCircularProgress-determinate MuiCircularProgress-colorPrimary css-1l4uury-MuiCircularProgress-root"
          role="progressbar"
          aria-valuenow={size}
          style={{
            width: size,
            height: size,
            transform: 'rotate(-90deg)',
            position: 'absolute',
            left: 0,
            color: '#256eff',
            display: 'inline-block',
            transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          }}
        >
          <svg
            className="MuiCircularProgress-svg css-1idz92c-MuiCircularProgress-svg"
            viewBox="22 22 44 44"
          >
            <linearGradient id={`gradient=${size}`}>
              <stop
                offset="0%"
                style={{
                  stopColor: 'rgb(0,209,255)',
                  stopOpacity: 1
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: 'rgb(0,209,255)',
                  stopOpacity: 1
                }}
              />
            </linearGradient>
            <circle
              className="MuiCircularProgress-circle MuiCircularProgress-circleDeterminate MuiCircularProgress-circleDisableShrink css-oxts8u-MuiCircularProgress-circle"
              cx={diameter / 2}
              cy={diameter / 2}
              r={radius}
              fill="none"
              strokeWidth={strokeWidth}
              style={{
                stroke: `url(#gradient=${size})`,
                strokeLinecap: 'square',
                strokeDasharray: circumference,
                strokeDashoffset: strokeDashOffset,
                transition:
                  'transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
              }}
            ></circle>
          </svg>
        </span>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography
            variant="caption"
            component="div"
            fontWeight="600"
            fontFamily="montserrat,sans-serif"
            color="secondary.dark"
            fontSize={fontSize}
          >
            {`${Math.round(percent)}%`}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

CourseProgressCircle.propTypes = {
  percent: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number
}

CourseProgressCircle.defaultProps = {
  percentComplete: 0,
  size: 50,
  strokeWidth: 3
}
