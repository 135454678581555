import PropTypes from 'prop-types'
import { useQueryClient } from '@tanstack/react-query'
import { fetch } from '../common/api'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Button } from '@mui/material'

export default function PreviousTopicButton({
  course,
  topicId,
  children,
  variant,
  color,
  sx,
  id,
  size,
  fullWidth,
  onClick,
  disabled
}) {
  const queryClient = useQueryClient()
  const [previousTopicId, setPreviousTopicId] = useState(0)

  const topicQuery = (courseId, topicId) => ({
    queryKey: ['topic', courseId, topicId],
    queryFn: async () => {
      return await fetch(
        `/courses/${courseId}/topics/${topicId}?related=attachments`
      )
    },
    enabled: !!previousTopicId
  })

  if (previousTopicId)
    queryClient.getQueryData(['topic', course.slug, previousTopicId]) ??
      queryClient.prefetchQuery(topicQuery(course.slug, previousTopicId))

  useEffect(() => {
    let mounted = true
    if (!course || !course.id) return

    const currentTopicIndex = course.topics.reduce(
      (a, t, i) => (t.id === topicId ? i : a),
      0
    )

    const previousTopicId =
      topicId && currentTopicIndex !== 0
        ? course.topics[currentTopicIndex - 1].slug
        : false

    if (mounted) setPreviousTopicId(previousTopicId)

    return () => {
      mounted = false
    }
  }, [course, topicId])

  return (
    <>
      {previousTopicId ? (
        <Button
          component={Link}
          id={id}
          color={color}
          variant={variant}
          sx={sx}
          size={size}
          fullWidth={fullWidth}
          onClick={onClick}
          disabled={disabled}
          to={`/courses/${course.slug}/${previousTopicId}`}
        >
          {children}
        </Button>
      ) : undefined}
    </>
  )
}

PreviousTopicButton.propTypes = {
  course: PropTypes.object.isRequired,
  topicId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.any,
  variant: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.object,
  id: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

PreviousTopicButton.defaultProps = {
  variant: 'contained',
  topicId: null,
  color: 'primary.main',
  size: 'medium',
  fullWidth: false,
  sx: {},
  disabled: false,
  onClick: () => {}
}
