import { useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { useAuth } from './auth'
import {
  Dialog,
  TextField,
  Box,
  Typography,
  Alert,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@mui/material'
import PasswordRequirements from './PasswordRequirements'

function ForgotPasswordForm({ closeModal, emailDefault }) {
  const [showAlert, setShowAlert] = useState(false)
  const [email, setEmail] = useState(null)
  const [code, setCode] = useState(null)
  const [allowSubmit, setAllowSubmit] = useState(false)
  const [showCodeConfrim, setShowCodeConfrim] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const auth = useAuth()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleDialogClose = () => {
    setDialogOpen(false)
    if (closeModal && typeof closeModal === 'function') closeModal()
  }

  const handleEmail = (e) => {
    setEmail(e.target.value.trim())
    setAllowSubmit(true)
  }

  const handleCode = (e) => {
    setCode(e.target.value)
    setAllowSubmit(e.target.value.length > 3 ? true : false)
  }

  const handleForgotPasswordRequest = async (e) => {
    setShowAlert(false)
    e.preventDefault()
    e.stopPropagation()

    try {
      await Auth.forgotPassword(email)
      setShowCodeConfrim(true)
      setAllowSubmit(false)
    } catch (e) {
      setShowAlert(e.message)
      console.log(e.code)
    }
  }

  const handleConfirmPasswordRequest = async (e) => {
    setShowAlert(false)
    e.preventDefault()
    e.stopPropagation()

    const data = new FormData(e.currentTarget)
    const newPassword = data.get('new-password').trim()
    const confirmPassword = data.get('confirm-password').trim()

    if (newPassword !== confirmPassword) {
      setShowAlert(
        'Sorry, your confirm password does not match your new password. Please verify and try again.'
      )
      return false
    }

    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword)
      const loginToken = await executeRecaptcha('loginToken')
      await auth.login(email, newPassword, loginToken)
      setDialogOpen(true)
    } catch (e) {
      setShowAlert(e.message)
      console.log(e.code)
    }
  }

  useEffect(() => {
    let mounted = true

    if (emailDefault !== undefined && mounted) {
      setEmail(emailDefault)
      setAllowSubmit(true)
    }

    return () => {
      mounted = false
    }
  }, [emailDefault])

  return (
    <>
      <Dialog
        onClose={handleDialogClose}
        open={dialogOpen}
        aria-labelledby="reset-password-confirmation-title"
        aria-describedby="reset-password-confirmation-description"
      >
        <DialogContent>
          <DialogTitle id="reset-password-confirmation-title">
            You have successfully reset your password. You may continue your
            training experience.
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            onClick={handleDialogClose}
            sx={{ mx: 3, mb: 3 }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Typography
          component="h1"
          variant="h5"
          sx={{ textAlign: 'center', textTransform: 'uppercase' }}
        >
          Reset your password
        </Typography>
        {showAlert ? (
          <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
            {showAlert}
          </Alert>
        ) : undefined}
      </Box>
      {!showCodeConfrim ? (
        <Box component="form" onSubmit={handleForgotPasswordRequest}>
          {emailDefault ? (
            <Typography
              component="p"
              variant="body1"
              sx={{ mt: 1, textAlign: 'center' }}
            >
              Once submitted, a reset code will be sent to your email address.
              Please enter this code on the next screen.
            </Typography>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Enter your email address"
                name="email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={handleEmail}
              />

              <Typography
                component="p"
                variant="body1"
                sx={{ mt: 1, textAlign: 'center' }}
              >
                On submission, a reset code will be sent to your email address
                above. Please enter this code on the next screen.
              </Typography>
            </>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, p: 2 }}
            disabled={!allowSubmit}
          >
            Send my reset password code
          </Button>
        </Box>
      ) : (
        <Box component="form" onSubmit={handleConfirmPasswordRequest}>
          <Typography component="p" variant="body1" sx={{ mt: 1 }}>
            Please enter your new password twice and the code received via your
            email.
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="new-password"
            label="Enter your new password"
            type="password"
            id="new-password"
            autoComplete="new-password"
          />{' '}
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirm-password"
            label="Confirm password"
            type="password"
            id="confirm-password"
            autoComplete="confirm-password"
          />
          <PasswordRequirements />
          <Grid
            container
            rowSpacing={{ xs: 0, lg: 0 }}
            columnSpacing={{ xs: 2 }}
          >
            <Grid item xs={6} lg={6}>
              <TextField
                margin="normal"
                fullWidth
                id="code"
                label="Enter code"
                name="code"
                autoComplete="code"
                onChange={handleCode}
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2, p: 2 }}
                disabled={!allowSubmit}
              >
                Submit code
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

ForgotPasswordForm.propTypes = {
  closeModal: PropTypes.func,
  emailDefault: PropTypes.string
}

export default ForgotPasswordForm
