import { Component } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter } from 'react-router-dom'
import { Box, Container, Typography, Button } from '@mui/material'
import Footer from '../layout/Footer'
import Nav from '../layout/Nav'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

class ErrorBoundary extends Component {
  state = { hasError: false, errorStatus: false }
  static getDerivedStateFromError(error) {
    return { hasError: true, errorStatus: error?.response?.status }
  }
  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo })
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Nav />
              <Container component="section">
                <Box sx={{ my: 20, textAlign: 'center' }}>
                  <Typography
                    component="h1"
                    sx={{ fontSize: '100px !important', mb: 4 }}
                  >
                    Error: {this.state.errorStatus}
                  </Typography>
                  <Typography sx={{ pb: 8 }}>
                    wow.. how embarrassing.. somthing went wrong. Please retry
                  </Typography>

                  <Button
                    id="reset-password"
                    color="secondary"
                    variant="contained"
                    onClick={() => this.setState({ hasError: false })}
                    sx={{ ml: 2 }}
                  >
                    Retry!
                  </Button>
                </Box>
              </Container>

              <Footer></Footer>
            </BrowserRouter>
          </QueryClientProvider>
        </>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
}

export default ErrorBoundary
