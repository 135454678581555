import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Typography, Box, Card, CardActions, CardContent } from '@mui/material'

export default function ShortCard({ title, excerpt, link, publishedDate }) {
  const published = publishedDate
    ? new Date(publishedDate).toLocaleString('default', {
        month: 'long',
        year: 'numeric'
      })
    : ''

  return (
    <Card
      className={'course-card mdc-card'}
      elevation={3}
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow: 'wrap'
      }}
    >
      <CardContent sx={{ textAlign: 'left', p: 3, width: '100%' }}>
        {title ? (
          <Typography
            variant="h3"
            sx={{ textTransform: 'none', fontWeight: '600' }}
            dangerouslySetInnerHTML={{
              __html: title ? title : ''
            }}
          ></Typography>
        ) : (
          <Box
            className="is-loading"
            sx={{
              px: 4,
              py: 5,
              mb: 2,
              width: '100%'
            }}
          ></Box>
        )}
        {title ? (
          <Typography component="h6" sx={{ my: 2 }}>
            {published}
          </Typography>
        ) : (
          <Box
            className="is-loading"
            sx={{
              px: 4,
              py: 1,
              mb: 2,
              width: '50%'
            }}
          ></Box>
        )}
        {title ? (
          <Typography component="p">{excerpt ? excerpt : ''}</Typography>
        ) : (
          <Box
            className="is-loading"
            sx={{
              px: 4,
              py: 6,
              width: '100%'
            }}
          ></Box>
        )}
      </CardContent>
      <CardActions disableSpacing sx={{ px: 3, width: '100%' }}>
        {link ? (
          <Box
            component={Link}
            aria-label="Read More"
            to={`${link}`}
            sx={{
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'space-between',
              mt: { xl: 2 },
              textTransform: 'uppercase',
              textDecoration: 'none',
              ':hover': {
                color: 'secondary',
                textDecoration: 'underline'
              }
            }}
          >
            <Typography
              component="h5"
              color="primary.main"
              sx={{ fontWeight: 600 }}
            >
              Read more
            </Typography>
          </Box>
        ) : (
          <Box
            className="is-loading"
            sx={{
              display: 'flex',
              px: 4,
              py: 2,
              minHeight: 44,
              alignItems: 'left',
              justifyContent: 'left',
              width: '30%'
            }}
          ></Box>
        )}
      </CardActions>
    </Card>
  )
}

ShortCard.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  publishedDate: PropTypes.string
}
