import { update } from '../common/api'

const createInteraction = (type, userId, courseId, topicId, metadata) => {
  return update(`/users/${userId}/interactions`, {
    interactionType: type,
    courseId: courseId,
    ...(topicId ? { topicId: topicId } : {}),
    ...(metadata ? { metadata: metadata } : {})
  })
}

export const completeTopic = (userId, courseId, topicId, metadata) => {
  return createInteraction(
    'topic_completed',
    userId,
    courseId,
    topicId,
    metadata
  )
}

export const courseProgress = (userId, courseId, percent) => {
  return update(`/users/${userId}/interactions`, {
    interactionType: 'course_progression',
    courseId: courseId,
    percentCompleted: percent
  })
}

export const resetTopicFailedAttemps = (userId, courseId, topicId) => {
  return createInteraction('topic_reset', userId, courseId, topicId)
}

export const enrollCourse = (userId, courseId) => {
  return createInteraction('course_enrollment', userId, courseId)
}

export const create = (type, userId, courseId) => {
  return createInteraction(type, userId, courseId)
}

export const buildXapiInteractionEndPoint = (userId) => {
  return (
    process.env.REACT_APP_KIDAWARE_API_BASE_URL +
    `/users/${userId}/interactions/xapi/`
  )
}
