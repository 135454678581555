import { useState } from 'react'
import PropTypes from 'prop-types'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import SaveIcon from '@mui/icons-material/Save'
export default function TopicEditorSaveButton({
  editorState,
  handleSave,
  attachment
}) {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

  const handleSaveLogic = async () => {
    if (saveButtonDisabled) return

    setSaveButtonDisabled(true)
    console.log('save attempt')

    if (handleSave && typeof handleSave === 'function') {
      try {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        const attachmentPayload = {
          ...attachment,
          ...{ html: html }
        }

        await handleSave(attachmentPayload)
        setSaveButtonDisabled(false)
      } catch (e) {
        setSaveButtonDisabled(false)
        console.log(e)
      }
    }
  }

  return (
    <div className="rdw-option-wrapper" title="Save" onClick={handleSaveLogic}>
      <SaveIcon color="secondary" disabled={saveButtonDisabled} />
    </div>
  )
}

TopicEditorSaveButton.propTypes = {
  editorState: PropTypes.object,
  attachment: PropTypes.object,
  handleSave: PropTypes.func
}
