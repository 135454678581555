import { Navigate } from 'react-router-dom'
import CmsPage from './pages/CmsPage'
import Curriculum from './course/Curriculum'
import Course from './course/Course'
import LoginPrompt from './account/LoginPrompt'
import MyProfile from './account/MyProfile'
import CourseHeader from './course/CourseHeader'
import Topic from './course/Topic'
import Resources from './pages/Resources'
import ResourceType from './pages/ResourceType'
import Resource from './pages/Resource'
import { RequireAuth } from './account/auth'
import VirtualInstructorLedTrainingRegistration from './course/VirtualInstructorLedTrainingRegistration'
import Contact from './pages/Contact'
import CourseSearchResults from './course/CourseSearchResults'
import NotFound from './pages/NotFound'

const routes = [
  { path: '/', element: <CmsPage /> },
  { path: '/contact-us', element: <Contact /> },
  { path: '/about-us', element: <CmsPage /> },
  { path: '/certificate-program', element: <></> },
  {
    path: '/resources',
    children: [
      {
        path: '',
        element: <Resources />
      },
      {
        path: ':type',
        element: <ResourceType />
      },
      {
        path: ':category/:slug',
        element: <Resource />
      }
    ]
  },
  { path: '/terms-of-use', element: <CmsPage /> },
  { path: '/privacy-policy', element: <CmsPage /> },
  { path: '/login', element: <LoginPrompt returnPath="/courses" /> },
  {
    path: '/courses',
    element: (
      <RequireAuth>
        <CourseHeader />
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        element: <Curriculum />
      },
      {
        path: 'virtual-instructor-led-training-registration',
        element: (
          <RequireAuth forceLogin={true}>
            <VirtualInstructorLedTrainingRegistration />
          </RequireAuth>
        )
      },
      {
        path: ':id',
        element: <Course />
      },
      {
        path: ':courseId/:id',
        element: (
          <RequireAuth forceLogin={true}>
            <Topic />
          </RequireAuth>
        )
      },
      {
        path: 'search-results/',
        element: (
          <RequireAuth forceLogin={true}>
            <CourseSearchResults />
          </RequireAuth>
        )
      }
    ]
  },
  {
    path: '/account',
    element: (
      <RequireAuth forceLogin={true}>
        <MyProfile />
      </RequireAuth>
    ),
    children: [{ path: ':slug', element: <></> }]
  },
  { path: '404', element: <NotFound /> },
  { path: '*', element: <Navigate to="/404" replace /> }
]

export default routes
