import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@mui/material'
import { buildBasicAuth, fetch } from '../common/api'
import { buildXapiInteractionEndPoint } from '../common/interactions'
import VerifiedIcon from '@mui/icons-material/Verified'

export default function TopicXapi({
  attachment,
  courseId,
  topicId,
  userId,
  userEmail,
  preview,
  callback,
  endPoint
}) {
  const [file, setFile] = useState('')
  const [failedAttemps, setFailedAttemps] = useState(0)
  const [topicPassed, setTopicPassed] = useState(0)
  const [topicScore, setTopicScore] = useState(0)

  const reloadIframe = () => {
    const fileCopy = `${file} `
    setFile(fileCopy)
  }

  const handleDataSync = useCallback(async () => {
    try {
      const topic = await fetch(endPoint)

      setFailedAttemps(topic?.failedTopicAttempts)
      setTopicPassed(topic?.topicScore && topic.isCompleted)
      setTopicScore(topic?.topicScore)

      typeof callback == 'function' && callback(topic)
    } catch (e) {
      console.error(e.message)
    }
  }, [endPoint, callback])

  useEffect(() => {
    let mounted = true
    let timer

    if (mounted) {
      clearTimeout(timer)
      window.addEventListener(
        'blur',
        function () {
          timer = window.setTimeout(function () {
            if (
              document.activeElement === document.getElementById('xapi-iframe')
            ) {
              window.addEventListener(
                'click',
                function () {
                  if (endPoint) handleDataSync()
                },
                { once: true }
              )
            }
          }, 0)
        },
        { once: true }
      )
    }

    const fetchData = async () => {
      const topic = await fetch(endPoint)

      setFailedAttemps(topic?.failedTopicAttempts)
      setTopicPassed(topic?.topicScore && topic.isCompleted)
      setTopicScore(topic?.topicScore)
    }

    const buildXapiContentPath = (attachment) => {
      const path = attachment?.uri
      const file = preview ? 'story.html' : 'index_lms.html'

      return (
        path &&
        path
          .substring(
            0,
            attachment.uri.indexOf(attachment.id) + attachment.id.length + 1
          )
          .replace('zip', 'content')
          .concat(file)
      )
    }

    const buildXapiUrlQuery = (courseId, topicId, userId, userEmail) => {
      const actor = userId
        ? `{"id":["${userId}"],"name":["${userId}"],"mbox":["mailto:${userEmail}"]}`
        : ''

      return `?endpoint=${encodeURIComponent(
        buildXapiInteractionEndPoint(userId)
      )}&auth=${buildBasicAuth()}&actor=${encodeURIComponent(
        actor
      )}&activity_id=${courseId}|${topicId}|`
    }

    const getAttachment = async (
      courseId,
      topicId,
      attachment,
      userId,
      userEmail
    ) => {
      const uri = buildXapiContentPath(attachment)
      const query = userId
        ? buildXapiUrlQuery(courseId, topicId, userId, userEmail)
        : ''

      setFile(`${process.env.REACT_APP_ASSETS_S3_URL}/${uri}${query}`)
    }

    fetchData()

    if (mounted) {
      getAttachment(courseId, topicId, attachment, userId, userEmail, endPoint)
    }

    return () => {
      mounted = false
      clearTimeout(timer)
    }
  }, [
    courseId,
    attachment,
    userId,
    topicId,
    userEmail,
    preview,
    handleDataSync,
    endPoint
  ])

  return (
    <>
      {failedAttemps > 0 && failedAttemps < 3 && !topicPassed ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h2">
            You last scored {(topicScore * 100).toFixed(2)}%
          </Typography>
          <Typography variant="h4" sx={{ mt: 3, fontWeight: 300 }}>
            You need a score of at least 80% to continue - you may retake this
            quiz {3 - failedAttemps} more time(s.)
          </Typography>
        </Box>
      ) : undefined}
      {failedAttemps >= 3 && !topicPassed ? (
        <Box sx={{ textAlign: 'center', my: 6 }}>
          <Typography variant="h2">
            We are sorry but you have attemped to complete the quiz three times
            without success.
          </Typography>
          <Typography variant="h4" sx={{ my: 3, fontWeight: 300 }}>
            An KidAware admin will contact you shortly.
          </Typography>
        </Box>
      ) : topicPassed ? (
        <Box sx={{ textAlign: 'center', my: 6 }}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            Congrats on passing the Test!
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography variant="h3" sx={{ my: 3 }}>
              You Scored: {(topicScore * 100).toFixed(2)}%
            </Typography>
            <VerifiedIcon
              fontSize="large"
              sx={{ color: 'primary.main', ml: 1, mb: 1 }}
            ></VerifiedIcon>
          </Box>
          <Typography variant="h4" sx={{ fontWeight: 300 }}>
            To claim your badge, check your inbox (and spam folder) for an email
            from Accredible, our badging tool, which will provide you with
            instructions for how to claim your credential and post on LinkedIn.
          </Typography>
        </Box>
      ) : (
        <>
          <Box className="xapi-iframe">
            <iframe
              src={file}
              title="xapi-topic"
              frameBorder="0"
              id="xapi-iframe"
            ></iframe>
          </Box>
        </>
      )}
      {attachment.kind === 'xapi_quiz' && failedAttemps < 3 && !topicPassed ? (
        <Button
          fullWidth
          id="refresh-quiz-status"
          sx={{ mb: 1, py: 2 }}
          color="black"
          variant="contained"
          onClick={reloadIframe}
        >
          Refresh your quiz status
        </Button>
      ) : undefined}
    </>
  )
}

TopicXapi.propTypes = {
  attachment: PropTypes.object.isRequired,
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  topicId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endPoint: PropTypes.string,
  userEmail: PropTypes.string,
  preview: PropTypes.bool,
  callback: PropTypes.func
}
