import { Container, Typography, Box } from '@mui/material'

const NotFound = () => {
  return (
    <Container component="section">
      <Box sx={{ my: 20, textAlign: 'center' }}>
        <Typography component="h1" sx={{ fontSize: '100px !important', mb: 4 }}>
          404
        </Typography>
        <Typography sx={{ pb: 8 }}>
          We are sorry, but We can&apos;t seem to find the page you&apos;re
          looking for.
        </Typography>
      </Box>
    </Container>
  )
}

export default NotFound
