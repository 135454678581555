import { Container, Box, Grid } from '@mui/material'
import PropTypes from 'prop-types'

export default function HeroBannerAngled({
  children,
  image,
  imageLocation,
  height
}) {
  return (
    <Box
      sx={{
        pb: 4
      }}
    >
      <Box
        sx={{
          position: 'relative'
        }}
      >
        <Grid
          container
          columnSpacing={2}
          rowSpacing={0}
          direction={imageLocation === 'right' ? 'row' : 'row-reverse'}
        >
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              height: height,
              '&::after': {
                content: "''",
                position: 'absolute',
                backgroundColor: 'secondary.main',
                width: { xs: '100%', md: '66%' },
                height: '100%',
                ...(imageLocation === 'right' ? { left: 0 } : { right: 0 }),
                top: 0,
                transform: 'skewY(-3deg)',
                zIndex: -1
              }
            }}
          ></Grid>
          <Grid item xs={0} md={4}></Grid>
        </Grid>
        <Grid
          container
          columnSpacing={2}
          rowSpacing={0}
          direction={imageLocation === 'right' ? 'row' : 'row-reverse'}
          sx={{
            position: 'absolute',
            height: height,
            top: 0
          }}
        >
          <Grid item xs={6} sm={8} md={6}></Grid>
          <Grid
            item
            xs={6}
            sm={4}
            md={6}
            sx={{
              display: { xs: 'none', sm: 'flex' },
              alignItems: 'center',
              py: 3,
              mt: '2.2%',
              minHeight: height
            }}
          >
            {image ? (
              <Box
                sx={{
                  backgroundImage: `url(${image})`,
                  height: '100%',
                  width: '100%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  transform: 'skew(-3deg) rotate(-3deg)'
                }}
              ></Box>
            ) : (
              <Box
                className="is-loading"
                sx={{
                  minHeight: '100%',
                  width: '100%'
                }}
              ></Box>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            height: height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <Container component="main" maxWidth="xl">
            <Grid
              container
              rowSpacing={0}
              columnSpacing={{ xs: 2 }}
              direction={imageLocation === 'right' ? 'row' : 'row-reverse'}
            >
              <Grid
                item
                xs={12}
                sm={7}
                md={5}
                sx={{
                  height: height,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <div>{children}</div>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

HeroBannerAngled.propTypes = {
  children: PropTypes.array,
  image: PropTypes.string,
  imageLocation: PropTypes.string,
  height: PropTypes.object
}

HeroBannerAngled.defaultProps = {
  imageLocation: 'right',
  height: {
    xs: '397px',
    md: '397px',
    lg: '497px',
    xl: '597px'
  }
}
