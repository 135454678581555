import PropTypes from 'prop-types'
import { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { useOutletContext } from 'react-router-dom'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import DataGridWithFiltering from '../common/DataGridWithFiltering'
import ButtonConfirm from '../common/ButtonConfirm'
import Enrollment from '../admin/Enrollment'
import {
  Typography,
  Box,
  SwipeableDrawer,
  Link as MUILink
} from '@mui/material'
import VerifiedIcon from '@mui/icons-material/Verified'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import ReplayIcon from '@mui/icons-material/Replay'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import VideocamIcon from '@mui/icons-material/Videocam'
import { fetch } from '../common/api'
import {
  completeTopic,
  courseProgress,
  enrollCourse
} from '../common/interactions'
import { useAuth } from './auth'

function AccountEnrollment({ subId }) {
  const [listCopy, setListCopy] = useState([])
  const [showItem, setShowItem] = useState(false)
  const [enrollment, setEnrollment] = useState(null)
  const queryClient = useQueryClient()
  const auth = useAuth()
  const { setNotification } = useOutletContext() || {}

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setShowItem(open)
  }

  const markCourseAsComplete = async (course) => {
    if (!auth.isAdmin) return

    course.topics
      .filter((t) => t.isCompleted === false)
      .forEach(async (topic) => {
        await completeTopic(subId, course.id, topic.id, {
          createdBy: auth.user.email
        })
      })

    await courseProgress(subId, course.id, 100)

    const newCourse = {
      ...course,
      ...{
        percentCompleted: 100
      },
      ...{
        topics: course.topics.map((t) => ({
          ...t,
          ...{ isCompleted: true }
        }))
      }
    }

    const newList = list.map((c) => {
      return { ...c, ...(c.id === course.id ? newCourse : null) }
    })

    queryClient.setQueryData(['userEnrollments', subId], newList)
  }

  const resetCourse = async (course) => {
    if (!auth.isSuperAdmin && !auth.isAdmin) return

    await enrollCourse(subId, course.id)

    const today = new Date(Date.now())
    const newCourse = {
      ...course,
      ...{
        percentCompleted: 0,
        enrolledAt: today.toLocaleDateString()
      },
      ...{
        topics: course.topics.map((t) => ({
          ...t,
          ...{ isCompleted: false }
        }))
      }
    }

    const newList = list.map((c) => {
      return { ...c, ...(c.id === course.id ? newCourse : null) }
    })

    queryClient.setQueryData(['userEnrollments', subId], newList)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Course',
      minWidth: 280,
      flex: 1,
      editable: false,
      renderCell: function renderCell(params) {
        return (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}
          >
            {params.row.percentCompleted === 100 ? (
              <VerifiedIcon
                sx={{ color: 'tertiaryCerulean.main', ml: 1 }}
              ></VerifiedIcon>
            ) : params.row.type === 'vilt' ? (
              <VideocamIcon
                sx={{ color: 'darkPurple.main', ml: 1 }}
              ></VideocamIcon>
            ) : (
              <CircleOutlinedIcon
                color="disabled"
                sx={{ ml: 1 }}
              ></CircleOutlinedIcon>
            )}
            <Typography sx={{ ml: 1 }}>
              {params.value}{' '}
              {params.row.type === 'vilt' ? '(ZOOM Meeting)' : ''}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'percentCompleted',
      headerName: 'Details',
      minWidth: 80,
      flex: 1,
      editable: false,
      renderCell: function renderCell(params) {
        return (
          <>
            {params.row.type !== 'vilt' ? (
              <Typography sx={{ ml: 1 }}>
                {params?.value?.toFixed(2)} %
              </Typography>
            ) : (
              <Typography sx={{ ml: 1 }}>
                Starts @ {params.row?.startTime}
              </Typography>
            )}
          </>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 1,
      maxWidth: 100,
      editable: false,
      getActions: (params) => [
        <>
          {params.row.type === 'vilt' ? (
            <MUILink
              aria-label="join zoom"
              target="_blank"
              href={`https://us05web.zoom.us/${params.row.zoomCode}`}
            >
              <GridActionsCellItem
                id={`enrollment-complete-${params.id}`}
                icon={<MeetingRoomIcon />}
                sx={{ color: 'darkPurple.main' }}
                label="Join Zoom"
              />
            </MUILink>
          ) : auth.isAdmin ? (
            <>
              {params.row.percentCompleted >= 100 ? (
                <ButtonConfirm
                  key={`edit-${params.id}`}
                  confirmAction={() => resetCourse(params.row)}
                  component={
                    <GridActionsCellItem
                      id={`enrollment-reset-${params.id}`}
                      icon={<ReplayIcon />}
                      sx={{ color: 'primary.main' }}
                      label="Reset Course"
                    />
                  }
                >
                  <>
                    Are you sure you want to mark reset the status of this
                    course?
                  </>
                </ButtonConfirm>
              ) : (
                <ButtonConfirm
                  key={`edit-${params.id}`}
                  confirmAction={() => markCourseAsComplete(params.row)}
                  component={
                    <GridActionsCellItem
                      id={`enrollment-complete-${params.id}`}
                      icon={<VerifiedIcon />}
                      sx={{ color: 'tertiaryCerulean.main' }}
                      label="Mark as Completed"
                    />
                  }
                >
                  <>Are you sure you want to mark the course as completed?</>
                </ButtonConfirm>
              )}
            </>
          ) : (
            <>
              {params.row.percentCompleted >= 100 ? (
                <Link
                  aria-label="restart topic"
                  target="_blank"
                  to={`/courses/${params.row.slug}`}
                >
                  <GridActionsCellItem
                    id={`enrollment-complete-${params.id}`}
                    icon={<ReplayIcon />}
                    sx={{ color: 'trinary.main' }}
                    label="Restart"
                  />
                </Link>
              ) : (
                <Link
                  aria-label="continue topic"
                  target="_blank"
                  to={`/courses/${params.row.slug}`}
                >
                  <GridActionsCellItem
                    id={`enrollment-complete-${params.id}`}
                    icon={<PlayCircleOutlineIcon />}
                    sx={{ color: 'secondary.main' }}
                    label="Continue"
                  />
                </Link>
              )}
            </>
          )}
        </>
      ]
    }
  ]

  const filterList = (list) => {
    list &&
      list.length > 0 &&
      queryClient.setQueryData(['userEnrollments', subId], list)
  }

  const useAccountEnrollments = (userId) => {
    return useQuery({
      queryKey: ['userEnrollments', userId],
      queryFn: async () => {
        return await fetch(`/users/${subId}/courses?related=topics`)
      },
      onSuccess: (courses) => {
        const filteredCourses = courses.filter((c) => c.percentCompleted >= 0)
        setListCopy(filteredCourses)
      },
      enabled: !!subId
    })
  }

  const { status, data: list, error } = useAccountEnrollments(subId)

  return (
    <>
      <Typography
        component="h2"
        variant="h5"
        sx={{ textAlign: 'left', textTransform: 'uppercase', my: 3 }}
      >
        Enrollment
      </Typography>
      {status === 'error' ? (
        <span>Error: {error.message}</span>
      ) : (
        <>
          <DataGridWithFiltering
            height="600px"
            rows={list ?? []}
            rowsCopy={listCopy}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            setRows={filterList}
            loading={status === 'loading'}
            onCellClick={(_, event) => {
              event.defaultMuiPrevented = true
            }}
            onRowClick={({ row }) => {
              if (!auth.isAdmin) return
              if (row.type === 'vilt') return
              setEnrollment(row)
              setShowItem(true)
            }}
            getRowId={(row) => row.id}
          />
          <SwipeableDrawer
            anchor="right"
            open={showItem && auth.isAdmin}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <Box sx={{ p: 4 }}>
              {enrollment ? (
                <Enrollment
                  current={enrollment}
                  subId={subId}
                  onClose={toggleDrawer(false)}
                  onSuccess={(item) => {
                    const index = list.findIndex((i) => i.id === item.id)
                    const newList = [...list]

                    if (index === -1) newList.push(item)
                    else newList.splice(index, 1, item)

                    queryClient.setQueryData(
                      ['userEnrollments', subId],
                      newList
                    )

                    setNotification({
                      open: true,
                      message: 'Rersource has been saved',
                      severity: 'success'
                    })
                  }}
                />
              ) : undefined}
            </Box>
          </SwipeableDrawer>
        </>
      )}
    </>
  )
}

AccountEnrollment.propTypes = {
  account: PropTypes.object,
  subId: PropTypes.string
}

export default AccountEnrollment
