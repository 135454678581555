import { useEffect, useState } from 'react'
import { useLocation, Link as ReactLink } from 'react-router-dom'
import { Breadcrumbs, Link } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

function BreadCrumb() {
  const location = useLocation()
  const [currentRoute, setCurrentRoute] = useState([{ path: '' }])

  useEffect(() => {
    const paths = window.location.pathname.substring(1).split('/')
    const formatedPaths = paths.map((p, i) => ({
      path: paths.filter((p, pi) => pi <= i).reduce((a, p) => a + `/${p}`, ''),
      name: p.replaceAll('-', ' ')
    }))

    setCurrentRoute(formatedPaths ? formatedPaths : [{ path: '' }])
  }, [location])

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{ my: 3 }}
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {currentRoute
        .filter((r) => r.path !== '')
        .map((r, key) => {
          return (
            <Link
              component={ReactLink}
              sx={{ textTransform: 'uppercase' }}
              underline="hover"
              color="inherit"
              to={r.path}
              key={key}
            >
              {decodeURI(r.name)}
            </Link>
          )
        })}
    </Breadcrumbs>
  )
}

export default BreadCrumb
