import { useEffect, useState } from 'react'
import DataGridWithFiltering from '../common/DataGridWithFiltering'
import {
  Container,
  Typography,
  Box,
  Alert,
  FormControl,
  OutlinedInput,
  Button
} from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { fetch, cancel as cancelRequest, update, destroy } from '../common/api'
import ButtonConfirm from '../common/ButtonConfirm'
import DeleteIcon from '@mui/icons-material/Delete'

export default function LearningPath() {
  const [list, setList] = useState([])
  const [listCopy, setListCopy] = useState([])
  const [showAlert, setShowAlert] = useState(null)
  const [newPath, setNewPath] = useState(null)

  const columns = [
    {
      field: 'type',
      headerName: 'Name (Editable)',
      editable: true,
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: (params) => [
        <ButtonConfirm
          key={`edit-${params.id}`}
          confirmAction={() => handleRemovePath(params.id)}
          component={
            <GridActionsCellItem
              id={`resource-delete-confirm-${params.id}`}
              icon={<DeleteIcon />}
              sx={{ color: 'primary.main' }}
              label="Delete"
            />
          }
        >
          <>Please confirm if you would like to delete the learning path.</>
        </ButtonConfirm>
      ]
    }
  ]

  const handleRemovePath = async (id) => {
    try {
      setShowAlert(false)
      await destroy(`/learning-paths/${id}`)

      const index = list.findIndex((r) => r.id === id)
      const newList = [...list]
      newList.splice(index, 1)
      setList(newList)
      setListCopy(newList)

      setShowAlert({
        severity: 'success',
        message: 'Resource deleted!'
      })
    } catch (e) {
      setShowAlert({ severity: 'error', message: e.message })
      console.log(e)
    }
  }
  const handleNewPath = (e) => {
    setNewPath(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      setShowAlert(false)
      const path = await update(`/learning-paths/`, { type: newPath })
      const newList = [...list]

      newList.push(path)
      setList(newList)
      setNewPath('')

      setShowAlert({
        severity: 'success',
        message: 'Path added!'
      })
    } catch (e) {
      setShowAlert(e.message)
    }
  }

  const handleUpdate = async ({ id, value }) => {
    const path = { id: id, type: value }

    try {
      setShowAlert(false)
      await update(`/learning-paths/${id}`, path)

      const index = list.findIndex((r) => r.id === id)
      const newList = [...list]
      newList.splice(index, 1, path)
      setList(newList)
      setListCopy(newList)

      setShowAlert({
        severity: 'success',
        message: 'Path updated!'
      })
    } catch (e) {
      setShowAlert(e.message)
    }
  }

  useEffect(() => {
    let mounted = true
    let request

    async function fetchData() {
      try {
        request = fetch(`/learning-paths`)
        const paths = await request
        if (mounted) {
          setList(paths)
          setListCopy(paths)
        }
      } catch (e) {
        setShowAlert(e.message)
        console.log(e)
      }
    }

    fetchData()

    return () => {
      mounted = false
      cancelRequest(request)
    }
  }, [])

  return (
    <>
      <Container component="main">
        {showAlert ? (
          <Alert
            variant="outlined"
            severity={showAlert.severity}
            sx={{ my: 2 }}
          >
            {showAlert.message}
          </Alert>
        ) : undefined}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            component="h1"
            variant="h4"
            sx={{ textAlign: 'left', textTransform: 'uppercase', mb: 2 }}
          >
            Learning Paths
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: 'flex', justifyContent: 'end', mb: 1 }}
            >
              <FormControl>
                <OutlinedInput
                  id="name"
                  name="name"
                  placeholder="Enter new learning path*"
                  required
                  size="small"
                  onChange={handleNewPath}
                />
              </FormControl>
              <Button type="submit" size="small" variant="outlined">
                Add New
              </Button>
            </Box>
          </Box>
        </Box>

        <DataGridWithFiltering
          rows={list}
          rowsCopy={listCopy}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          setRows={setList}
          onCellEditCommit={handleUpdate}
          getRowId={(row) => row.id}
        />
      </Container>
    </>
  )
}
