import { useState } from 'react'
import PropTypes from 'prop-types'
import DeleteIcon from '@mui/icons-material/Delete'
import ButtonConfirm from '../common/ButtonConfirm'

export default function TopicEditorRemoveButton({
  handleRemove,
  attachment,
  index
}) {
  const [removeButtonDisabled, setremoveButtonDisabled] = useState(false)

  const handleRemoveLogic = async () => {
    if (removeButtonDisabled) return

    setremoveButtonDisabled(true)

    if (handleRemove && typeof handleRemove === 'function') {
      try {
        await handleRemove(attachment)
        setremoveButtonDisabled(false)
      } catch (e) {
        setremoveButtonDisabled(false)
        console.log(e)
      }
    }
  }

  return (
    <ButtonConfirm
      confirmAction={handleRemoveLogic}
      component={
        <div
          id={`editor-delete-confirm-${index}`}
          className="rdw-option-wrapper"
          title="delete"
        >
          <DeleteIcon color="primary" disabled={removeButtonDisabled} />
        </div>
      }
    >
      <>
        Please confirm if you would like to delete the html attachment. You must
        click &quot;Update&quot; to save the deletion.
      </>
    </ButtonConfirm>
  )
}

TopicEditorRemoveButton.propTypes = {
  editorState: PropTypes.object,
  attachment: PropTypes.object,
  handleRemove: PropTypes.func,
  index: PropTypes.number
}
