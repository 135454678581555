import PropTypes from 'prop-types'
import { useResourceLink } from './useResourceQueries.js'
import { Link } from 'react-router-dom'
import resourcePlaceholderImage from './blog-placeholder.jpg'
import {
  Typography,
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  CardActionArea,
  CardContent,
  Chip,
  Link as MuiLink
} from '@mui/material'

export default function FullCard({
  link,
  title,
  excerpt,
  imageLinkUrl,
  mediaHeight,
  category,
  isLoading,
  categoryColor,
  isExternalLink
}) {
  const { status: imageStatus, data: imageLink } = useResourceLink(
    title,
    'image',
    imageLinkUrl,
    !!imageLinkUrl
  )

  const imageSize =
    imageLink?.media_details?.sizes['resource-thumbnail']?.source_url ??
    imageLink?.media_details?.sizes['medium']?.source_url

  const url = link?.url ?? link

  return (
    <Card
      className={'course-card mdc-card'}
      elevation={3}
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow: 'wrap',
        p: 2
      }}
    >
      <CardActionArea
        sx={{
          maxHeight: mediaHeight
        }}
      >
        {!isLoading ? (
          <CardMedia
            sx={{
              position: 'relative',
              backgroundSize: 'contain',
              backgroundImage: `url(${
                imageStatus === 'success' ? imageSize : resourcePlaceholderImage
              })`,
              maxHeight: mediaHeight,
              borderRadius: 1
            }}
          >
            <Box height={mediaHeight}></Box>
            {category ? (
              <Box
                height={'200px'}
                className=""
                sx={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'end',
                  p: 1
                }}
              >
                <Chip
                  size="small"
                  label={category}
                  variant="filled"
                  color={categoryColor}
                  sx={{ px: 2, borderRadius: 1 }}
                />
              </Box>
            ) : undefined}
          </CardMedia>
        ) : !isLoading && (imageStatus === 'success' || !imageStatus) ? (
          <></>
        ) : (
          <Box
            className="is-loading"
            sx={{
              py: 6,
              minHeight: mediaHeight,
              width: '100%'
            }}
          ></Box>
        )}
      </CardActionArea>
      <CardContent sx={{ textAlign: 'left', width: '100%' }}>
        {!isLoading ? (
          <Typography
            component="h3"
            variant="cardBigTitle"
            sx={{ textTransform: 'none', mb: 2 }}
            dangerouslySetInnerHTML={{
              __html: title
            }}
          ></Typography>
        ) : (
          <Box
            className="is-loading"
            sx={{
              py: 15,
              width: '100%',
              mb: 3
            }}
          ></Box>
        )}

        <Box>
          {!isLoading ? (
            <Typography
              component="p"
              dangerouslySetInnerHTML={{
                __html: excerpt ? excerpt : ''
              }}
            ></Typography>
          ) : (
            <Box
              className="is-loading"
              sx={{
                py: 6,
                width: '100%'
              }}
            ></Box>
          )}
        </Box>
      </CardContent>
      <CardActions disableSpacing sx={{ px: 2, width: '100%' }}>
        {!isLoading && url && !isExternalLink ? (
          <Button
            aria-label="Read More"
            component={Link}
            to={`${url}`}
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
          >
            Read More
          </Button>
        ) : !isLoading && url && isExternalLink ? (
          <Button
            aria-label="Read More"
            component={MuiLink}
            href={`${url}`}
            fullWidth
            target="_blank"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Read More
          </Button>
        ) : (
          <Box
            className="is-loading"
            sx={{
              py: 3,
              width: '100%'
            }}
          ></Box>
        )}
      </CardActions>
    </Card>
  )
}

FullCard.defaultProps = {
  categoryColor: 'primary'
}

FullCard.propTypes = {
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  excerpt: PropTypes.string,
  category: PropTypes.string,
  categoryColor: PropTypes.string,
  imageLinkUrl: PropTypes.string,
  isExternalLink: PropTypes.bool,
  mediaHeight: PropTypes.string,
  isLoading: PropTypes.bool
}
