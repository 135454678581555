import { useEffect, useRef, createRef } from 'react'
import { Link } from 'react-router-dom'
import { Container, Typography, Box, Grid, Button, Stack } from '@mui/material'
import HeroBanner from '../course/HeroBanner'
import AngledBackground from './AngledBackground'
import ResourceTypeList from './ResourceTypeList'
import ResourceHeroImage from './kidaware-resources-hero.png'

export default function Resources() {
  const headerHeight = {
    xs: '150px',
    sm: '400px',
    md: '400px',
    lg: '400px',
    xl: '400px'
  }

  const menu = [
    'News Feed',
    'FROM OUR BLOG',
    'GUIDES',
    'PODCASTS',
    'EXTERNAL RESOURCES',
    'KIDAWARE IN THE WORLD'
  ]

  const menusRef = useRef(menu.map(() => createRef()))

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <HeroBanner
        image={ResourceHeroImage}
        imageLocation="right"
        backgroundSize="contain"
        height={headerHeight}
      >
        <Box></Box>
        <Typography
          variant="h1"
          color="white"
          sx={{
            mb: 3
          }}
        >
          Resources
        </Typography>
      </HeroBanner>

      <Container component="section" maxWidth="xl">
        <Grid container justifyContent="center">
          <Grid item md={12}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              ref={menusRef.current[0]}
              sx={{
                my: { xs: 1, md: 4 },
                py: 4,
                justifyContent: 'space-between'
              }}
            >
              {menu.map((m, i) => (
                <Box
                  key={i}
                  component={Link}
                  to="#"
                  onClick={() =>
                    menusRef.current[i].current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'start'
                    })
                  }
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: { xs: 'center', md: 'start' },
                    justifyContent: 'space-between',
                    textTransform: 'uppercase',
                    pr: { xl: 3 },
                    textDecoration: 'none',
                    ':hover': {
                      color: 'secondary',
                      textDecoration: 'underline'
                    }
                  }}
                >
                  <Typography component="h6">{m}</Typography>
                </Box>
              ))}
            </Stack>

            <Box
              component="section"
              sx={{
                pt: { xs: 8, md: 0, lg: 0, xl: 0 },
                pb: { xs: 8, md: 12, lg: 13, xl: 15 }
              }}
              id="newsletters"
            >
              <Typography variant="h2" sx={{ mb: { xs: 3, md: 6, lg: 8 } }}>
                News Feed
              </Typography>
              <ResourceTypeList type="headline" page={0} limit={10} />

              <Button
                ref={menusRef.current[1]}
                aria-label="View all news feeds"
                component={Link}
                to="headline"
                variant="contained"
                sx={{ mt: 4, mb: 0, p: 2 }}
              >
                View all
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <AngledBackground>
        <>
          <Typography variant="h2" sx={{ mb: { xs: 3, md: 6, lg: 8 } }}>
            From our Blog
          </Typography>
          <ResourceTypeList type="blog" page={0} limit={3} />
          <Button
            ref={menusRef.current[2]}
            aria-label="View all blogs"
            component={Link}
            to="blog"
            variant="contained"
            sx={{ mt: 4 }}
          >
            View all
          </Button>
        </>
      </AngledBackground>

      <AngledBackground backgroundColor="white">
        <>
          <Typography variant="h2" sx={{ mb: { xs: 3, md: 6, lg: 8 } }}>
            Guides
          </Typography>

          <ResourceTypeList type="guide" page={0} limit={3} />

          <Button
            ref={menusRef.current[3]}
            aria-label="View all guides"
            component={Link}
            to="guide"
            variant="contained"
            sx={{ mt: 1 }}
          >
            View all
          </Button>
        </>
      </AngledBackground>

      <AngledBackground>
        <>
          <Typography variant="h2" sx={{ mb: { xs: 3, md: 6, lg: 8 } }}>
            #Kidtech Podcasts
          </Typography>
          <Box sx={{ pt: 4 }}>
            <ResourceTypeList type="podcast" page={0} limit={3} />
          </Box>
          <Button
            ref={menusRef.current[4]}
            aria-label="View all podcasts"
            component={Link}
            to="podcast"
            variant="contained"
            sx={{ mt: 1 }}
          >
            View all
          </Button>
        </>
      </AngledBackground>

      <AngledBackground backgroundColor="white">
        <>
          <Typography variant="h2" sx={{ mb: { xs: 3, md: 6, lg: 8 } }}>
            External Resources
          </Typography>

          <ResourceTypeList type="external-resource" page={0} limit={3} />

          <Button
            ref={menusRef.current[5]}
            aria-label="View all external resources"
            component={Link}
            to="external-resource"
            variant="contained"
            sx={{ mt: 4 }}
          >
            View all
          </Button>
        </>
      </AngledBackground>

      <AngledBackground>
        <>
          <Box component="section" sx={{ my: 4 }}>
            <Typography variant="h2" sx={{ mb: { xs: 3, md: 6, lg: 8 } }}>
              KidAware in the World
            </Typography>
            <ResourceTypeList type="kidaware-in-the-world" page={0} limit={3} />
            <Button
              aria-label="View all KidAware in the World"
              component={Link}
              to="kidaware-in-the-world"
              variant="contained"
              sx={{ mt: 4, mb: 0, p: 2 }}
            >
              View all
            </Button>
          </Box>
        </>
      </AngledBackground>
    </>
  )
}
