import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Router from './../Router'
import { useAuth } from '../account/auth'
import Nav from './Nav'
import AdminNav from '../admin/AdminNav'
import Footer from './Footer'
import { Box } from '@mui/material'

export default function Layout() {
  const location = useLocation()
  const auth = useAuth()
  const [showAdminSection, setShowAdminSection] = useState(false)

  useEffect(() => {
    let mounted = true

    if (mounted)
      setShowAdminSection(
        window.location.pathname.indexOf('/admin') === 0 && auth.isAdmin
      )

    return () => {
      mounted = false
    }
  }, [location, auth])

  useEffect(() => {
    return auth.watchAuthEvents()
  }, [auth])

  return (
    <div style={{ display: showAdminSection ? 'flex' : undefined }}>
      {showAdminSection ? (
        <AdminNav open={showAdminSection} isSuperAdmin={auth.isSuperAdmin} />
      ) : undefined}
      <Box
        sx={{
          width: '100%',
          ml: showAdminSection ? { xs: 0, xl: '-225px' } : undefined
        }}
      >
        <Nav
          authed={auth.authed}
          user={auth.user}
          isAdmin={auth.isAdmin}
          isAdminRoute={showAdminSection}
        />
        <Box sx={{ pt: showAdminSection ? 2 : 0 }}>
          <Router />
        </Box>
      </Box>
      {!showAdminSection ? <Footer /> : undefined}
    </div>
  )
}
