import { useEffect } from 'react'
import { useParams, Link as ReactLink } from 'react-router-dom'
import { Container, Typography, Box, Grid, Link } from '@mui/material'
import { useResource, useResourceLink } from './useResourceQueries.js'
import HeroBanner from '../course/HeroBanner.js'
import BreadCrumb from '../course/BreadCrumb.js'
import angleLeftSvg from './angle-left.svg'

import '../scss/theme.css'

export default function Resource() {
  const { category, slug } = useParams()

  const headerHeight = {
    xs: '100px',
    sm: '250px',
    md: '250px',
    lg: '250px',
    xl: '250px'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const { status, data } = useResource(category, slug)

  const { status: imageStatus, data: imageLink } = useResourceLink(
    status === 'success' && data[0].slug,
    'image',
    status === 'success' &&
      data[0]._links['wp:featuredmedia'] &&
      data[0]._links['wp:featuredmedia'][0].href,
    status === 'success' && data[0]._links['wp:featuredmedia']
  )

  /*const { status: authorStatus, data: author } = useResourceLink(
    status === 'success' && data[0].slug,
    'author',
    status === 'success' &&
      data[0]._links['author'] &&
      data[0]._links['author'][0].href,
    status === 'success' && data[0]._links['author']
  )*/

  const { status: categoryStatus, data: categories } = useResourceLink(
    status === 'success' && data[0].slug,
    'tags',
    status === 'success' &&
      data[0]._links['wp:term'] &&
      data[0]._links['wp:term'].find((t) => t.taxonomy === 'category') &&
      data[0]._links['wp:term'].find((t) => t.taxonomy === 'category').href,
    status === 'success' &&
      data[0]._links['wp:term'].find((t) => t.taxonomy === 'category')
  )

  return (
    <>
      <HeroBanner height={headerHeight} containerWidth="lg">
        {status === 'success' ? (
          <Typography
            variant="h1"
            color="white"
            sx={{ py: 4 }}
            dangerouslySetInnerHTML={{
              __html: data[0].title.rendered
            }}
          ></Typography>
        ) : status === 'loading' ? (
          <Box
            className="is-loading"
            sx={{
              minHeight: '80px',
              width: '350px'
            }}
          ></Box>
        ) : undefined}
        <></>
      </HeroBanner>

      <Container component="section">
        <Grid container justifyContent="center">
          <Grid item md={12}>
            <BreadCrumb />
          </Grid>
        </Grid>
      </Container>

      <Container component="section">
        <Box sx={{ my: 4 }}>
          {imageStatus === 'success' && imageLink ? (
            <>
              <img
                alt={imageLink.title.rendered}
                style={{ width: '100%', borderRadius: 4 }}
                src={
                  imageLink.media_details.sizes.large
                    ? `${imageLink.media_details.sizes['custom-thumbnail'].source_url}`
                    : `${imageLink.media_details.sizes['custom-thumbnail'].source_url}`
                }
              />
            </>
          ) : imageStatus === 'loading' &&
            status === 'success' &&
            data[0]._links['wp:featuredmedia'] ? (
            <Box sx={{ width: '100%' }}>
              <Box
                className="is-loading"
                sx={{
                  py: 50,
                  mb: 4,
                  width: '100%'
                }}
              ></Box>
            </Box>
          ) : undefined}
        </Box>
      </Container>
      <Container component="section" sx={{ mb: { xs: 17, md: 18, lg: 25 } }}>
        <Grid container justifyContent="center">
          <Grid item md={9} className="wp-content-container">
            {status === 'success' ? (
              <>
                <Typography
                  variant="topicBody"
                  dangerouslySetInnerHTML={{
                    __html: data[0].content.rendered
                  }}
                ></Typography>
              </>
            ) : status === 'loading' ? (
              <Box sx={{ width: '100%' }}>
                <Box
                  className="is-loading"
                  sx={{
                    py: 4,
                    mb: 2,
                    width: '100%'
                  }}
                ></Box>
                <Box
                  className="is-loading"
                  sx={{
                    py: 30,
                    mb: 4,
                    width: '100%'
                  }}
                ></Box>
                <Box
                  className="is-loading"
                  sx={{
                    py: 30,
                    width: '100%'
                  }}
                ></Box>
              </Box>
            ) : undefined}

            <Box sx={{ display: 'flex', mt: 3 }}>
              <Typography sx={{ fontWeight: 600 }}>Post Tags:</Typography>
              {categoryStatus === 'success' ? (
                <>
                  {categories.map((c, i) => (
                    <Typography key={i} sx={{ ml: 1 }}>
                      {c.name}
                      {i < categories.length - 1 ? <>,</> : undefined}
                    </Typography>
                  ))}
                </>
              ) : categoryStatus === 'loading' ? (
                <>
                  <Box
                    className="is-loading"
                    sx={{
                      py: 1,
                      ml: 2,
                      width: '10%'
                    }}
                  ></Box>
                  ,
                  <Box
                    className="is-loading"
                    sx={{
                      py: 1,
                      ml: 2,
                      width: '10%'
                    }}
                  ></Box>
                </>
              ) : undefined}
            </Box>

            <Link
              to={`/resources/${category}`}
              component={ReactLink}
              underline="hover"
              color="secondary"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 5 }}>
                <img width="10px" alt="angle-left-svg" src={angleLeftSvg} />
                <Typography
                  color="secondary"
                  sx={{
                    ml: 2,
                    fontWeight: 600
                  }}
                >
                  Back
                </Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
