import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Grid, Typography, Box, Link } from '@mui/material'
import ShortCard from './ShortCard'
import PodcastCard from './PodcastCard'
import FullCard from './FullCard'
import NewsFeedCard from './NewsFeedCard'

import { useResources, getResourceType } from './useResourceQueries.js'

export default function ResourceTypeList({ type, limit, page }) {
  const [typeInfo, setTypeInfo] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    setTypeInfo(getResourceType(type))
  }, [type])

  const { status, data } = useResources(type, limit, page)

  const categories = [
    { id: 3, name: 'Blog', color: 'primary' },
    { id: 4, name: 'Video', color: 'purple' },
    { id: 5, name: 'Webinar', color: 'black' }
  ]
  const mediaHeight = '200px'

  const card = (cardType, resource) => {
    const category =
      resource &&
      resource.categories &&
      categories.find((c) => c.id === resource.categories[0])

    const customFields = resource?.acf
      ? {
          ...resource.acf
        }
      : {}

    const headlineFields = resource?.source
      ? {
          ...{ source: resource.source }
        }
      : {}

    const categoryParams = category
      ? {
          category: category ? category.name : null,
          categoryColor: category ? category.color : null
        }
      : {}

    const excerptParam =
      resource && resource.acf && resource.acf.teaser
        ? {
            excerpt: resource.acf.teaser
          }
        : {
            excerpt:
              resource && resource.excerpt ? resource.excerpt.rendered : null
          }
    const channels =
      resource && resource.acf
        ? {
            channels: {
              spotify_url: resource.acf?.spotify_url,
              amazon_music_url: resource.acf?.amazon_music_url,
              sound_cloud_url: resource.acf?.sound_cloud_url
            }
          }
        : null

    const hasExternalLink =
      (resource?.acf?.link?.url || resource?.external_link) ?? null

    const url = hasExternalLink ?? `/resources/${type}/${resource?.slug}`
    const featuredImage =
      resource?._links && resource._links['wp:featuredmedia']
        ? resource._links['wp:featuredmedia'][0].href
        : null

    const coverImage =
      resource && resource?.acf?.cover_image
        ? `https://www.superawesome.com/wp-json/wp/v2/media/${resource?.acf?.cover_image}`
        : null

    const params = {
      title: resource && resource.title ? resource.title.rendered : null,
      publishedDate: resource && resource.date_gmt ? resource.date_gmt : null,
      link: url,
      isExternalLink: !!hasExternalLink,
      mediaHeight: mediaHeight,
      imageLinkUrl: coverImage ?? featuredImage,
      ...categoryParams,
      ...excerptParam,
      ...channels,
      ...customFields,
      ...headlineFields
    }

    switch (cardType) {
      case 'ShortCard':
        return <ShortCard {...params} />

      case 'PodcastCard':
        return <PodcastCard {...params} />

      case 'Guide':
        return resource ? (
          <Typography
            component={Link}
            sx={{
              textDecoration: 'none'
            }}
            target="_blank"
            href={resource?.acf?.link?.url ?? resource?.link}
          >
            <Typography
              variant="h3"
              sx={{ mb: 2 }}
              dangerouslySetInnerHTML={{
                __html: params?.title ? params.title : ''
              }}
            ></Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h6" sx={{ mb: 2, display: 'flex' }}>
                {resource?._embedded &&
                  resource?._embedded['wp:term'][0]?.map((r, i) => (
                    <Box key={i} sx={{ mr: 2 }}>
                      #{r.name}
                    </Box>
                  ))}
              </Typography>
            </Box>
          </Typography>
        ) : (
          <>
            <Box
              className="is-loading"
              sx={{
                px: 4,
                py: 3,
                mb: 2,
                width: '100%'
              }}
            ></Box>
            <Box
              className="is-loading"
              sx={{
                px: 4,
                py: 2,
                mb: 2,
                width: '100%'
              }}
            ></Box>
          </>
        )

      case 'NewsFeed':
        return <NewsFeedCard {...params} />

      case 'FullCard':
      default:
        return <FullCard {...params} />
    }
  }

  return (
    <>
      {typeInfo ? (
        <Grid
          container
          rowSpacing={{ ...typeInfo.rowSpacing }}
          columnSpacing={{ xs: 2, sm: 3, lg: 4, xl: 5 }}
        >
          {status === 'success'
            ? data.map((resource, i) => {
                return (
                  <Grid item md={typeInfo.columns?.md} key={i}>
                    {card(typeInfo.cardType, resource)}
                  </Grid>
                )
              })
            : status === 'error'
            ? [...Array(1)].map((_, i) => (
                <Grid item key={i}>
                  <Typography variant="h3" color="primary">
                    Error loading data...
                  </Typography>
                </Grid>
              ))
            : status === 'loading'
            ? [...Array(9)].map((_, i) => (
                <Grid item md={4} key={i} sx={{ mb: 4 }}>
                  {card(typeInfo.cardType)}
                </Grid>
              ))
            : undefined}
        </Grid>
      ) : (
        <>NOT FOUND</>
      )}
    </>
  )
}

ResourceTypeList.defaultProps = {
  page: 0,
  limit: 9
}

ResourceTypeList.propTypes = {
  type: PropTypes.string.isRequired,
  page: PropTypes.number,
  limit: PropTypes.number
}
