import { useEffect } from 'react'
import { fetch as apiFetch } from '../common/api'
import { useQuery } from '@tanstack/react-query'
import { Box, Typography, Container } from '@mui/material'

import '../scss/theme.css'

export default function CmsPage() {
  const useContent = (pathname) => {
    const path = pathname === '/' ? 'home' : pathname.substring(1)

    return useQuery(
      ['content', path],
      async () => await apiFetch(`/content/${path}`)
    )
  }

  function loadScriptViaPromise(src) {
    return new Promise(function (resolve, reject) {
      const s = document.createElement('script')
      s.src = src
      s.onload = resolve
      s.onerror = reject
      document.body.appendChild(s)
      return s
    })
  }

  const loadScript = (path) => {
    const script = document.createElement('script')
    script.src = path
    document.body.appendChild(script)
    return script
  }

  const { status, data, error } = useContent(window.location.pathname)
  useEffect(() => {
    let mounted = true

    if (!mounted) return

    window.scrollTo(0, 0)

    const styleSheet = document.createElement('link')
    styleSheet.setAttribute('rel', 'stylesheet')
    styleSheet.setAttribute('type', 'text/css')
    styleSheet.setAttribute(
      'href',
      `${process.env.REACT_APP_KIDAWARE_WP_API}wp-content/plugins/animate-it/assets/css/animate-animo.css`
    )
    document.body.appendChild(styleSheet)

    const newScript = document.createElement('script')
    const inlineScript = document.createTextNode(
      `/* <![CDATA[ */var edsanimate_options = {"offset":"75","hide_hz_scrollbar":"1","hide_vl_scrollbar":"0"};/* ]]> */

        if (typeof Swiper !== 'undefined')
          new Swiper('.swiper', {
            loop: true,
            effect: document.getElementById('swiper')? document.getElementById('swiper').getAttribute('effect') : 'fade',
            autoplay: {
                delay: document.getElementById('swiper')? document.getElementById('swiper').getAttribute('speed') : 2500,
                disableOnInteraction: false,
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
          });
      `
    )
    newScript.appendChild(inlineScript)
    document.body.appendChild(newScript)

    loadScriptViaPromise(
      `${process.env.REACT_APP_KIDAWARE_WP_API}wp-includes/js/jquery/jquery.min.js`
    ).then(() => {
      loadScript(
        `${process.env.REACT_APP_KIDAWARE_WP_API}wp-content/plugins/animate-it/assets/js/jquery.ba-throttle-debounce.min.js`
      )

      loadScriptViaPromise(
        `${process.env.REACT_APP_KIDAWARE_WP_API}wp-content/plugins/animate-it/assets/js/viewportchecker.js`
      ).then(() => {
        loadScript(
          `${process.env.REACT_APP_KIDAWARE_WP_API}wp-content/plugins/animate-it/assets/js/edsanimate.js`
        )

        loadScript(
          `${process.env.REACT_APP_KIDAWARE_WP_API}wp-content/plugins/animate-it/assets/js/edsanimate.site.js`
        )

        loadScript(
          `${process.env.REACT_APP_KIDAWARE_WP_API}wp-content/plugins/animate-it/assets/js/animo.min.js`
        )
      })
    })

    loadScript('https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.js')

    return () => {
      mounted = false
    }
  }, [data])

  return (
    <>
      {status === 'success' ? (
        <Box
          sx={{
            mb: { xs: 17, md: 18, lg: 25 }
          }}
          dangerouslySetInnerHTML={{
            __html: data ? data.content : '<h1>Content Missing</h1>'
          }}
        ></Box>
      ) : status === 'loading' && window.location.pathname === '/' ? (
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            my: 8
          }}
        >
          <Box
            sx={{ width: '100%', textAlign: 'center', my: { xs: 10, md: 25 } }}
          >
            <Box
              className="is-loading"
              sx={{
                py: 4,
                mb: 1,
                width: '50%',
                mx: 'auto'
              }}
            ></Box>
            <Box
              className="is-loading"
              sx={{
                py: 4,
                mb: 2,
                width: '50%',
                mx: 'auto'
              }}
            ></Box>
            <Box
              className="is-loading"
              sx={{
                py: 8,
                mb: 2,
                width: '40%',
                mx: 'auto'
              }}
            ></Box>
            <Box
              className="is-loading"
              sx={{
                py: 3,
                mb: 2,
                width: '15%',
                mx: 'auto'
              }}
            ></Box>
          </Box>
        </Container>
      ) : status === 'loading' ? (
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            my: 8
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Box
              className="is-loading"
              sx={{
                py: 4,
                mb: 2,
                width: '25%'
              }}
            ></Box>
            <Box
              className="is-loading"
              sx={{
                py: 30,
                mb: 4,
                width: '100%'
              }}
            ></Box>
            <Box
              className="is-loading"
              sx={{
                py: 30,
                width: '100%'
              }}
            ></Box>
          </Box>
        </Container>
      ) : status === 'error' ? (
        <Container>
          <Box>
            <Typography variant="h1" sx={{ mt: 8 }}>
              Error loading content
            </Typography>
            <Typography
              sx={{ minHeight: 300 }}
              dangerouslySetInnerHTML={{ __html: error }}
            ></Typography>
          </Box>
        </Container>
      ) : (
        <Container>
          <Typography variant="h1" sx={{ mt: 7 }}>
            No Content found
          </Typography>
        </Container>
      )}
    </>
  )
}
