import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import DataGridWithFiltering from '../common/DataGridWithFiltering'
import { fetch, cancel as cancelRequest, update } from '../common/api'
import Page from './Page'
import ButtonConfirm from '../common/ButtonConfirm'
import {
  Container,
  Typography,
  Box,
  Alert,
  SwipeableDrawer
} from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import SyncIcon from '@mui/icons-material/Sync'

export default function Pages() {
  const [list, setList] = useState([])
  const [listCopy, setListCopy] = useState([])
  const [showAlert, setShowAlert] = useState(null)
  const [showItem, setShowItem] = useState(false)
  const [resource, setResource] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)
  const { setNotification } = useOutletContext()

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setShowItem(open)
  }

  // eslint-disable-next-line no-unused-vars
  const handleAddPage = async (id, title, slug, status) => {
    try {
      await update(`/content/${id}`, {
        id: id,
        title: title,
        slug: slug,
        isPublished: status
      })
    } catch (e) {
      setShowAlert({ severity: 'error', message: e.message })
      console.log(e)
    }
  }

  const handleRefreshContent = async (id, page) => {
    try {
      setShowAlert(false)
      setShowAlert({ severity: 'warning', message: 'Refreshing...' })
      const update = await fetch(`/content/${id}/refresh`)

      const index = list.findIndex((i) => i.id === update.id)
      const newList = [...list]
      const newPage = { ...page, ...update }

      if (index === -1) newList.push(newPage)
      else newList.splice(index, 1, newPage)

      setList(newList)
      setListCopy(newList)

      setShowAlert({
        severity: 'success',
        message: 'Content refreshed!'
      })
    } catch (e) {
      setShowAlert({ severity: 'error', message: e.message })
      console.log(e)
    }
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      hidden: true,
      flex: 1,
      minWidth: 170
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 150,
      editable: false
    },
    {
      field: 'slug',
      headerName: 'Url',
      minWidth: 280,
      flex: 1,
      editable: false
    },
    {
      field: 'lastModified',
      headerName: 'Last udpated',
      flex: 1,
      minWidth: 250,
      editable: false
    },
    {
      field: 'isPublished',
      headerName: 'IsPublished',
      flex: 1,
      minWidth: 100,
      editable: false
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: (params) => [
        <ButtonConfirm
          key={`edit-${params.id}`}
          confirmAction={() => handleRefreshContent(params.id, params.row)}
          component={
            <GridActionsCellItem
              id={`content-refresh-confirm-${params.id}`}
              icon={<SyncIcon />}
              sx={{ color: 'primary.main' }}
              label="Refresh"
            />
          }
        >
          <>
            Please confirm you would like to Refresh the latest content from WP
            CMS
          </>
        </ButtonConfirm>
      ]
    }
  ]

  useEffect(() => {
    let mounted = true
    let request

    //handleAddPage(19, 'About Us', '%2Fabout-us', true)

    async function fetchData() {
      setDataLoading(true)
      try {
        request = fetch(`/content`)
        const content = await request

        if (mounted) {
          setList(content)
          setListCopy(content)
          setDataLoading(false)
        }
      } catch (e) {
        if (mounted) {
          setShowAlert({ severity: 'error', message: e.message })
          setDataLoading(false)
        }
        console.log(e)
      }
    }

    fetchData()

    return () => {
      mounted = false
      cancelRequest(request)
    }
  }, [])

  return (
    <>
      <Container component="main">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              textAlign: 'left',
              textTransform: 'uppercase',
              mb: 2,
              display: 'flex',
              alignContent: 'space-between'
            }}
          >
            Pages (From WordPress)
          </Typography>
        </Box>

        {showAlert ? (
          <Alert
            variant="outlined"
            severity={showAlert.severity}
            sx={{ my: 2 }}
          >
            {showAlert.message}
          </Alert>
        ) : undefined}
        <DataGridWithFiltering
          height="600px"
          rows={list}
          rowsCopy={listCopy}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          setRows={setList}
          onRowClick={({ row }) => {
            setResource(row)
            setShowItem(true)
          }}
          onCellClick={(_, event) => {
            event.defaultMuiPrevented = true
          }}
          getRowId={(row) => row.id}
          loading={dataLoading}
        />
      </Container>
      <SwipeableDrawer
        anchor="right"
        open={showItem}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{ p: 4 }}>
          {resource ? (
            <Page
              current={resource}
              onClose={toggleDrawer(false)}
              onSuccess={(item) => {
                const index = list.findIndex((i) => i.id === item.id)
                const newList = [...list]

                if (index === -1) newList.push(item)
                else newList.splice(index, 1, item)

                setList(newList)
                setNotification({
                  open: true,
                  message: 'Page has been saved',
                  severity: 'success'
                })
              }}
            />
          ) : undefined}
        </Box>
      </SwipeableDrawer>
    </>
  )
}
