import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import DataGridWithFiltering from '../common/DataGridWithFiltering'
import { fetch, cancel as cancelRequest, destroy } from '../common/api'
import Resource from './Resource'
import ButtonConfirm from '../common/ButtonConfirm'
import {
  Container,
  Typography,
  Box,
  Alert,
  SwipeableDrawer,
  Button
} from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

export default function Resources() {
  const [list, setList] = useState([])
  const [listCopy, setListCopy] = useState([])
  const [showAlert, setShowAlert] = useState(null)
  const [showItem, setShowItem] = useState(false)
  const [resource, setResource] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)
  const { setNotification } = useOutletContext()

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setShowItem(open)
  }

  const handleRemoveResource = async (id) => {
    try {
      setShowAlert(false)
      await destroy(`/resources/${id}`)

      const index = list.findIndex((r) => r.id === id)
      const newList = [...list]
      newList.splice(index, 1)
      setList(newList)
      setListCopy(newList)

      setShowAlert({
        severity: 'success',
        message: 'Resource deleted!'
      })
    } catch (e) {
      setShowAlert({ severity: 'error', message: e.message })
      console.log(e)
    }
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      hidden: true,
      flex: 1,
      minWidth: 170
    },
    {
      field: 'type',
      headerName: 'Wordpress Type',
      width: 150,
      editable: false
    },
    {
      field: 'key',
      headerName: 'Option',
      minWidth: 280,
      flex: 1,
      editable: false
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      minWidth: 450,
      editable: false
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: (params) => [
        <ButtonConfirm
          key={`edit-${params.id}`}
          confirmAction={() => handleRemoveResource(params.id)}
          component={
            <GridActionsCellItem
              id={`resource-delete-confirm-${params.id}`}
              icon={<DeleteIcon />}
              sx={{ color: 'primary.main' }}
              label="Delete"
            />
          }
        >
          <>Please confirm if you would like to delete the resource.</>
        </ButtonConfirm>
      ]
    }
  ]

  useEffect(() => {
    let mounted = true
    let request

    async function fetchData() {
      setDataLoading(true)
      try {
        request = fetch(`/resources?`)
        const resources = await request

        if (mounted) {
          setList(resources)
          setListCopy(resources)
          setDataLoading(false)
        }
      } catch (e) {
        if (mounted) {
          setShowAlert({ severity: 'error', message: e.message })
          setDataLoading(false)
        }
        console.log(e)
      }
    }

    fetchData()

    return () => {
      mounted = false
      cancelRequest(request)
    }
  }, [])

  return (
    <>
      <Container component="main">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              textAlign: 'left',
              textTransform: 'uppercase',
              mb: 2,
              display: 'flex',
              alignContent: 'space-between'
            }}
          >
            Resources
          </Typography>
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              endIcon={<AddIcon />}
              onClick={() => {
                setResource({
                  key: '',
                  type: '',
                  value: ''
                })
                setShowItem(true)
              }}
            >
              Add
            </Button>
          </Box>
        </Box>

        {showAlert ? (
          <Alert
            variant="outlined"
            severity={showAlert.severity}
            sx={{ my: 2 }}
          >
            {showAlert.message}
          </Alert>
        ) : undefined}
        <DataGridWithFiltering
          height="600px"
          rows={list}
          rowsCopy={listCopy}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          setRows={setList}
          onRowClick={({ row }) => {
            setResource(row)
            setShowItem(true)
          }}
          onCellClick={(_, event) => {
            event.defaultMuiPrevented = true
          }}
          getRowId={(row) => row.id}
          loading={dataLoading}
        />
      </Container>
      <SwipeableDrawer
        anchor="right"
        open={showItem}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{ p: 4 }}>
          {resource ? (
            <Resource
              current={resource}
              onClose={toggleDrawer(false)}
              onSuccess={(item) => {
                const index = list.findIndex((i) => i.id === item.id)
                const newList = [...list]

                if (index === -1) newList.push(item)
                else newList.splice(index, 1, item)

                setList(newList)
                setNotification({
                  open: true,
                  message: 'Rersource has been saved',
                  severity: 'success'
                })
              }}
            />
          ) : undefined}
        </Box>
      </SwipeableDrawer>
    </>
  )
}
