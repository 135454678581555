import { Card, CardContent, CardActions, Button, Box } from '@mui/material'
import './Course.scss'

export default function LoadingCourseCard() {
  return (
    <>
      <Card
        className="course-card"
        elevation={3}
        sx={{ height: '100%', display: 'flex', flexFlow: 'wrap' }}
      >
        <Box
          className="is-loading"
          sx={{
            display: 'flex',
            px: 4,
            py: 6,
            minHeight: 194,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}
        ></Box>
        <CardContent sx={{ textAlign: 'left', width: '100%' }}>
          <Box
            className="is-loading"
            sx={{
              mb: 2,
              p: 2,
              width: '100%',
              borderRadius: 2
            }}
          ></Box>
          <Box
            className="is-loading"
            sx={{
              mb: 2,
              p: 4,
              width: '100%',
              borderRadius: 2
            }}
          ></Box>
        </CardContent>
        <CardActions
          disableSpacing
          sx={{ p: 2, width: '100%', alignSelf: 'self-end', opacity: 0.25 }}
        >
          <Button
            size="small"
            color="primary"
            variant="outlined"
            disabled
            sx={{
              textTransform: 'initial',
              width: 110,
              boxShadow:
                '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
            }}
            aria-label="course action"
          >
            Start
          </Button>
        </CardActions>
      </Card>
    </>
  )
}
