import { Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

function CourseHeader(props) {
  const [authed, setAuthed] = useState(false)
  const [user, setUser] = useState({})
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isApproved, setIsApproved] = useState(true)
  const [isWhitelisted, setIsWhitelisted] = useState(true)

  useEffect(() => {
    let mounted = true

    if (mounted) {
      setAuthed(props.authed)
      setUser(props.user)
      setIsConfirmed(props.authed && props?.user?.email_verified)
      setIsApproved(props.authed && (props.user?.is_approved ?? false))
      setIsWhitelisted(props.authed && (props.user?.is_whitelisted ?? false))
    }

    return () => {
      mounted = false
    }
  }, [props])

  return (
    <Box sx={{ mb: { xs: 17, md: 18, lg: 25 } }}>
      <Outlet
        context={{ authed, user, isConfirmed, isApproved, isWhitelisted }}
      />
    </Box>
  )
}

export default CourseHeader

CourseHeader.propTypes = {
  authed: PropTypes.bool,
  user: PropTypes.object
}

CourseHeader.defaultProps = {
  authed: false,
  user: null
}
