import { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { EditorState, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import DOMPurify from 'dompurify'
import GlobalStyles from '@mui/material/GlobalStyles'
import TopicEditorSaveButton from './TopicEditorSaveButton'
import TopicEditorColorPicker from './TopicEditorColorPicker'
import TopicEditorRemoveButton from './TopicEditorRemoveButton'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
export default function TopicEditor({
  attachment,
  handleSave,
  index,
  handleRemove,
  handleImageUplaod
}) {
  const formatHTml = (html) =>
    html
      .replace(/data-imageloader-src/g, 'src')
      .replace(/data-imageloader=""/g, 'alt="topic-image"')
      //.replace(/^([^.]*.[^>]*)(.*)$/g, '$1/$2')
      .replace(/class="ql-align-center"/g, 'style="text-align:center"')

  const [editorState, setEditorState] = useState(() => {
    const formatedHtml = DOMPurify.sanitize(formatHTml(attachment.html))
    const contentBlock = htmlToDraft(formatedHtml)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      return EditorState.createWithContent(contentState)
    }
  })

  const editor = useRef(`editor-${index}`)

  useEffect(() => {
    let mounted = true

    const formatedHtml = DOMPurify.sanitize(formatHTml(attachment.html))
    const contentBlock = htmlToDraft(formatedHtml)
    if (contentBlock && mounted) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      EditorState.createWithContent(contentState)
      setEditorState(EditorState.createWithContent(contentState))
    }

    return () => {
      mounted = false
    }
  }, [attachment.html, index])

  return (
    <>
      {attachment && attachment.html ? (
        <div
          style={{
            border: '1px solid black',
            minHeight: '6em',
            padding: '15px',
            cursor: 'text'
          }}
        >
          <GlobalStyles
            styles={(theme) => {
              return {
                ...theme.typography,
                ...{
                  body: theme.typography.topicBody,
                  'img.data-imageloader': {
                    height: 'auto',
                    width: '100%'
                  },
                  '.rdw-image-imagewrapper img': {
                    width: '100%'
                  },
                  figure: {
                    display: 'table-cell'
                  },
                  a: {
                    color: theme.palette.primary.main
                  }
                }
              }
            }}
          />
          <Editor
            ref={editor}
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              options: [
                'inline',
                'blockType',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'image',
                'history'
              ],
              colorPicker: { component: TopicEditorColorPicker },
              image: {
                uploadCallback: handleImageUplaod,
                inputAccept:
                  'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                previewImage: true,
                alt: { present: true, mandatory: false },
                defaultSize: {
                  height: 'auto',
                  width: 'auto'
                }
              }
            }}
            toolbarCustomButtons={[
              <TopicEditorSaveButton
                key={0}
                attachment={attachment}
                handleSave={handleSave}
              />,
              <TopicEditorRemoveButton
                key={1}
                index={index}
                attachment={attachment}
                handleRemove={handleRemove}
              />
            ]}
          />
        </div>
      ) : undefined}
    </>
  )
}

TopicEditor.propTypes = {
  attachment: PropTypes.object.isRequired,
  handleSave: PropTypes.func,
  handleRemove: PropTypes.func,
  handleImageUplaod: PropTypes.func,
  index: PropTypes.number
}
