import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetch, update } from '../common/api'
import { useAuth } from './auth'
import LearningPathSelector from '../admin/LearningPathSelector'
import ForgotPasswordForm from '../account/ForgotPasswordForm'
import ButtonModal from '../common/ButtonModal'
import {
  Box,
  Grid,
  Button,
  Alert,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@mui/material'

function AccountForm({ subId, user, onSuccess, onClose, allowResetPassword }) {
  const auth = useAuth()
  const queryClient = useQueryClient()
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [showAlert, setShowAlert] = useState(null)
  const [orignalEmail, setOrignalEmail] = useState(null)
  const [originalApprovalStatus, setOriginalApprovalStatus] = useState(null)
  const [communicateConsentChecked, setCommunicateConsent] = useState(false)
  const [readOnlyAccess, setReadOnlyAccess] = useState(false)
  const [userAccount, setUserAccount] = useState({
    userId: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    companyName: '',
    userRole: 'student',
    userStatus: 'activated',
    newsletterConsent: '',
    communicateConsent: '',
    isApproved: false
  })

  const handleChange = (prop) => (event) => {
    updateAccountState({ [prop]: event.target.value })
  }

  const handleCheckBoxChange = (prop) => (event) => {
    if (prop === 'communicateConsent')
      setCommunicateConsent(event.target.checked)

    updateAccountState({ [prop]: event.target.checked ? 'true' : 'false' })
  }

  const updateAccountState = (newAccount) => {
    setUserAccount({
      ...userAccount,
      ...newAccount
    })

    if (subId)
      queryClient.setQueryData(['user', subId], {
        ...account,
        ...newAccount
      })
  }

  const cleanPayload = (account) => {
    const payload = { ...account }

    if (!auth.isAdmin || auth?.user?.sub === userAccount?.userId) {
      delete payload.userStatus
      delete payload.userType
    }

    if (
      payload.userRole !== 'student' ||
      auth?.user?.sub === userAccount?.userId
    )
      delete payload.userRole

    if (orignalEmail === account.emailAddress) delete payload.emailAddress
    if (originalApprovalStatus === account.isApproved) delete payload.isApproved

    return {
      ...payload,
      ...(orignalEmail !== account.emailAddress
        ? { emailAddress: payload.emailAddress.trim() }
        : null),
      ...(originalApprovalStatus !== account.isApproved
        ? { isApproved: payload.isApproved }
        : null),
      userName: orignalEmail,
      firstName: payload.firstName.trim(),
      lastName: payload.lastName.trim(),
      companyName: payload.companyName ? payload.companyName.trim() : ''
    }
  }

  const handleLearningPathSelect = (list) => {
    updateAccountState({ learningPathIds: list.map((p) => p.id) })
  }

  const handleForceTempPassReset = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    setSubmitButtonDisabled(true)
    setShowAlert(false)

    try {
      const userId = subId ? subId : user.userId
      const payload = {
        emailAddress: orignalEmail,
        userName: orignalEmail,
        firstName: userAccount.firstName.trim(),
        lastName: userAccount.lastName.trim(),
        resendTemporaryPassword: true
      }

      await update(`/users/${userId}`, payload)

      setSubmitButtonDisabled(false)
      setShowAlert({
        severity: 'success',
        message: 'Account temp password has been reset!'
      })
    } catch (e) {
      setSubmitButtonDisabled(false)
      setShowAlert({ severity: 'error', message: e.message })
      console.error(e)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    setSubmitButtonDisabled(true)
    setShowAlert(false)

    try {
      const userId = subId ? subId : user.userId
      const payload = cleanPayload(userAccount)

      await update(`/users/${userId}`, payload)
      if (onSuccess && typeof onSuccess === 'function') onSuccess(userAccount)

      setSubmitButtonDisabled(false)
      setShowAlert({
        severity: 'success',
        message: 'Account information saved!'
      })
    } catch (e) {
      setSubmitButtonDisabled(false)
      setShowAlert({ severity: 'error', message: e.message })
      console.error(e)
    }
  }

  const getCheckedValue = (value) => {
    return value === 'true' ? true : false
  }

  const useAccount = (userId) => {
    return useQuery({
      queryKey: ['user', userId],
      queryFn: async () => {
        return await fetch(`/users/${userId}`)
      },
      onSuccess: (account) => {
        const preparedAccount = {
          ...account,
          ...(!account.companyName ? { companyName: '' } : null),
          ...(!account.isApproved
            ? { isApproved: false }
            : { isApproved: true }),
          ...{
            communicateConsent:
              account.communicateConsent === undefined
                ? ''
                : account.communicateConsent,
            newsletterConsent:
              account.newsletterConsent === undefined
                ? ''
                : account.newsletterConsent
          }
        }

        queryClient.setQueryData(['user', { userId }], preparedAccount)
        setUserAccount(preparedAccount)
        setReadOnlyAccess(
          (auth?.user?.is_okta && auth?.user?.sub === userId) ||
            (!auth.isAdmin &&
              !preparedAccount.isWhitelisted &&
              !preparedAccount.isApproved)
        )
        setCommunicateConsent(getCheckedValue(account.communicateConsent))
        setSubmitButtonDisabled(false)
        setOrignalEmail(preparedAccount.emailAddress)
        setOriginalApprovalStatus(preparedAccount.isApproved)
      },
      enabled: !!subId
    })
  }

  const { status, data: account, error } = useAccount(subId)

  const getAccountField = (field) => userAccount[field]

  useEffect(() => {
    let mounted = true

    if (user && mounted) {
      setUserAccount(user)
      setReadOnlyAccess(auth?.user?.is_okta && auth?.user?.sub === subId)
      setOrignalEmail(user.emailAddress)
      setOriginalApprovalStatus(user.isApproved)
      setCommunicateConsent(getCheckedValue(user.communicateConsent))
      setSubmitButtonDisabled(false)
    }
    return () => {
      mounted = false
    }
  }, [subId, user, auth?.user])

  return (
    <>
      {showAlert ? (
        <Alert variant="outlined" severity={showAlert.severity} sx={{ my: 2 }}>
          {showAlert.message}
        </Alert>
      ) : undefined}

      {userAccount.userId || status === 'success' ? (
        <Box component="form" onSubmit={handleSubmit} autoComplete="off">
          <Grid container rowSpacing={0} columnSpacing={{ xs: 0, lg: 3 }}>
            <Grid item xs={12} lg={6}>
              <Box>
                {auth.isAdmin && auth?.user?.sub !== userAccount?.userId ? (
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, lg: 3 }}
                  >
                    <Grid item xs={12} lg={6}>
                      <FormControl fullWidth variant="filled" margin="normal">
                        <InputLabel id="userStatus-label">
                          User Status
                        </InputLabel>
                        <Select
                          labelId="userStatus-label"
                          id="userStatus"
                          value={getAccountField('userStatus')}
                          onChange={handleChange('userStatus')}
                          label="User Status"
                        >
                          <MenuItem value="activated">Active</MenuItem>
                          <MenuItem value="deactivated">Deactivated</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {getAccountField('isWhitelisted') ? (
                        <FormControl fullWidth variant="filled" margin="normal">
                          <InputLabel id="isWhitelisted-label">
                            Whitelisted
                          </InputLabel>
                          <Select
                            labelId="isWhitelisted-label"
                            id="isApproved"
                            value={getAccountField('isWhitelisted')}
                            label="Is Whitelisted"
                            readOnly
                            disabled
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                          </Select>
                        </FormControl>
                      ) : (
                        <FormControl fullWidth variant="filled" margin="normal">
                          <InputLabel id="isApproved-label">
                            Is Approved
                          </InputLabel>
                          <Select
                            labelId="isApproved-label"
                            id="isApproved"
                            value={getAccountField('isApproved')}
                            onChange={handleChange('isApproved')}
                            label="Is User Approved"
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                ) : undefined}
                <FormControl fullWidth margin="normal" variant="filled">
                  <InputLabel shrink htmlFor="firstName">
                    First Name*
                  </InputLabel>
                  <OutlinedInput
                    id="firstName"
                    name="firstName"
                    placeholder="Please enter your name*"
                    required
                    onChange={handleChange('firstName')}
                    value={getAccountField('firstName')}
                    readOnly={readOnlyAccess}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal" variant="filled">
                  <InputLabel shrink htmlFor="emailAddress">
                    Email
                  </InputLabel>
                  <OutlinedInput
                    id="emailAddress"
                    name="emailAddress"
                    type="email"
                    placeholder="Please enter your email*"
                    required
                    onChange={handleChange('emailAddress')}
                    value={getAccountField('emailAddress')}
                    readOnly={readOnlyAccess}
                  />
                </FormControl>

                {auth.isAdmin && auth?.user?.sub !== userAccount?.userId ? (
                  <>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleForceTempPassReset}
                      disabled={submitButtonDisabled}
                      sx={{ my: 2 }}
                    >
                      Force Temp Password Reset
                    </Button>
                  </>
                ) : undefined}
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box>
                {auth.isAdmin && auth?.user?.sub !== userAccount?.userId ? (
                  <FormControl fullWidth variant="filled" margin="normal">
                    <InputLabel id="userRole-label">User Role</InputLabel>
                    <Select
                      labelId="userRole-label"
                      id="userRole"
                      value={getAccountField('userRole')}
                      disabled
                      onChange={handleChange('userRole')}
                      label="User Role"
                    >
                      <MenuItem value="student">Student</MenuItem>
                      <MenuItem value="administrator">Administrator</MenuItem>
                      <MenuItem value="super_administrator">
                        Super Administrator
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : undefined}
                <FormControl fullWidth margin="normal" variant="filled">
                  <InputLabel shrink htmlFor="lastName">
                    Last Name*
                  </InputLabel>
                  <OutlinedInput
                    id="lastName"
                    name="lastName"
                    placeholder="Please enter your last name"
                    required
                    onChange={handleChange('lastName')}
                    value={getAccountField('lastName')}
                    readOnly={readOnlyAccess}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal" variant="filled">
                  <InputLabel shrink htmlFor="companyName">
                    Company
                  </InputLabel>
                  <OutlinedInput
                    id="companyName"
                    name="companyName"
                    placeholder="Please enter your company"
                    onChange={handleChange('companyName')}
                    value={getAccountField('companyName')}
                    readOnly={readOnlyAccess}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {auth.isAdmin && !readOnlyAccess ? (
            <Box sx={{ mb: 2 }}>
              <LearningPathSelector
                sx={{ mb: 2 }}
                onSelect={handleLearningPathSelect}
                initialList={getAccountField('learningPathIds')}
              />
            </Box>
          ) : undefined}
          <Box sx={{ mt: { xs: 1, sm: 0 } }}>
            <FormControlLabel
              componentsProps={{ typography: { variant: 'subtitle2' } }}
              control={
                <Checkbox
                  name="communicateConsent"
                  checked={communicateConsentChecked}
                  onChange={handleCheckBoxChange('communicateConsent')}
                  disabled={readOnlyAccess}
                />
              }
              label="I consent to receive email communication about other SuperAwesome products and services. (optional)"
            />
          </Box>

          {getAccountField('userRole') === 'student' && allowResetPassword ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Typography variant="h5">
                To reset your password click:
              </Typography>
              <ButtonModal
                component={
                  <Button
                    id="reset-password"
                    color="secondary"
                    variant="contained"
                    sx={{ ml: 2 }}
                  >
                    Reset your Password
                  </Button>
                }
              >
                <ForgotPasswordForm emailDefault={userAccount?.emailAddress} />
              </ButtonModal>
            </Box>
          ) : undefined}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'end',
              mt: 3
            }}
          >
            {onClose ? (
              <Button
                onClick={onClose}
                variant="outlined"
                color="warning"
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
            ) : undefined}
            <Button
              type="submit"
              variant="contained"
              disabled={submitButtonDisabled || readOnlyAccess}
            >
              Update
            </Button>
          </Box>
        </Box>
      ) : status === 'error' ? (
        <span>Error: {error.message}</span>
      ) : (
        <>
          <Box
            className="is-loading"
            sx={{
              minHeight: '70px',
              width: '100%',
              mb: 3
            }}
          ></Box>
          <Box
            className="is-loading"
            sx={{
              minHeight: '70px',
              width: '100%',
              mb: 2
            }}
          ></Box>
          <Box
            className="is-loading"
            sx={{
              minHeight: '70px',
              width: '100%',
              mb: 2
            }}
          ></Box>

          <Box
            className="is-loading"
            sx={{
              minHeight: '70px',
              width: '60%',
              mb: 2
            }}
          ></Box>
        </>
      )}
    </>
  )
}

AccountForm.propTypes = {
  subId: PropTypes.string,
  user: PropTypes.object,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  allowResetPassword: PropTypes.bool
}

AccountForm.defaultProps = {
  subId: null,
  user: null,
  allowResetPassword: true
}

export default AccountForm
