import PropTypes from 'prop-types'
import { Box, Container } from '@mui/material'

export default function AngledBackground({
  children,
  backgroundColor,
  maxWidth
}) {
  const backgroundClass = `background-${backgroundColor}`

  return (
    <Box
      component="section"
      className={`brand-background ${backgroundClass}`}
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        my: 4,
        py: { xs: 8, md: 12, lg: 13, xl: 15 }
      }}
    >
      <Container maxWidth={maxWidth}>{children}</Container>
    </Box>
  )
}

AngledBackground.defaultProps = {
  maxWidth: 'xl',
  backgroundColor: 'concrete'
}

AngledBackground.propTypes = {
  children: PropTypes.element.isRequired,
  backgroundColor: PropTypes.string,
  maxWidth: PropTypes.string
}
