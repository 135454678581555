import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Storage } from 'aws-amplify'

export default function TopicVideo({ attachment, onEnded }) {
  const [file, setFile] = useState('')

  const onVideoEnd = () => {
    if (onEnded && typeof onEnded === 'function') onEnded()
  }

  useEffect(() => {
    let mounted = true

    const getAttachment = async () => {
      if (!attachment.uri) return

      if (attachment.amplified) {
        setFile(attachment.uri)
        return
      }

      const uri = attachment.uri
        .replace(`${process.env.REACT_APP_ASSETS_S3_URL}/`, '')
        .replace(
          'https://staging-kidaware-assets.s3.us-east-1.amazonaws.com/public/',
          ''
        )
        .replace(`public/`, '')

      const r = await Storage.get(uri)
      setFile(r)
    }

    if (mounted) getAttachment()

    return () => {
      mounted = false
    }
  }, [attachment.uri, attachment.amplified])

  return (
    <>
      <video
        onEnded={onVideoEnd}
        className="MuiCardMedia-root MuiCardMedia-media css-e6k82g-MuiCardMedia-root"
        src={file}
        style={{
          maxWidth: 800,
          width: '100%',
          height: 'auto'
        }}
        controls
      >
        <source src={file} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  )
}

TopicVideo.propTypes = {
  attachment: PropTypes.object.isRequired,
  onEnded: PropTypes.func
}
