import palette from './scss/_palette_variables.module.scss'
import typography from './scss/_typography_variables.module.scss'
import { createTheme } from '@mui/material/styles'

const config = {
  breakpoints: {
    unit: 'px',
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: parseInt(typography.breakpointXs),
      sm: parseInt(typography.breakpointSm),
      md: parseInt(typography.breakpointMd),
      lg: parseInt(typography.breakpointLg),
      xl: parseInt(typography.breakpointXl)
    }
  },
  typography: {
    fontFamily: [typography.fontFamily].join(','),
    superLarge: {
      fontFamily: [typography.headerFontFamily].join(','),
      fontWeight: 700,
      fontSize: '3.125rem',
      lineHeight: 1.2
    },
    h1: {
      fontFamily: [typography.headerFontFamily].join(','),
      fontWeight: typography.headerFontWeight,
      fontSize: typography.headingH1FontSize
    },
    h2: {
      fontFamily: [typography.headerFontFamily].join(','),
      fontWeight: typography.headerFontWeight,
      fontSize: typography.headingH2FontSize
    },
    h3: {
      fontFamily: [typography.headerFontFamily].join(','),
      fontWeight: typography.headerFontWeight,
      fontSize: typography.headingH3FontSize
    },
    h4: {
      fontFamily: [typography.headerFontFamily].join(','),
      fontWeight: typography.headerFontWeight,
      fontSize: typography.headingH4FontSize
    },
    h5: {
      fontFamily: [typography.headerFontFamily].join(','),
      fontWeight: typography.headerFontWeight,
      fontSize: typography.headingH5FontSize
    },
    h6: {
      fontFamily: [typography.headerFontFamily].join(','),
      fontWeight: typography.headerFontWeight,
      color: '#141414',
      fontSize: typography.headingH6FontSize
    },
    eyebrow: {
      fontFamily: ['open-sans', 'sans-serif'].join(','),
      fontSize: '1.8rem',
      fontWeight: 400
    },
    cardBigTitle: {
      fontFamily: [typography.headerFontFamily].join(','),
      fontSize: '1.86rem !important',
      fontWeight: 600
    },
    button: {
      fontFamily: [typography.headerFontFamily].join(','),
      fontWeight: 600,
      fontSize: '1rem',
      textTransform: 'uppercase !important'
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 100,
      color: '#4f4f4f'
    },
    body2: {
      fontSize: '.9rem',
      fontWeight: 100,
      color: '#4f4f4f !important'
    },
    topicBody: {
      fontSize: '1.125rem',
      fontWeight: 100,
      color: '#4f4f4f !important'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: typography.borderRadius,
          backgroundColor: '#111111',
          paddingTop: '8px !important',
          paddingRight: '24px !important',
          paddingBottom: '3px !important',
          paddingLeft: '24px !important',
          '&:active': {
            backgroundColor: '#111111'
          }
        },
        outlined: {
          borderRadius: typography.borderRadius,
          paddingTop: '8px !important',
          paddingRight: '24px !important',
          paddingBottom: '3px !important',
          paddingLeft: '24px !important'
        }
      }
    }
  },
  palette: {
    success: {
      main: palette.successMain
    },
    primary: {
      main: palette.primaryMain
    },
    secondary: {
      main: palette.secondaryMain,
      dark: palette.secondaryDark,
      light: palette.secondaryLight
    },
    secondaryDark: {
      main: palette.secondaryDark,
      contrastText: '#fff'
    },
    secondaryLight: {
      main: palette.secondaryLight
    },
    trinary: {
      main: palette.trinaryMain,
      light: palette.trinaryLight
    },
    whiteButton: {
      main: palette.whiteButtonMain
    },
    black: {
      main: palette.blackMain,
      contrastText: '#fff'
    },
    purple: {
      main: palette.purpleMain,
      dark: palette.purpleDark,
      contrastText: '#fff'
    },
    darkPurple: {
      main: palette.darkPurpleMain,
      contrastText: '#fff'
    },
    concrete: {
      main: palette.concreteMain
    },
    alto: {
      main: '#D0D0D0'
    },
    coolGray: {
      main: '#141414'
    },
    secondaryMalibu: {
      main: '#7CA8FF'
    },
    secondaryChathamsBlue: {
      main: '#133780'
    },
    secondaryLavenderPurple: {
      main: '#907BAF'
    },
    secondaryHaiti: {
      main: '#23123D'
    },
    secondaryHopbush: {
      main: '#C6669B'
    },
    secondaryBlackberry: {
      main: '#50002D'
    },
    tertiaryCerulean: {
      main: '#069CD4'
    },
    tertiaryTurquoiseBlue: {
      main: '#6AC4E5'
    },
    tertiaryRegalBlue: {
      main: '#034E6A'
    },
    tertiaryPurpleHeart: {
      main: '#7E1DDB'
    },
    tertiaryLavender: {
      main: '#B277E9'
    },
    tertiaryPersianIndigo: {
      main: '#3F0F6E'
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
}

export default createTheme(config)
