import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { cancel as cancelRequest, fetch } from '../common/api'
import { Auth } from 'aws-amplify'
import AccountForm from './AccountForm'
import AccountEnrollment from './AccountEnrollment'
import HeroBanner from '../course/HeroBanner'
import { Typography, Container, Box, Chip } from '@mui/material'

function MyProfile({ user }) {
  const queryClient = useQueryClient()
  const [subId, setSubId] = useState(null)
  const [tab, setTab] = useState(1)
  const headerHeight = {
    xs: '200px',
    md: '200px',
    lg: '200px',
    xl: '200px'
  }

  if (subId)
    queryClient.getQueryData(['userEnrollments', subId]) ??
      queryClient.prefetchQuery(
        ['userEnrollments', subId],
        async () => await fetch(`/users/${subId}/courses`)
      )

  useEffect(() => {
    let mounted = true
    let authUserRequest

    async function setData() {
      try {
        let sub = user ? user.sub : null
        authUserRequest = Auth.currentSession()
        const { idToken } = await authUserRequest
        if (mounted) {
          sub = idToken.payload.sub
          setSubId(sub)
        }
      } catch (e) {
        //setShowAlert(e.message)
        console.log(e)
      }
    }

    setData()
    return () => {
      mounted = false
      cancelRequest(authUserRequest)
    }
  }, [user])

  return (
    <>
      <HeroBanner height={headerHeight} containerWidth="lg">
        <Typography variant="h1" color="white">
          My Profile
        </Typography>
        <></>
      </HeroBanner>

      <Container component="main" sx={{ mb: { xs: 17, md: 18, lg: 25 } }}>
        <Box sx={{ mb: 2 }}>
          <Chip
            label="My details"
            clickable
            variant={tab === 1 ? 'filled' : 'outlined'}
            onClick={() => setTab(1)}
            sx={{ mr: 2 }}
          />
          <Chip
            label="My enrollments"
            variant={tab === 2 ? 'filled' : 'outlined'}
            clickable
            onClick={() => setTab(2)}
          />
        </Box>

        {tab === 1 ? (
          <AccountForm subId={subId} />
        ) : (
          <AccountEnrollment subId={subId}></AccountEnrollment>
        )}
      </Container>
    </>
  )
}

MyProfile.propTypes = {
  user: PropTypes.object
}

export default MyProfile
