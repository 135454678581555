import { Container, Box, Grid } from '@mui/material'
import PropTypes from 'prop-types'

export default function HeroBanner({
  children,
  image,
  imageLocation,
  height,
  containerWidth,
  backgroundColor,
  backgroundSize,
  paddingBottom
}) {
  return (
    <Box
      sx={{
        pb: paddingBottom
      }}
    >
      <Box
        sx={{
          position: 'relative'
        }}
      >
        <Grid
          container
          columnSpacing={2}
          rowSpacing={0}
          direction={imageLocation === 'right' ? 'row' : 'row-reverse'}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              minHeight: height,
              '&::after': {
                content: "''",
                position: 'absolute',
                backgroundColor: backgroundColor,
                width: '100%',
                height: '100%',
                ...(imageLocation === 'right' ? { left: 0 } : { right: 0 }),
                top: '25%',
                transform: 'skewY(-3deg)',
                zIndex: -1
              }
            }}
          ></Grid>
          <Grid item xs={0} md={4}></Grid>
        </Grid>
        <Box
          sx={{
            minHeight: height,
            top: 0,
            backgroundColor: backgroundColor,
            position: 'absolute',
            width: '100%'
          }}
        ></Box>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            minHeight: height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            mt: { xs: 5, sm: 6 }
          }}
        >
          <Container component="main" maxWidth={containerWidth}>
            <Grid
              container
              rowSpacing={0}
              columnSpacing={{ xs: 2 }}
              direction={imageLocation === 'right' ? 'row' : 'row-reverse'}
              sx={{
                justifyContent: 'space-between'
              }}
            >
              <Grid
                item
                xs={12}
                sm={image != null ? 10 : 12}
                md={image != null ? 6 : 10}
                sx={{
                  minHeight: height,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Box>{children}</Box>
              </Grid>
              <Grid item xs={0} md={1}></Grid>
              {image != null ? (
                <Grid item xs={0} md={5}>
                  {image ? (
                    <Box
                      sx={{
                        backgroundImage: `url(${image})`,
                        backgroundRepeat: 'no-repeat',
                        height: '100%',
                        width: '100%',
                        backgroundSize: backgroundSize,
                        backgroundPosition: 'center center',
                        '&::after': {
                          content: "''",
                          position: 'absolute',
                          height: '50px',
                          width: '100%',
                          backgroundColor: backgroundColor,
                          bottom: '-58px',
                          left: 0,
                          transform: 'rotate(-3deg)',
                          display: { xs: 'none', md: 'flex' }
                        }
                      }}
                    ></Box>
                  ) : (
                    <Box
                      className="is-loading"
                      sx={{
                        minHeight: '100%',
                        width: '100%'
                      }}
                    ></Box>
                  )}
                </Grid>
              ) : undefined}
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

HeroBanner.propTypes = {
  children: PropTypes.array,
  image: PropTypes.string,
  imageLocation: PropTypes.string,
  height: PropTypes.object,
  containerWidth: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundSize: PropTypes.string,
  paddingBottom: PropTypes.object
}

HeroBanner.defaultProps = {
  imageLocation: 'right',
  containerWidth: 'xl',
  height: {
    xs: '397px',
    md: '397px',
    lg: '497px',
    xl: '597px'
  },
  backgroundColor: 'secondary.main',
  backgroundSize: 'cover',
  paddingBottom: {
    xs: '95px',
    sm: '120px',
    md: '120px',
    lg: '115px',
    xl: '140px'
  }
}
