import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { fetch } from '../common/api'
import {
  Typography,
  Container,
  Box,
  Grid,
  Button,
  Paper,
  TextField
} from '@mui/material'
import HeroBanner from './HeroBanner'
import BreadCrumb from './BreadCrumb'
import './Course.scss'

export default function CourseSearchResults() {
  const [searchParams] = useSearchParams()
  const [searchText, setSearchText] = useState('')
  const headerHeight = {
    xs: '297px',
    md: '297px',
    lg: '297px',
    xl: '397px'
  }

  const buildCourseResults = (results) => {
    let courseId = ''
    return results.map((r, i) => {
      const header =
        courseId !== r.courseId ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 4,
              mb: 2
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h3"
                sx={{
                  pt: 1
                }}
              >
                {r.courseName}
              </Typography>
            </Box>
            <Button
              component={Link}
              to={`${r.courseLink}`}
              target="_blank"
              color="primary"
              size="small"
              variant="contained"
              sx={{
                textTransform: 'initial',
                px: 3
              }}
            >
              {`Start Course`}
            </Button>
          </Box>
        ) : undefined

      courseId = r.courseId

      return (
        <Box key={i} sx={{ mb: 1 }}>
          {header}
          {r.from === 'Topic' ? (
            <Box
              key={i}
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="h6"
                  sx={{
                    pt: 1
                  }}
                >
                  {r.text}
                </Typography>
              </Box>
              <Button
                component={Link}
                to={`${r.link}`}
                target="_blank"
                color="primary"
                size="small"
                variant="contained"
                sx={{
                  textTransform: 'initial',
                  px: 3
                }}
              >
                {`Start ${r.from}`}
              </Button>
            </Box>
          ) : undefined}
        </Box>
      )
    })
  }

  const filterResults = (r) => {
    const param = Object.fromEntries([...searchParams])
    const q = searchText || param.query
    return q
      ? r
          .reduce((p, c) => {
            const foundTopic = c.topics
              ? c.topics
                  .filter((c) => c.name !== 'KidAware Newsletters')
                  .map((t) => {
                    const foundTitle =
                      t.name.toLowerCase().search(q.toLowerCase()) > -1

                    const foundAttachment = t.attachments.find(
                      (a) => a.toLowerCase().search(q.toLowerCase()) > -1
                    )

                    return foundTitle || foundAttachment
                      ? {
                          id: t.id,
                          text: `${t.name}`,
                          courseName: c.name,
                          courseId: c.id,
                          from: 'Topic',
                          link: `/courses/${c.slug}/${t.slug}`,
                          courseLink: `/courses/${c.slug}`
                        }
                      : t
                  })
              : false

            const foundCourse =
              c?.name &&
              c?.description &&
              (c.name.toLowerCase().search(q.toLowerCase()) > -1 ||
                c.description.toLowerCase().search(q.toLowerCase()) > -1)
                ? {
                    id: c.id,
                    text: c.name,
                    courseName: c.name,
                    courseId: c.id,
                    from: 'Course',
                    courseLink: `/courses/${c.slug}`
                  }
                : c

            return [...p, ...[foundCourse], ...(foundTopic ? foundTopic : [])]
          }, [])
          .filter((r) => r.text)
      : r
  }

  const handleSearchChange = () => (event) => {
    setSearchText(event.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const useSearch = () => {
    return useQuery(['searchCourses'], async () => {
      return await fetch(`/courses/search`)
    })
  }

  const { status, data: searchResults, error } = useSearch()

  useEffect(() => {
    let mounted = true
    const param = Object.fromEntries([...searchParams])
    if (mounted && param.query && param.query !== '') setSearchText(param.query)

    return () => {
      mounted = false
    }
  }, [searchParams])

  return (
    <>
      <HeroBanner
        imageLocation="right"
        height={headerHeight}
        backgroundColor="#6806EE"
      >
        <Typography
          variant="h1"
          color="white"
          sx={{
            mb: 0
          }}
        >
          Looking for something specific?
        </Typography>
        <Typography variant="body" paragraph color="white" sx={{ mb: 3 }}>
          Try searching to narrow down your course selection.
        </Typography>
        <Box sx={{ display: 'flex' }} component="form" onSubmit={handleSubmit}>
          <TextField
            id="outlined-basic"
            label="Search our courses"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              sx: { display: 'none' }
            }}
            InputProps={{
              sx: {
                backgroundColor: 'white',
                borderTopLeftRadius: '100px',
                borderBottomLeftRadius: '100px',
                borderTopRightRadius: '0',
                borderBottomrightRadius: '0'
              }
            }}
            onChange={handleSearchChange('searchText')}
            value={searchText}
          />
          <Button
            type="submit"
            id="enroll-now-button"
            color="secondaryDark"
            variant="contained"
            sx={{
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
              '.MuiOutlinedInput-notchedOutline': {
                border: 0
              }
            }}
            aria-label="start course"
          >
            Search
          </Button>
        </Box>
      </HeroBanner>

      <Container component="section" maxWidth="xl" sx={{ pt: 4 }}>
        <BreadCrumb />
      </Container>
      <Container component="section">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Typography
              variant="h2"
              sx={{
                my: { xs: 0, md: 1, lg: 5 }
              }}
            >
              {`Course content containing "${searchText}"`}
            </Typography>
            {status === 'loading' ? (
              [...Array(8)].map((_, i) => (
                <Paper
                  key={`empty-topic-card-${i}`}
                  elevation={0}
                  className={('topic-card', 'is-loading')}
                  sx={{
                    p: 1,
                    my: 2,
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      opacity: 0.25
                    }}
                  >
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      disabled
                      sx={{
                        textTransform: 'initial',
                        px: 3
                      }}
                    >
                      Start
                    </Button>
                  </Box>
                </Paper>
              ))
            ) : status === 'error' ? (
              <span>Error: {error.message}</span>
            ) : (
              <>
                {filterResults(searchResults).length > 0 ? (
                  <>{buildCourseResults(filterResults(searchResults))}</>
                ) : (
                  <Typography>
                    Sorry, no courses can be found. Please refine your search
                    and try again.
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
