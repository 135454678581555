import { useQuery } from '@tanstack/react-query'
import { fetch as fetchApi } from '../common/api'

const types = [
  {
    type: 'blog',
    name: ' Blogs',
    cardType: 'FullCard',
    columns: { xs: 12, md: 4, lg: 4 },
    rowSpacing: { xs: 2, sm: 3, lg: 5 },
    path: `${process.env.REACT_APP_SUPERAWESOME_WP_API}posts?_embed=1`,
    pathSingle: `${process.env.REACT_APP_SUPERAWESOME_WP_API}posts?_embed=1&slug=`
  },
  {
    type: 'newsletter',
    name: ' Newsletters',
    cardType: 'ShortCard',
    columns: { xs: 12, md: 4, lg: 4 },
    rowSpacing: { xs: 2, sm: 3, lg: 5 },
    path: `${process.env.REACT_APP_SUPERAWESOME_WP_API}newsletter?_embed=1`,
    pathSingle: `${process.env.REACT_APP_SUPERAWESOME_WP_API}newsletter?_embed=1&slug=`
  },
  {
    type: 'podcast',
    name: ' Podcasts',
    columns: { xs: 12, md: 4, lg: 4 },
    rowSpacing: { xs: 2, sm: 3, lg: 5 },
    cardType: 'PodcastCard',
    path: `${process.env.REACT_APP_SUPERAWESOME_WP_API}posts?_embed=1`,
    pathSingle: `${process.env.REACT_APP_SUPERAWESOME_WP_API}posts?_embed=1&slug=`
  },
  {
    type: 'guide',
    name: ' Guides',
    columns: { xs: 2, sm: 2, lg: 2 },
    rowSpacing: { xs: 2, sm: 2, lg: 2 },
    cardType: 'Guide',
    path: `${process.env.REACT_APP_SUPERAWESOME_WP_API}resource?_embed=1`,
    pathSingle: `${process.env.REACT_APP_SUPERAWESOME_WP_API}resource?_embed=1&slug=`
  },
  {
    type: 'external-resource',
    name: ' External Resources',
    cardType: 'link',
    columns: { xs: 12, md: 4, lg: 4 },
    rowSpacing: { xs: 2, sm: 3, lg: 5 },
    path: `${process.env.REACT_APP_SUPERAWESOME_WP_API}resource?_embed=1`,
    pathSingle: `${process.env.REACT_APP_SUPERAWESOME_WP_API}resource?_embed=1&slug=`
  },
  {
    type: 'kidaware-in-the-world',
    name: ' KidAware In The World',
    cardType: 'FullCard',
    columns: { xs: 12, md: 4, lg: 4 },
    rowSpacing: { xs: 2, sm: 3, lg: 5 },
    path: `${process.env.REACT_APP_SUPERAWESOME_WP_API}resource?_embed=1`,
    pathSingle: `${process.env.REACT_APP_SUPERAWESOME_WP_API}resource?_embed=1&slug=`
  },
  {
    type: 'headline',
    name: ' News Feed',
    cardType: 'NewsFeed',
    columns: { xs: 2, md: 6, lg: 6 },
    rowSpacing: { xs: 1, sm: 1, lg: 1 },
    path: `${process.env.REACT_APP_KIDAWARE_WP_API}wp-json/kid-api/v1/headlines/?orderby=meta_value&meta_key=sa_headline_date&order=desc`,
    pathSingle: `${process.env.REACT_APP_KIDAWARE_WP_API}wp-json/kid-api/v1/headlines?_embed=1&slug=`
  }
]

const getResourceType = (type) => types.find((c) => c.type === type) ?? false

const useResourceLink = (id, type, link, enabled) => {
  return useQuery(
    [`resource${type}`, id],
    async () => {
      const r = await fetch(link)
      return await r.json()
    },
    { enabled: !!enabled }
  )
}

function useResources(type, limit, page) {
  const p = page ?? 0
  return useQuery(
    [type, limit, p],
    async () => {
      const configs = await fetchApi('/resources')
      const category = configs.find(
        (r) => r.type === type && r.key === 'categories'
      )

      const usesResourceCategory = [
        'guide',
        'kidaware-in-the-world',
        'external-resource'
      ]

      const categoryType = usesResourceCategory.includes(type)
        ? 'resource_category'
        : 'categories'
      const categories = category ? `&${categoryType}=${category.value}` : ''
      const tag = configs.find((r) => r.type === type && r.key === 'tags')
      const tags = tag ? `&tags=${tag.value}` : ''

      const resource = types.find((r) => r.type === type)

      let result
      try {
        const r = await fetch(
          `${resource.path}${categories}${tags}&per_page=${limit}`
        )

        if (!r.ok) throw new Error(r.statusText)

        result = await r.json()
      } catch (err) {
        throw new Error(err.message)
      }

      return result
    },
    {
      onError: (e) => console.log(e)
    }
  )
}

function useResource(type, slug) {
  return useQuery([type, slug], async () => {
    const resource = types.find((r) => r.type === type)
    const r = await fetch(`${resource.pathSingle}${slug}`)
    return r.json()
  })
}

export { useResources, useResource, useResourceLink, types, getResourceType }
