import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material'

const ButtonConfirm = ({ component, children, confirmAction, title }) => {
  const [buttonConfirmOpen, setButtonConfirmOpen] = useState(false)

  const handleButtonConfirmOpen = (e) => {
    e.preventDefault()
    setButtonConfirmOpen(true)
  }

  const handleConfirmClose = () => setButtonConfirmOpen(false)

  useEffect(() => {
    let mounted = true

    if (mounted)
      document
        .getElementById(component.props.id)
        .addEventListener('click', handleButtonConfirmOpen)

    return () => {
      const el = document.getElementById(component.props.id)
      mounted = false

      return el
        ? el.removeEventListener('click', handleButtonConfirmOpen)
        : undefined
    }
  }, [component])

  return (
    <>
      {component}
      <Dialog
        open={buttonConfirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose}>No</Button>
          <Button
            onClick={() => {
              confirmAction()
              handleConfirmClose()
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ButtonConfirm.propTypes = {
  component: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
  confirmAction: PropTypes.func.isRequired,
  title: PropTypes.string
}

ButtonConfirm.defaultProps = {
  title: 'Are you sure?'
}

export default ButtonConfirm
